import React from 'react';

const ShareIconWhatsapp: React.FC = () => {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.64876 1.95267L9.75807 9.06198M14.515 5.48979L3.44438 1.64897C2.76262 1.41244 2.10854 2.06652 2.34506 2.74828L6.18588 13.8189C6.449 14.5773 7.514 14.5981 7.80652 13.8505L9.56445 9.35804C9.65225 9.13366 9.82976 8.95615 10.0541 8.86835L14.5466 7.11042C15.2942 6.8179 15.2734 5.75291 14.515 5.48979Z" stroke="#1F2022" stroke-width="1.4" stroke-linecap="round" />
        </svg>

    );
};
export default ShareIconWhatsapp;
