import { SVGProps } from "react"
const ArrowDown = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.8 9.60039L12 14.4004L7.20005 9.60039"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round" />
    </svg>

)
export default ArrowDown
