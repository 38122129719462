import * as cheerio from "cheerio"

export function truncateString(str: string, maxLength: number) {
  if (str.length <= maxLength) {
    return str
  }

  let truncatedStr = str.substr(0, maxLength)
  let lastSpaceIndex = truncatedStr.lastIndexOf(" ")

  if (lastSpaceIndex > 0) {
    truncatedStr = truncatedStr.substr(0, lastSpaceIndex)
  } else {
    truncatedStr = truncatedStr.substr(0, maxLength)
  }

  return truncatedStr + "..."
}

export function getUmbracoURL(
  rootApp: string,
  currentURL: string,
  addURL: string,
) {
  return `/${rootApp}/pages/${currentURL}/${addURL}`
}

export function getUmbracoMediaUrl(url?: string, GRAPHQL_URL_CLIENT?: string) {
  const GRAPHQL = process.env.GRAPHQL_URL || GRAPHQL_URL_CLIENT
  const GRAPHQL_URL = GRAPHQL?.replace("/graphql", "")
  if (!url) {
    return ""
  }
  if (url.includes("http")) {
    if (url.includes("http://")) {
      return url.replace("http://", "https://")
    }
    return url
  }

  let updatedUrl = url
  if (url && url.startsWith("/media")) {
    updatedUrl = GRAPHQL_URL + url
  }
  return updatedUrl
}

export function getNonUmbracoURL(url?: string | null): string {
  let updatedUrl = url || ""
  if (url?.includes("https://") || url?.includes("http://")) {
    return updatedUrl
  }
  if (!updatedUrl) return ""
  updatedUrl = updatedUrl.replace("/pazcorp", "")
  updatedUrl = updatedUrl.replace("/pazoils", "")
  if (updatedUrl.includes("pazen")) {
    return updatedUrl.replace("/pazen/pages", "/en")
  }
  if (updatedUrl.includes("home")) {
    return updatedUrl.includes("pazcharge") ? "/pazcharge" : "/"
  }

  return updatedUrl.replace(/\/pages/, "")
}

export function convertISODateString(dateStr?: string) {
  if (!dateStr) return
  const dateObj = new Date(dateStr)
  return new Intl.DateTimeFormat("en-GB").format(dateObj)
}

export function removePTags(str: string) {
  return str.replace(/<\/?p>/g, "")
}

export function httpsImage(url: string) {
  if (!url) {
    return ""
  }
  return url.replace("http://", "https://")
}

// Function to replace href attributes in <a> tags
export const replaceHrefsWithMediaUrl = (
  htmlString: string,
  GRAPHQL_URL_CLIENT?: string,
): string => {
  const $ = cheerio.load(htmlString)
  $("a").each((index, element) => {
    const oldHref = $(element).attr("href")
    if (oldHref) {
      const newHref = getUmbracoMediaUrl(oldHref, GRAPHQL_URL_CLIENT)
      $(element).attr("href", newHref)
    }
  })
  return $.html()
}

// Debounce function builder
export const debounceBuilder = <T extends (...args: any[]) => void>(func: T, delay: number) => {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;
  return (...args: Parameters<T>) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};
