import { SVGProps } from "react"
const PhoneIconMobile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#0050C9"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.4}
      d="M2.78 15.643s.966.948 1.203 1.226c.385.411.84.605 1.435.605.057 0 .118 0 .175-.003 1.134-.073 2.187-.515 2.977-.892a16.947 16.947 0 0 0 5.633-4.402c1.302-1.566 2.172-3.013 2.748-4.567.355-.948.485-1.687.428-2.384a1.747 1.747 0 0 0-.527-1.131L15.55 2.796c-.187-.175-.385-.27-.58-.27-.24 0-.435.144-.557.266l-.011.012c-.233.217-.454.441-.687.681-.118.122-.24.244-.363.37l-1.042 1.04c-.404.403-.404.777 0 1.18.111.11.218.221.329.328.32.327.068.076.4.373.008.008.016.012.02.02.328.327.267.647.198.864l-.011.034c-.271.655-.653 1.272-1.233 2.007l-.004.004C10.956 11 9.845 12.009 8.62 12.782c-.156.1-.317.18-.47.255a4.852 4.852 0 0 0-.377.202c-.016.008-.03.02-.046.027a.83.83 0 0 1-.378.095.816.816 0 0 1-.58-.263l-.748-.746c-.13-.13-.336-.286-.576-.286-.237 0-.432.149-.55.278l-.008.008-2.102 2.098c-.393.389-.004 1.193-.004 1.193Z"
    />
  </svg>
)
export default PhoneIconMobile
