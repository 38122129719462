"use client"

import styled from "@emotion/styled"
import { Box, Breadcrumbs } from "@mui/material"
import Link from "next/link"
import React from "react"
import { NewText } from "./NewText/NewText"
import { BreadcrumbIcon } from "./icons"
import { sharedColors } from "./theme/colors"

const StyledContainer = styled(Box)`
  padding: 12px 0;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 12px 0;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
`
const Seperator = styled.div<{ en: boolean }>`
  ${({ en }) => en && `transform: scale(-1);`}
`
const StyledCurrentText = styled(NewText)`
  ${(props) => props.theme.breakpoints.down("md")} {
    text-overflow: ellipsis;
    max-width: 114px;
    white-space: nowrap;
    overflow: hidden;
  }
`
type BreadcrumbType = {
  title: string | JSX.Element
  url: string
  noClick?: boolean
  isPage?: boolean
}

type Props = {
  current: BreadcrumbType
  parents: BreadcrumbType[]
  children?: JSX.Element
  locale?: string,
  firstItemIconText?: boolean
  firstItemIconTextUnderline?: boolean
  firstIconText?: string
}

export const CustomBreadcrumbs: React.FC<Props> = ({
  current,
  parents,
  children,
  locale,
  firstItemIconText = false,
  firstItemIconTextUnderline = false,
  firstIconText
}) => {
  // Take homepage + only relvant pages that exists..
  const pages = [parents[0], ...parents.filter(parent => parent.isPage)]
  return (
    <StyledContainer>
      {children}

      <Breadcrumbs
        role="navigation"
        separator={<BreadcrumbIcon en={locale === "en"} aria-hidden="true" />}
        aria-label="המיקום שלך באתר"
      >
        {
          pages.map((item, index) => {
            // TODO: find dynamic solution for parents with no actual page
            if (!item) { return null }
            if ((!item.noClick && item.isPage) || index === 0) {
              return (
                <StyledLink href={item.url} key={index}>
                  {typeof item.title !== "string" ? (
                    <div style={{ fontSize: 0,display: "flex", gap: 2  }} aria-label="דף הבית">{item.title}</div>
                  ) : (
                    <>
                    <NewText
                      component="p"
                      desktopFontSize={12}
                      desktopFontWeight="400"
                      desktopLineHeight="150%"
                      color={sharedColors.blue}
                    >
                      {item.title}
                      </NewText>
                      
                      {firstItemIconText && <NewText
                        component="p"
                        desktopFontSize={12}
                        desktopFontWeight="400"
                        desktopLineHeight="150%"
                        color={sharedColors.blue}
                        style={{ textDecoration: firstItemIconTextUnderline ? "underline" : "none" }}
                      >
                        {firstIconText}
                      </NewText>}
                  </>
                ) 
                // : (
                //   <NewText
                //     component="p"
                //     desktopFontSize={12}
                //     desktopFontWeight="400"
                //     desktopLineHeight="150%"
                //     color={sharedColors.blue}
                //   >
                //     {item.title}
                //   </NewText>
                // )
                }
              </StyledLink>
            )
          } else {
            return (
              <NewText
                component="p"
                desktopFontSize={12}
                desktopFontWeight="500"
                desktopLineHeight="150%"
                color={sharedColors.gray}
                key={index}
              >
                {item.title}
              </NewText>
            )
          }
        })}
        <StyledCurrentText
          component="p"
          desktopFontSize={12}
          desktopFontWeight="500"
          desktopLineHeight="150%"
          color={sharedColors.gray}
        >
          {current.title}
        </StyledCurrentText>
      </Breadcrumbs>
    </StyledContainer>
  )
}

export default CustomBreadcrumbs
