import { SVGProps } from "react"

interface LeftArrowShortProps extends SVGProps<SVGSVGElement> {
  size?: number // Adding a size prop
}

const LeftArrowShort = ({ size = 24, ...props }: LeftArrowShortProps) => (
  <svg
    width={size} // Using the size prop for width
    height={size} // Using the size prop for height
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props} // Spread the rest of the props
  >
    <path
      d="M7.41176 16.8897L3 12.2574M3 12.2574L7.41176 7.625M3 12.2574H21"
      stroke={props.color || "#000000"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default LeftArrowShort
