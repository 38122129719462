import * as React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  checked: boolean
}
const MobileFilterCheckbox = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <rect width={19} height={19} x={0.5} y={0.5} stroke="#000" rx={1.5} />
    {props.checked && (
      <path
        stroke="#0050C9"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m16 5.5-8.95 9L4 11.432"
      />
    )}
  </svg>
)
export default MobileFilterCheckbox
