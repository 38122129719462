import { SVGProps } from "react"
const PortfolioIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.6875 15.604V24.0623C26.6875 25.0248 25.9 25.8123 24.9375 25.8123H3.0625C2.1 25.8123 1.3125 25.0248 1.3125 24.0623V15.604"
      stroke="#5C6474"
      strokeWidth="1.4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.1875 4.8125C9.1875 3.36263 10.3635 2.1875 11.8125 2.1875H16.1875C17.6365 2.1875 18.8125 3.36263 18.8125 4.8125"
      stroke="#5C6474"
      strokeWidth="1.4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6667 15.6042H7.72917C2.89742 15.6042 1.3125 14.0201 1.3125 9.1875V6.5625"
      stroke="#5C6474"
      strokeWidth="1.4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.6875 6.5625H26.6875V9.1875C26.6875 14.0201 25.1026 15.6042 20.2708 15.6042H15.3125"
      stroke="#5C6474"
      strokeWidth="1.4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.166 13.5625H11.666V18.2292H15.166V13.5625Z"
      stroke="#5C6474"
      strokeWidth="1.4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.84999 6.5625H3.5"
      stroke="#5C6474"
      strokeWidth="1.4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default PortfolioIcon
