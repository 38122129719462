import { styled } from "@mui/material"
import Link from "next/link"
import { ElementType } from "react"
import { NewText } from "./NewText/NewText"
import { sharedColors } from "./theme/colors"

interface Props {
  justify?: boolean
}

const StyledLink = styled(Link) <Props>`
  text-decoration: none;
  display: flex;
  align-items: center;
  ${(props) => props.theme.breakpoints.down("md")} {
    ${(props) => props.justify && `justify-content:center;`}
  }

  svg {
    margin-right: 10px;
    margin-left: 3px;
  }
`

type LinkComponentProps = {
  text: string
  icon?: JSX.Element
  link: string
  fontSize: number
  color?: string
  mobileFontSize?: number
  desktopLineHeight?: string
  mobileLineHeight?: string
  fontWeight?: number
  mobileFontWeight?: number
  mediumFontSize?: number
  justify?: boolean
  largeDesktopFontSize?: number
  largeDesktopLineHeight?: string
  compType?: ElementType<any>
}

export const LinkComponent = ({
  text,
  icon,
  link,
  fontSize,
  color = sharedColors.blue,
  mobileFontSize = fontSize,
  desktopLineHeight = "24px",
  largeDesktopFontSize = fontSize,
  largeDesktopLineHeight = desktopLineHeight,
  mobileLineHeight = desktopLineHeight,
  fontWeight = 400,
  mobileFontWeight = fontWeight,
  mediumFontSize = fontSize,
  justify,
  compType
}: LinkComponentProps) => {
  return (
    <StyledLink href={link} justify={justify}>
      {icon ? icon : null}
      <NewText
        component={compType || "p"}
        largeDesktopFontSize={largeDesktopFontSize}
        largeDesktopLineHeight={largeDesktopLineHeight}
        desktopFontSize={fontSize}
        desktopLineHeight={desktopLineHeight}
        color={color}
        mobileFontSize={mobileFontSize}
        mobileLineHeight={mobileLineHeight}
        desktopFontWeight={fontWeight}
        mobileFontWeight={mobileFontWeight}
        smallDesktopFontSize={mediumFontSize}
        smallDesktopLineHeight="16px"
      >
        {text}
      </NewText>
    </StyledLink>
  )
}

export default LinkComponent
