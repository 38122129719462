import styled from "@emotion/styled"
import { Collapse, List, ListItemButton, ListItemText } from "@mui/material"
import Image from "next/image"
import Link from "next/link"
import { useRouter } from "next/navigation"
import React from "react"
import { getNonUmbracoURL } from "utils/utils"
import { NewText } from "./NewText/NewText"
import { sharedColors } from "./theme/colors"

const StyledListItemButton = styled(ListItemButton)`
  a {
    text-decoration: none;
  }
`

const StyledLi = styled.li`
  padding: 8px 16px ;
`
const StyledButton = styled(ListItemButton)`
  background: transparent;
  padding: 16px 0px;
  border-top: 1px solid ${(props) => sharedColors.bluishGray}1E;
`
const StyledLink = styled.div`
  background: transparent;
  padding: 16px 0px;
  border-top: 1px solid ${(props) => sharedColors.bluishGray}1E;
  text-decoration: none;
`

const StyledImage = styled(Image)`
  transform: scale(-1);
`
const StyledLinkk = styled(Link)`
  text-decoration: none;
`
type Props = {
  title: string
  children: { title: string; url: string }[]
  singlUrl?: string
  locale?: string
  target?: string
}

export const FooterList: React.FC<Props> = ({
  title,
  children,
  singlUrl,
  locale,
  target,
}) => {
  const router = useRouter()
  const [open, setOpen] = React.useState(false)
  const handleClick = () => {
    setOpen(!open)
  }
  return (
    <>
      {!children.length || children.length === 0 ? (
        <StyledLinkk href={getNonUmbracoURL(singlUrl)} target={target}>
          <StyledLink
            tabIndex={-1}
          >
            <ListItemText>
              <NewText
                desktopFontSize={16}
                component="li"
                desktopLineHeight="150%"
                desktopFontWeight="500"
                color={sharedColors.white}
              >
                {title}
              </NewText>
            </ListItemText>
            {locale === "en" && (
              <StyledImage
                src="/icons/arrow-left-white.svg"
                width={24}
                height={24}
                alt=""
              />
            )}
          </StyledLink>
        </StyledLinkk>
      ) : null}
      {children.length ? (
        <StyledButton
          onClick={handleClick}
          tabIndex={0}
          role="button"
          aria-expanded={open}
        >
          <ListItemText>
            <NewText
              desktopFontSize={16}
              component="li"
              desktopLineHeight="150%"
              desktopFontWeight="500"
              color={sharedColors.white}
            >
              {title}
            </NewText>
          </ListItemText>
          {open ? (
            <Image
              src="/icons/arrow-down-white.svg"
              width={24}
              height={24}
              alt=""
            />
          ) : (
            <Image
              src="/icons/arrow-left-white.svg"
              width={24}
              height={24}
              alt=""
            />
          )}
        </StyledButton>
      ) : null}

      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
      >
        <List component="ul" disablePadding>
          {children.map((item, index) => (
            <StyledLi role="link" tabIndex={0} key={index.toString()} onClick={() => {
              router.push(item?.url)

            }}>
              <ListItemText>
                <NewText
                  desktopFontSize={16}
                  component="span"
                  desktopLineHeight="150%"
                  desktopFontWeight="400"
                  color={sharedColors.white}
                >
                  {item?.title}
                </NewText>
              </ListItemText>
            </StyledLi>
          ))}
        </List>
      </Collapse>
    </>
  )
}

export default FooterList
