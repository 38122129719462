import { SVGProps } from "react"
const LocationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ?? 24}
    height={props.height ?? 24}
    fill="none"
    {...props}
  >
    <path
      stroke={props.color}
      strokeWidth={1.4}
      d="M12 21.6s7.513-6.678 7.513-11.687a7.513 7.513 0 1 0-15.026 0C4.487 14.922 12 21.6 12 21.6Z"
    />
    <path
      stroke={props.color}
      strokeWidth={1.4}
      d="M14.4 9.6a2.4 2.4 0 1 1-4.8 0 2.4 2.4 0 0 1 4.8 0Z"
    />
  </svg>
)
export default LocationIcon
