"use client"

import styled from "@emotion/styled"

const StyledContainer = styled.div<{
  $rightGap?: number
  $leftGap?: number
  $mediumRightGap?: number
  $mediumLeftGap?: number
  $mobileGap?: number
  $yGap?: number
}>`
  display: grid;
  ${(p) => (p.$yGap ? `margin-top: 32px;` : "")}
  ${(p) => (p.$yGap ? `margin-bottom: 32px;` : "")}
  grid-template-columns:${(p) => {
    let string
    if (p.$rightGap && p.$leftGap) {
      string = `${p.$mobileGap}px auto ${p.$mobileGap}px`
    }
    if (p.$rightGap && !p.$leftGap) {
      string = `${p.$mobileGap}px calc(100% - ${p.$mobileGap}px)`
    }
    if (p.$mediumRightGap && !p.$rightGap) {
      string = `40px calc(100% - 40px)`
    }
    return string
  }};

  ${(props) => props.theme.breakpoints.up("md")} {
    ${(p) => (p.$yGap ? `margin-top: ${p.$yGap}px;` : "")}
    ${(p) => (p.$yGap ? `margin-bottom: ${p.$yGap}px;` : "")}
    grid-template-columns: ${(p) => {
      let string
      if (p.$mediumLeftGap && p.$mediumRightGap) {
        string = `${p.$mediumLeftGap}px auto ${p.$mediumRightGap}px`
      } else if (p.$mediumRightGap && !p.$mediumLeftGap) {
        string = `${p.$mediumRightGap}px calc(100% - ${p.$mediumRightGap}px)`
      } else if (p.$mediumLeftGap && !p.$mediumRightGap) {
        string = `${p.$mediumLeftGap}px calc(100% - ${p.$mediumLeftGap}px)`
      } else {
        string = "150px calc(100% - 150px)"
      }
      return string
    }};
  }

  ${(props) => props.theme.breakpoints.up("lg")} {
    ${(p) => (p.$yGap ? `margin-top: ${p.$yGap}px;` : "")}
    ${(p) => (p.$yGap ? `margin-bottom: ${p.$yGap}px;` : "")}
    grid-template-columns: ${(p) => {
      let string
      if (p.$leftGap && p.$rightGap) {
        string = `${p.$leftGap}px auto ${p.$rightGap}px`
      } else if (p.$rightGap && !p.$leftGap) {
        string = `${p.$rightGap}px calc(100% - ${p.$rightGap}px)`
      } else if (p.$leftGap && !p.$rightGap) {
        string = `${p.$leftGap}px calc(100% - ${p.$leftGap}px)`
      } else if (!p.$rightGap && p.$mediumRightGap) {
        string = `0px auto`
      } else {
        string = `150px calc(100% - 150px)`
      }
      return string
    }};
  }

  ${(props) => props.theme.breakpoints.up("xl")} {
    ${(p) => (p.$yGap ? `margin-top: ${p.$yGap}px;` : "")}
    ${(p) => (p.$yGap ? `margin-bottom: ${p.$yGap}px;` : "")}
  grid-template-columns: ${(p) => {
      let string
      if (p.$leftGap && p.$rightGap) {
        string = `${p.$leftGap}px auto ${p.$rightGap}px`
      } else if (p.$rightGap && !p.$leftGap) {
        string = `${p.$rightGap}px calc(100% - ${p.$rightGap}px)`
      } else if (p.$leftGap && !p.$rightGap) {
        string = `${p.$leftGap}px calc(100% - ${p.$leftGap}px)`
      } else if (!p.$rightGap && p.$mediumRightGap) {
        string = `0px auto`
      } else {
        string = `150px calc(100% - 150px)`
      }
      return string
    }};
  }
`

type MainContainerProps = {
  children: JSX.Element
  rightGap?: number
  leftGap?: number
  mediumRightGap?: number
  mediumLeftGap?: number
  mobileGap?: number
  yGap?: number
}

export const MainContainer: React.FC<MainContainerProps> = ({
  children,
  rightGap = 0,
  leftGap = 0,
  yGap = 0,
  mediumRightGap = 0,
  mobileGap = 20,
  mediumLeftGap = 0,
}: MainContainerProps) => {
  return (
    <StyledContainer
      $rightGap={rightGap}
      $leftGap={leftGap}
      $mediumRightGap={mediumRightGap}
      $mobileGap={mobileGap}
      $yGap={yGap}
      $mediumLeftGap={mediumLeftGap}
    >
      {rightGap || mediumRightGap ? <div /> : null}
      {children}
      {leftGap ? <div /> : null}
    </StyledContainer>
  )
}

export default MainContainer
