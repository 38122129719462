import React, { SVGProps } from 'react';

interface LeftArrowProps extends SVGProps<SVGSVGElement> {
  color?: string;
}
const LeftArrow: React.FC<LeftArrowProps> = ({ color = '#111827', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={45}
    height={18}
    fill="none"
    {...props}
  >
    <path
      stroke={color}
      strokeLinejoin="bevel"
      d="M44.5 9H1m0 0 8-8M1 9l8 8"
    />
  </svg>
);
export default LeftArrow;
