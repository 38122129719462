import styled from "@emotion/styled"
import StickyBannerText from "./StickyBannerText"

const StyledSearchContainer = styled.div<{ pazomat?: boolean }>`
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: 2;
  width: 290px;
  height: 80px;
  background-image: url("/images/PazomatVectorMobile.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  ${(props) => props.theme.breakpoints.up("md")} {
    ${(props) =>
    props.pazomat
      ? `
    width: 570px;
    height: 157px;
    `
      : `
    width: 500px;
    height: 140px;`}
    background-image: url("/images/PazomatVector.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    bottom: -25px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    ${(props) => props.theme.breakpoints.up("md")} {
      ${(props) => (props.pazomat ? `height: 140px;` : null)}
      width: 750px;
      bottom: 0px;
    }
  }
`
type PositionVectorProps = {
  textOne: string
  textTwo: string
  linkOne: string
  linkTwo: string
  pazomat?: boolean
}

export const PositionVector: React.FC<PositionVectorProps> = ({
  textOne,
  textTwo,
  linkOne,
  linkTwo,
  pazomat,
}) => {
  return (
    <StyledSearchContainer pazomat={pazomat}>
      <StickyBannerText
        textOne={textOne}
        textTwo={textTwo}
        linkOne={linkOne}
        linkTwo={linkTwo}
      />
    </StyledSearchContainer>
  )
}

export default PositionVector
