"use client"
import styled from "@emotion/styled"
import Image from "next/image"
import { useState } from "react"
import "swiper/css"
import "swiper/css/effect-coverflow"
import "swiper/css/effect-fade"
import "swiper/css/navigation"
import { Navigation } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import { extractProperty } from "utils"
import { httpsImage } from "utils/utils"
import ImageContainer from "../ImageContainer"
import { NewText } from "../NewText/NewText"
import { useIsDesktop } from "../common/bit/MediaQueries"
import { sharedColors } from "../theme/colors"

const StyledTitleContainer = styled.div`
  margin: 0 20px 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin: 0 0 26px 52px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    margin: 0 0 40px 52px;
  }
`

const StyledSlide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 381px;
  p {
    margin: 0px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    height: calc((100vw - 40px) * 663 / 539 / 2);
  }

  ${(props) => props.theme.breakpoints.up("xl")} {
    height: calc((100vw - 40px) / 2);
  }
`

const StyledSlideImage = styled.div`
  position: relative;
  height: 325px;
  ${(props) => props.theme.breakpoints.up("md")} {
    height: calc((100vw - 96px) / 2 * 0.94732143);
  }
`

const StyledSlideText = styled.div`
  margin-left: 8px;
`

const StyledActionsContainer = styled.div`
  display: none;
  ${(props) => props.theme.breakpoints.up("md")} {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 80px;
    gap: 47px;
    height: 49px;
  }
`

const StyledArrowsContainer = styled.div`
  display: flex;
  gap: 16px;
`
const StyledProgressBarContainer = styled.div`
  height: 1px;
  background: ${sharedColors.sliderBlue};
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 750px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    width: 1000px;
  }
  ${(props) => props.theme.breakpoints.up("xl")} {
    width: 1127px;
  }
`
const StyledNumbersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`
const StyledArrowContainer = styled.div`
  cursor: pointer;
`
const StyledBar = styled.div<{
  activeIndex: number
  length: number
  isDesktop: boolean
}>`
  width: ${(props) =>
    `calc((750px / ${props.length} ) * ${props.activeIndex + (props.isDesktop ? 2 : 1)
    }) ;`}
    ${(props) => props.theme.breakpoints.up("lg")} {
    width: ${(props) =>
    `calc((1000px / ${props.length} ) * ${props.activeIndex + (props.isDesktop ? 2 : 1)
    }) ;`};
  }
  ${(props) => props.theme.breakpoints.up("xl")} {
    width: ${(props) =>
    `calc((1127px / ${props.length} ) * ${props.activeIndex + (props.isDesktop ? 2 : 1)
    }) ;`};
  }
  height: 3px;
  position: relative;
  top: -1px;
  background: ${sharedColors.sliderBlue};
`
const StyledLine = styled.div`
  width: 45px;
  height: 2px;
  background: ${sharedColors.darkestBlue};
`

const StyledContainer = styled.div`
  margin-bottom: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-bottom: 0px;
  }
  .swiper-slide {
    width: 80%;
  }
`

type DealsCarouselProps = {
  data: any
}

export const DealsCarousel: React.FC<DealsCarouselProps> = ({ data }) => {
  const title = extractProperty(data, "carouselTitle")
  const deals: any[] = extractProperty(data, "deals")
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const isDesktop = useIsDesktop()

  if (deals.length === 0) return null

  return (
    <StyledContainer>
      <Swiper
        navigation={{ nextEl: ".arrow-right", prevEl: ".arrow-left" }}
        key={1}
        style={{ padding: 0 }}
        modules={[Navigation]}
        breakpoints={{
          0: {
            slidesPerView: "auto",
            spaceBetween: 20,
          },
          900: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
        }}
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
      >
        {deals.map((item: any, index: number) => {
          const url = extractProperty(item, "dealPic")
          const dealText = extractProperty(item, "dealText")
          return (
            <SwiperSlide key={index.toString()}>
              <StyledSlide>
                <StyledSlideImage>
                  <Image
                    priority={true}
                    src={httpsImage(url)}
                    alt={index.toString()}
                    fill
                    style={{ objectFit: "contain" }}
                  />
                </StyledSlideImage>
                <StyledSlideText>
                  <NewText
                    component="div"
                    desktopFontSize={20}
                    desktopLineHeight="28px"
                    mobileFontSize={12}
                    mobileLineHeight="14px"
                    color={sharedColors.black}
                    dangerouslySetInnerHTML={{ __html: dealText }}
                  />
                </StyledSlideText>
              </StyledSlide>
            </SwiperSlide>
          )
        })}
      </Swiper>
      <StyledActionsContainer>
        <StyledNumbersContainer>
          <NewText
            component="p"
            desktopFontSize={24}
            desktopLineHeight="28.8px"
            color={sharedColors.blue}
            smallDesktopFontSize={16}
            smallDesktopLineHeight="19px"
          >
            0{activeIndex + 1}
          </NewText>{" "}
          <StyledLine />{" "}
          <NewText
            component="p"
            desktopFontSize={24}
            desktopLineHeight="28.8px"
            color={sharedColors.darkestBlue}
            smallDesktopFontSize={16}
            smallDesktopLineHeight="19px"
          >
            0{deals.length - 1}
          </NewText>
        </StyledNumbersContainer>

        <StyledProgressBarContainer>
          <StyledBar
            isDesktop={isDesktop}
            activeIndex={activeIndex}
            length={deals.length}
          />
        </StyledProgressBarContainer>
        <StyledArrowsContainer>
          <StyledArrowContainer className={`arrow-left arrow`}>
            <ImageContainer
              height={24}
              desktopUrl={"/icons/Arrow/arrow-left.png"}
              width={24}
              alt="arrow-left"
              mediumHeight={16}
              mediumWidth={16}
            />
          </StyledArrowContainer>
          <StyledArrowContainer className={`arrow-right arrow`}>
            <ImageContainer
              height={24}
              desktopUrl={"/icons/Arrow/arrow-right.png"}
              width={24}
              alt="arrow-right"
              mediumHeight={16}
              mediumWidth={16}
            />
          </StyledArrowContainer>
        </StyledArrowsContainer>
      </StyledActionsContainer>
    </StyledContainer>
  )
}

export default DealsCarousel
