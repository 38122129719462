import * as React from "react"
const VideoIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={51}
    height={51}
    fill="none"
    {...props}
  >
    <path
      stroke="#fff"
      d="M31.145 24.47c1.405.812 1.41 1.834 0 2.752l-10.9 7.623c-1.37.758-2.3.31-2.397-1.33L17.8 17.56c-.03-1.51 1.17-1.937 2.313-1.213l11.03 8.123Z"
    />
    <circle cx={25} cy={25.603} r={24} stroke="#fff" />
    <path stroke="#fff" strokeWidth={3} d="M49 25.603a24 24 0 0 0-24-24" />
  </svg>
)
export default VideoIcon
