import styled from "@emotion/styled"
import { NewText } from "../NewText/NewText"

const StyledTextContainer = styled.div`
  height: 130px;
  position: absolute;
  top: 52px;
  left: 0;
  z-index: 2;
  ${(props) => props.theme.breakpoints.up("md")} {
    left: 0;
    top: 23.5%;
    height: 288px;
  }
`

const StyledLast = styled.div`
  height: 48px;
  h1 {
    font-size: 68px;
    line-height: 68px;
    margin: 0px;
  }
  p {
    margin: 0px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    height: auto;
    h1 {
      font-size: 80px;
      line-height: 96px;
      margin: 0px;
    }
    p {
      font-size: 26px;
      line-height: 40px;
      margin: 0px;
    }
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    height: auto;
    h1 {
      font-size: 120px;
      line-height: 144px;
      margin: 0px;
    }
    p {
      font-size: 40px;
      line-height: 60px;
      margin: 0px;
    }
  }
`

const StyledTextWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  width: fit-content;
  padding: 16px 8px 0px 19px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 12px 24px 0px 60px;
  }
`
interface StyledNewTextProps {
  corp?: boolean
}

const StyledNewText = styled(NewText, {
  shouldForwardProp: (prop) => prop !== "corp",
}) <StyledNewTextProps>`
  h1 {
    font-weight: 500;
    padding: 0 16px 0 16px;
    background-color: white;
    width: fit-content;
    font-size: 68px !important;
    line-height: 88px !important;
    ${(props) => props.theme.breakpoints.up("md")} {
      padding: 0 20px 0 60px;
      font-size: 80px !important;
      line-height: 96px !important;
    }
    ${(props) => props.theme.breakpoints.up("lg")} {
      font-size: 120px !important;
      line-height: 144px !important;
    }
  }
  p {
    padding: 0px 16px 8px 16px;
    background-color: white;
    width: 50%;
    font-weight: 500;
    font-size: 18px !important;
    line-height: 24px !important;
    ${(props) => props.theme.breakpoints.up("md")} {
      padding: 0px 24px 12px 60px;
      width: 64%;
      font-weight: 400;
      font-size: 27px !important;
      line-height: 40px !important;
    }
    ${(props) => props.theme.breakpoints.up("lg")} {
      width: 61%;
      font-size: 40px !important;
      line-height: 60px !important;
    }
  }
`

type Props = {
  title: string | undefined
  paragraph: string
  width: string
  color: string
  insideLine?: boolean
  corp?: boolean
}

export const PositionedCorpText: React.FC<Props> = ({
  title,
  paragraph,
  width,
  color,
  insideLine = false,
  corp,
}) => {
  return (
    <StyledTextContainer>
      <StyledTextWrapper>
        <NewText
          largeDesktopFontSize={24}
          largeDesktopLineHeight="33.6px"
          desktopFontSize={16}
          desktopLineHeight={"22.4px"}
          mobileFontSize={16}
          mobileLineHeight={"22.4px"}
          mobileFontWeight="400"
          component="h2"
          desktopFontWeight="500"
          color={color}
        >
          {title}
        </NewText>
      </StyledTextWrapper>

      <StyledLast>
        <StyledNewText
          corp={corp}
          largeDesktopFontSize={40}
          largeDesktopLineHeight="60px"
          desktopFontSize={40}
          desktopLineHeight="60px"
          mobileFontSize={18}
          mobileLineHeight={"24px"}
          component="div"
          desktopFontWeight="400"
          color={color}
          dangerouslySetInnerHTML={{
            __html: paragraph.replace("<br>", "").replace("h3", "h1"),
          }}
        />
      </StyledLast>
    </StyledTextContainer>
  )
}

export default PositionedCorpText
