import Typography, { TypographyProps } from "@mui/material/Typography"
import React, { ElementType, ReactNode } from "react"
import { sharedColors } from "../theme/colors"

export interface NewTextProps extends TypographyProps {
  desktopFontSize: number
  mobileFontSize?: number
  desktopLineHeight?: number | string
  mobileLineHeight?: number | string
  desktopFontWeight?: number | string
  mobileFontWeight?: number | string
  largeDesktopFontSize?: number | string
  color?: string
  component: ElementType<any>
  children?: ReactNode
  opacity?: number
  smallDesktopFontSize?: number
  smallDesktopLineHeight?: string
  largeDesktopLineHeight?: string
  hugeDesktopFontSize?: string
  hugeDesktopLineHeight?: string
}

export const NewText: React.FC<NewTextProps> = ({
  desktopFontSize,
  mobileFontSize = desktopFontSize,
  desktopLineHeight = "100%",
  mobileLineHeight = "100%",
  desktopFontWeight = "400",
  mobileFontWeight = desktopFontWeight,
  smallDesktopFontSize = mobileFontSize,
  largeDesktopFontSize = desktopFontSize,
  largeDesktopLineHeight = desktopLineHeight,
  hugeDesktopFontSize = largeDesktopFontSize,
  hugeDesktopLineHeight = largeDesktopLineHeight,
  color = sharedColors.gray,
  opacity = 1,
  children,
  component,
  smallDesktopLineHeight = mobileLineHeight,
  ...props
}) => {
  return (
    <Typography
      {...props}
      component={component}
      sx={{
        fontSize: {
          xs: mobileFontSize,
          sm: smallDesktopFontSize,
          md: desktopFontSize,
          lg: largeDesktopFontSize,
          xl: hugeDesktopFontSize,
        },
        color,
        lineHeight: {
          xs: mobileLineHeight,
          sm: smallDesktopLineHeight,
          md: desktopLineHeight,
          lg: largeDesktopLineHeight,
          xl: hugeDesktopLineHeight,
        },
        fontWeight: {
          xs: mobileFontWeight,
          sm: desktopFontWeight,
        },
        opacity,
      }}
    >
      {children}
    </Typography>
  )
}
