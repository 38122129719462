"use client"
import { useState } from "react"

import styled from "@emotion/styled"
import { IconButton } from "@mui/material"
import { NewText } from "./NewText/NewText"
import { CollapseIcon, ExpandIcon } from "./icons"
import { sharedColors } from "./theme/colors"

type AccordionProps = {
  children: JSX.Element
  title: string
  index: number
  showBorderOnExpand?: boolean
  defaultExpanded?: boolean
  mobileFontSize?: number
  mobileLineHeight?: string
}

const StyledIconButton = styled(IconButton)`
:focus-visible{
  border: 1px solid gray;
}`

const StyledAccordionWrapper = styled.div``
const StyledAccordionHeader = styled.div<{ $showBorderOnExpand: boolean }>`
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 1.5px solid
    ${(props) =>
    !props.$showBorderOnExpand ? sharedColors.black : "transparent"};
  button {
    height: 80px;
    width: 80px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    p {
      padding-left: 28px;
      padding-right: 28px;
    }
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    button {
      height: 53px;
      width: 53px;
    }
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    button {
      height: 80px;
      width: 80px;
    }
  }
`

export const Accordion: React.FC<AccordionProps> = ({
  children,
  title,
  index,
  showBorderOnExpand = true,
  defaultExpanded,
  mobileFontSize,
  mobileLineHeight,
}) => {
  const [expand, setExpand] = useState(index === 0 ? true : false)

  return (
    <StyledAccordionWrapper aria-expanded={expand ? "true" : "false"}>
      <StyledAccordionHeader
        $showBorderOnExpand={expand && !showBorderOnExpand}
        onClick={() => setExpand((el) => !el)}
      >
        <NewText
          component="p"
          desktopFontSize={40}
          desktopFontWeight={500}
          color={sharedColors.black}
          mobileFontSize={mobileFontSize || 20}
          mobileLineHeight={mobileLineHeight}
          smallDesktopFontSize={26}
          smallDesktopLineHeight="39px"
        >
          {title}
        </NewText>

        {expand ? (
          <StyledIconButton aria-expanded={expand ? "true" : "false"} disableRipple aria-label={`לסגור ${title}`}>
            <CollapseIcon />
          </StyledIconButton>
        ) : (
          <StyledIconButton aria-expanded={expand ? "true" : "false"} disableRipple aria-label={`לפתוח ${title}`}>
            <ExpandIcon />
          </StyledIconButton>
        )}
      </StyledAccordionHeader>
      {expand ? children : null}
    </StyledAccordionWrapper>
  )
}

export default Accordion
