import * as React from "react"

interface SvgComponentProps extends React.SVGProps<SVGSVGElement> {
  increase: boolean
  small: boolean
}

const FinancialArrow: React.FC<SvgComponentProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.small ? 10 : 18}
    height={props.small ? 19 : 25}
    viewBox="0 0 18 31"
    fill="none"
    {...props} // Spread all props to the svg element, including custom and standard SVG props
  >
    {props.increase && (
      <path
        stroke="#54A800"
        d="M9 31L9 2M9 2L17 10M9 2L1 10"
        stroke-width="2"
      />
    )}
    {!props.increase && (
      <path
        d="M9 -8.64267e-07L9 29M9 29L17 21M9 29L1 21"
        stroke="#D10040"
        stroke-width="2"
      />
    )}
  </svg>
)

export default FinancialArrow
