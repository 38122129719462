import styled from "@emotion/styled"
import Image from "next/image"
import { httpsImage } from "utils/utils"

const StyledContainer = styled.div<{ $width: string; $height: string }>`
  position: relative;
  width: 120px;
  height: 120px;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 93px;
    height: 93px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    width: ${(p) => p.$width};
    height: ${(p) => p.$height};
  }

  img {
    border-radius: 50%;
    object-fit: cover !important;
  }
`

type CircleImageProps = {
  width: string
  height: string
  src: string
  priority?: boolean
}

export const CircleImage: React.FC<CircleImageProps> = ({
  width,
  height,
  src,
  priority,
}) => {
  return (
    <StyledContainer $width={width} $height={height}>
      <Image
        priority={priority}
        style={{ objectFit: "contain" }}
        src={httpsImage(src)}
        fill
        alt=""
      />
    </StyledContainer>
  )
}

export default CircleImage
