import React from 'react';

const ExpandIconUpDown: React.FC = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.89551 14.3996L12.6955 9.59961L17.4955 14.3996" stroke="#0050C9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};
export default ExpandIconUpDown;
