import React, { SVGProps } from 'react';

const WhatsappIcon: React.FC = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 21.0486L1.41254 15.8882C0.540902 14.3777 0.0828937 12.6654 0.0837311 10.9096C0.086243 5.42018 4.55329 0.953125 10.0419 0.953125C12.7053 0.953962 15.2056 1.99139 17.0862 3.87366C18.9659 5.75594 20.0008 8.25782 20 10.9188C19.9975 16.409 15.5304 20.8761 10.0419 20.8761C8.37562 20.8753 6.73365 20.4574 5.27924 19.6637L0 21.0486ZM14.7178 13.0625C14.6755 13.0421 14.6298 13.0201 14.5809 12.9957C14.3323 12.8709 13.1089 12.2689 12.8804 12.186C12.6526 12.1031 12.4868 12.0612 12.3202 12.3107C12.1544 12.5594 11.6771 13.1204 11.5323 13.2862C11.3874 13.452 11.2417 13.4729 10.9931 13.3482C10.9522 13.3276 10.8963 13.3034 10.8272 13.2735C10.4765 13.1213 9.78591 12.8218 8.99189 12.1131C8.25254 11.4533 7.75267 10.6386 7.60781 10.3891C7.46296 10.1404 7.59274 10.0056 7.71666 9.88169C7.79134 9.80757 7.87678 9.7034 7.96236 9.59906C8.00535 9.54664 8.04838 9.49417 8.0901 9.44545C8.19724 9.32342 8.24305 9.23025 8.30548 9.10327C8.31673 9.0804 8.32851 9.05642 8.3413 9.03099C8.42419 8.8652 8.38316 8.71951 8.32036 8.59475C8.27693 8.50903 8.02559 7.89656 7.8063 7.36217C7.70854 7.12395 7.61715 6.90125 7.55339 6.74764C7.37436 6.31931 7.19338 6.3204 7.04854 6.32126C7.02945 6.32138 7.01098 6.32149 6.99323 6.32061L6.51596 6.31224C6.35017 6.31224 6.08056 6.3742 5.85281 6.62372C5.83784 6.64012 5.82107 6.65805 5.8028 6.67757C5.54328 6.95499 4.98201 7.55496 4.98201 8.69941C4.98201 9.91511 5.86027 11.0896 5.99471 11.2694L5.99766 11.2733C6.00604 11.2844 6.02118 11.3061 6.0429 11.3372C6.34497 11.7699 7.9193 14.025 10.2479 15.0303C10.8415 15.2865 11.3054 15.4398 11.6663 15.5545C12.2624 15.7437 12.805 15.7169 13.2337 15.6533C13.7118 15.5821 14.7057 15.0512 14.9133 14.4702C15.121 13.8882 15.121 13.39 15.0582 13.2862C15.0084 13.2027 14.8914 13.1463 14.7178 13.0625Z" fill="white" />
        </svg>

    );
};
export default WhatsappIcon;
