export const BlockRenderer = ({
  blockType,
  blockData,
  components,
  children,
  props,
  index,
  extraProps,
}: {
  blockType: string
  blockData: any
  components: any
  children?: any
  props?: any
  index?: any
  extraProps?: {}
}) => {
  const ComponentToRender = components[blockType]
  if (!ComponentToRender) {
    return null
  }

  return (
    <ComponentToRender
      keyForComponent={index}
      data={blockData}
      {...props}
      {...extraProps}
    >
      {children}
    </ComponentToRender>
  )
}
