import { SVGProps } from "react"
interface Props extends SVGProps<SVGSVGElement> {
  color?: string
}
const XIcon = (props: Props) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18 6L6 18M18 18L6 6"
      stroke={props.color || "black"}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)
export default XIcon
