import * as React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  size: "small" | "medium" | "big"
}
const ShapreBluer = (props: Props) => {
  const { size } = props
  if (size === "big")
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={753}
        height={305}
        fill="none"
        {...props}
      >
        <g filter="url(#a)">
          <path
            fill="#000"
            fillOpacity={0.43}
            d="m568 584.942 274.639-409.777c26.844-40.053 1.435-94.281-46.522-99.286L77.822.927c-64.215-6.7-102.494 69.707-58.677 117.127l439.294 475.422c30.699 33.224 84.377 29.043 109.561-8.534Z"
          />
        </g>
        <defs>
          <filter
            id="a"
            width={973.193}
            height={735.457}
            x={-59.643}
            y={-59.484}
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation={30} />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_1795_59348"
            />
            <feBlend
              in="SourceGraphic"
              in2="effect1_backgroundBlur_1795_59348"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    )
  if (size === "medium") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={560}
        height={269}
        fill="none"
        {...props}
      >
        <g filter="url(#a)">
          <path
            fill="#000"
            fillOpacity={0.43}
            d="m452.715 502.868 209.757-350.267c24.053-40.165-1.747-91.718-48.314-96.54L77.924.524C15.909-5.899-22.928 65.847 16.357 114.26l321.948 396.752c30.623 37.739 89.441 33.552 114.41-8.143Z"
          />
        </g>
        <defs>
          <filter
            id="a"
            width={791.109}
            height={656.785}
            x={-59.475}
            y={-59.879}
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation={30} />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_1795_57766"
            />
            <feBlend
              in="SourceGraphic"
              in2="effect1_backgroundBlur_1795_57766"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    )
  }
  if (size === "small") {
    return (
      <svg
        width="316"
        height="143"
        viewBox="0 0 316 143"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_b_1795_61888)">
          <path
            d="M202.121 271.629L375.884 80.7494C394.366 60.4462 381.522 27.8316 354.183 25.6471L35.0845 0.148643C8.68148 -1.96117 -8.78189 27.0755 5.49667 49.3449L144.985 266.897C157.794 286.874 186.108 289.219 202.121 271.629Z"
            fill="#000000"
            fill-opacity="0.8"
          />
        </g>
        <defs>
          <filter
            id="filter0_b_1795_61888"
            x="-7.61719"
            y="-7.96094"
            width="400.172"
            height="299.438"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_1795_61888"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_1795_61888"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    )
  }
}
export default ShapreBluer
