import { createTheme } from "@mui/material/styles"
import { GlobalStyle } from "./GlobalStyle"
import { sharedColors } from "./colors"

export const sharedTheme = createTheme({
  direction: "rtl", // Set initial direction (will be changed dynamically)
  //theme customizations
  typography: {
    fontFamily: "inherit",
  },
  palette: {
    primary: {
      main: sharedColors.black,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: GlobalStyle,
    },
    MuiButton: {},
    MuiTabs: {
      styleOverrides: {
        indicator: {
          // Hide the default bottom border
          display: "none",
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {},
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          alignItems: "flex-end",
          "&.MuiInput-root::before": {
            borderBottom: `1px solid ${sharedColors.darkBlue}`,
            opacity: 0.3,
          },
          "&.Mui-error::before": {
            borderBottom: `1px solid ${sharedColors.red}`,
            opacity: 1,
          },

          "&.MuiInput-root::after": {
            borderBottom: `1px solid ${sharedColors.blue}`,
          },

          "&.Mui-error::after": {
            borderBottom: `1px solid ${sharedColors.red}`,
            opacity: 1,
          },
        },
        input: {
          fontSize: "18px",
          lineHeight: "24px",
          paddingBottom: "3px",
          "&:focus::placeholder": {
            fontWeight: "500",
          },
          "&::placeholder": {
            fontSize: "18px",
            lineHeight: "24px",
            color: sharedColors.black,
            fontWeight: "400",
            opacity: 1,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: sharedColors.red,
          lineHeight: "20px",
          fontWeight: 400,
          position: "absolute",
          bottom: "-20px",
          height: "20px",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: "0px",
          borderBottom: "4px solid #FCBB00",
        },
      },
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 988,
      lg: 1601,
      xl: 1801,
    },
  },
})

export type Theme = typeof sharedTheme
