import { SVGProps } from "react"
const DownloadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#0050C9"
      fillRule="evenodd"
      d="M5.997 3.4H13.7v2.017a2.7 2.7 0 0 0 2.7 2.7h2.2V20a.6.6 0 0 1-.6.6H6a.6.6 0 0 1-.6-.6V4c0-.334.268-.6.597-.6ZM20 8.117v-.7l-.724-.7-4.176-4.04L14.4 2H5.997A1.998 1.998 0 0 0 4 4v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8.117Zm-4.9-3.492 2.163 2.092H16.4a1.3 1.3 0 0 1-1.3-1.3v-.792Zm-3.6 6.708v1.417H8v-1.417h3.5ZM8 13.75h3.5v1.5H8v-1.5Zm4.5 0v1.5H16v-1.5h-3.5ZM8 16.25h3.5v1.417H8V16.25Zm4.5 0v1.417H16V16.25h-3.5Zm-.5 2.417h4a1 1 0 0 0 1-1v-6.334a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v6.334a1 1 0 0 0 1 1h4Zm.5-5.917H16v-1.417h-3.5v1.417Z"
      clipRule="evenodd"
    />
  </svg>
)
export default DownloadIcon
