import * as React from "react";
import styled from "styled-components";

export const Title = styled.h1`
  color: blue;
  font-size: 2rem;
`;

// type TitleProps = { children: string };

// export const Title = ({ children }: TitleProps) => (
//   <StyledTitle>{children}</StyledTitle>
// );
