"use client"

import styled from "@emotion/styled"
import Image from "next/image"
import { httpsImage } from "utils/utils"
import { Desktop, Mobile, useIsDesktop } from "./common/bit/MediaQueries"

const StyledImagesContainer = styled.div<{
  $height: number
  $largeHeight: number
  $mediumHeight: number
  $mobileHeight?: number
  $width?: number
  $mediumWidth?: number
  $mobileWidth?: number
  $sm?: boolean
}>`
  position: relative;
  ${(props) => props.theme.breakpoints.down("sm")} {
    height: ${(p) => (p.$mobileHeight ? `${p.$mobileHeight}px` : "480px")};
    width: ${(p) => (p.$mobileWidth ? `${p.$mobileWidth}px` : "100%")};
  }
  ${(props) => props.$sm && props.theme.breakpoints.down("md")} {
    height: ${(p) => (p.$mobileHeight ? `${p.$mobileHeight}px` : "890px")};
    width: ${(p) => (p.$mobileWidth ? `${p.$mobileWidth}px` : "100%")};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    height: ${(p) => (p.$mediumHeight ? `${p.$mediumHeight}px` : "890px")};
    width: ${(p) => (p.$mediumWidth ? `${p.$mediumWidth}px` : "100%")};
  }

  ${(props) => props.theme.breakpoints.up("lg")} {
    height: ${(p) => (p.$height ? `${p.$height}px` : "890px")};
    width: ${(p) => (p.$width ? `${p.$width}px` : "100%")};
  }
  ${(props) => props.theme.breakpoints.up("xl")} {
    height: ${(p) =>
    p.$largeHeight ? `${p.$largeHeight}px` : `${p.$largeHeight}px`};
    width: ${(p) => (p.$width ? `${p.$width}px` : "100%")};
  }
`

type ImageContainerProps = {
  height: number
  largeHeight?: number
  desktopUrl: string
  mobileUrl?: string
  alt?: string
  mobileHeight?: number
  mobileWidth?: number
  children?: JSX.Element
  width?: number
  mediumHeight?: number
  mediumWidth?: number
  sm?: boolean
  fill?: boolean
  contain?: boolean
  objectPosition?: string
  style?: any
  loading?: "eager" | "lazy"
  emptyAlt?: boolean
  priority?: boolean
}

export const ImageContainer = ({
  height,
  largeHeight = height,
  desktopUrl,
  mobileUrl = desktopUrl,
  alt,
  mobileHeight,
  children,
  width,
  mediumHeight = height,
  mediumWidth = width,
  mobileWidth,
  sm,
  fill,
  contain,
  objectPosition,
  style,
  loading,
  emptyAlt,
  priority
}: ImageContainerProps) => {
  const isDesktop = useIsDesktop()
  return (
    <StyledImagesContainer
      $largeHeight={largeHeight}
      $height={height}
      $mobileHeight={mobileHeight}
      $width={width}
      $mediumHeight={mediumHeight}
      $mediumWidth={mediumWidth}
      $mobileWidth={mobileWidth}
      $sm={sm}
    >
      <Desktop>
        <Image
          style={{
            ...style,
            objectFit: fill ? "fill" : contain ? "contain" : "cover",
            objectPosition: objectPosition,
          }}
          src={httpsImage(desktopUrl)}
          alt={emptyAlt ? "" : alt ? alt : "image"}
          fill
          quality={85}
          loading={loading || "lazy"}
        // priority={priority}
        />
      </Desktop>
      <Mobile>
        <Image
          style={{
            ...style,
            objectFit: fill ? "fill" : contain ? "contain" : "cover",
            objectPosition: objectPosition,
          }}
          src={httpsImage(mobileUrl)}
          alt={alt ? alt : "image"}
          fill
          quality={85}
          loading={loading}
          priority={priority}
        />
      </Mobile>
      {children}
    </StyledImagesContainer>
  )
}

export default ImageContainer
