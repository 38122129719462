import { SVGProps } from "react"
const AdvancedMarkerIcon = ({ fill = "#FCBB00" }: SVGProps<SVGSVGElement>) => (
    <svg width="39" height="45" viewBox="0 0 39 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_5455_72300)">
            <path
                fill={fill}
                d="M33.0007 17.8333C33.0007 23.2495 25.7876 32.0331 21.9721 36.3006C20.7285 37.6914 18.6061 37.6914 17.3626 36.3006C13.547 32.0331 6.33398 23.2495 6.33398 17.8333C6.33398 10.4695 12.3035 4.5 19.6673 4.5C27.0311 4.5 33.0007 10.4695 33.0007 17.8333Z" />
            <path
                fill="#fff"
                d="M21.9721 36.3006L22.2206 36.5228L21.9721 36.3006ZM17.3626 36.3006L17.6111 36.0784L17.3626 36.3006ZM32.6673 17.8333C32.6673 19.1202 32.237 20.6351 31.5095 22.268C30.784 23.8965 29.7741 25.6184 28.6402 27.3123C26.3724 30.7001 23.6269 33.9497 21.7236 36.0784L22.2206 36.5228C24.1328 34.384 26.9015 31.1081 29.1942 27.6832C30.3405 25.9707 31.3723 24.2144 32.1185 22.5393C32.8627 20.8687 33.334 19.2546 33.334 17.8333H32.6673ZM17.6111 36.0784C15.7078 33.9497 12.9622 30.7001 10.6945 27.3123C9.56055 25.6184 8.5506 23.8965 7.82513 22.268C7.09768 20.6351 6.66732 19.1202 6.66732 17.8333H6.00065C6.00065 19.2546 6.47192 20.8687 7.21615 22.5393C7.96237 24.2144 8.99415 25.9707 10.1405 27.6832C12.4331 31.1081 15.2018 34.384 17.1141 36.5228L17.6111 36.0784ZM6.66732 17.8333C6.66732 10.6536 12.4876 4.83333 19.6673 4.83333V4.16667C12.1194 4.16667 6.00065 10.2854 6.00065 17.8333H6.66732ZM19.6673 4.83333C26.847 4.83333 32.6673 10.6536 32.6673 17.8333H33.334C33.334 10.2854 27.2152 4.16667 19.6673 4.16667V4.83333ZM21.7236 36.0784C20.6126 37.321 18.7221 37.321 17.6111 36.0784L17.1141 36.5228C18.4901 38.0618 20.8445 38.0618 22.2206 36.5228L21.7236 36.0784Z" />
        </g>
        <circle cx="19.6674" cy="16.8803" r="5.71429" fill="#fff" />
        <defs>
            <filter id="filter0_d_5455_72300" x="0.666667" y="0.166016" width="38.0007" height="44.1784" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="1.33333" />
                <feGaussianBlur stdDeviation="2.66667" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.0823529 0 0 0 0 0.243137 0 0 0 0 0.411765 0 0 0 0.4 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5455_72300" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5455_72300" result="shape" />
            </filter>
        </defs>
    </svg>

)
export default AdvancedMarkerIcon
