import { SVGProps } from "react";
const CloseIconSmall = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={props.width || 16}
        height={props.height || 16}
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M9 1L1 9M9 9L1 1"
            stroke="#153E69"
            stroke-width="1.4"
            stroke-linecap="round" />
    </svg>
)
export default CloseIconSmall
