import React, { SVGProps } from "react"

interface Props extends SVGProps<SVGSVGElement> {
  en?: boolean
}
const BreadcrumbIcon = ({ en, ...restProps }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={6}
    height={8}
    fill="none"
    transform={en ? "scale(-1)" : ""}
    {...restProps}
  >
    <path
      fill="#0050C9"
      d="M4.942 7.7a.318.318 0 0 1-.156-.048.736.736 0 0 0-.132-.072L.874 4.976a.984.984 0 0 1-.264-.24.547.547 0 0 1-.084-.3V4.22a.49.49 0 0 1 .084-.288 1.15 1.15 0 0 1 .264-.252l3.78-2.592c.04-.024.084-.052.132-.084a.318.318 0 0 1 .156-.048.23.23 0 0 1 .168.072c.048.04.072.1.072.18v.528a.37.37 0 0 1-.072.228.913.913 0 0 1-.204.168L1.69 4.328l3.216 2.196c.088.064.156.124.204.18a.339.339 0 0 1 .072.228v.528a.244.244 0 0 1-.072.18.254.254 0 0 1-.168.06Z"
    />
  </svg>
)
export default BreadcrumbIcon
