import styled from "@emotion/styled"
import { IconButton } from "@mui/material"
import Image from "next/image"
import { httpsImage } from "utils/utils"
import ButtonWithArrow from "../ButtonWithArrow"
import { NewText } from "../NewText/NewText"
import { Desktop, Mobile } from "../common/bit/MediaQueries"
import { XIcon } from "../icons"
import { sharedColors } from "../theme/colors"

const StyledActionDiv = styled.div<{
  $height?: number
  $width?: number
  $top?: number
  $left?: number
  $center?: boolean
}>`
  height: auto;
  width: auto;
  border-radius: 20px 20px 28px 28px;
  box-shadow: 0px 16px 32px 0px #11182733;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0px 30px 0px;
  position: relative;
  background: ${sharedColors.white};
  ${(props) => props.theme.breakpoints.down("md")} {
    margin-left: 10px;
    margin-right: 10px;
    top: 80px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-left: 50px;
    ${(p) => p.$top && !p.$center && `top: ${p.$top / 2}px`};
    ${(p) => p.$left && !p.$center && `left: ${p.$left / 2}px`};
    height: ${(p) => (p.$height ? `${p.$height}px` : "auto")};
    width: ${(p) => (p.$width ? `${p.$width}px` : "auto")};
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    ${(p) => p.$top && !p.$center && `top: ${p.$top}px`};
    ${(p) => p.$left && !p.$center && `left: ${p.$left}px`};
    height: ${(p) => (p.$height ? `${p.$height}px` : "auto")};
    width: ${(p) => (p.$width ? `${p.$width}px` : "auto")};
  }

   ${(props) => props.$center && `
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}
`

const StyledImageWrapper = styled.div`
  position: relative;
  height: 430px;
  width: 100%;
  border-bottom: 1px solid rgba(21, 62, 105, 0.3);
  border-opacity: 0.3;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    object-fit: contain;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 430px;
    width: 100%;
  }
`

const StyledSvgWrapper = styled(IconButton)`
  position: absolute;
  top: 0px;
  z-index: 1;
  top: 12px;
  right: 11px;
  cursor: pointer;
  :focus-visible{
    border: 1px solid gray;
  }
`

type ActionStateDesktopProps = {
  title: string
  image: string
  buttonText: string
  buttonAction?: () => void
  height?: number
  width?: number
  imageWidth?: number
  imageHeight?: number
  top?: number
  left?: number
  handleClose?: () => void
  center?: boolean
}

function ActionStateDesktop({
  title,
  image,
  buttonText,
  height,
  buttonAction = () => { },
  handleClose = () => { },
  width,
  imageWidth = 340,
  imageHeight = 260,
  top,
  left,
  center = false
}: ActionStateDesktopProps) {
  return (
    <StyledActionDiv $height={height} $width={width} $top={top} $left={left} $center={center}>
      <StyledSvgWrapper disableRipple onClick={() => handleClose()} size="small" aria-label="סגירה">
        <XIcon />
      </StyledSvgWrapper>
      <NewText
        component={"p"}
        desktopFontSize={24}
        desktopFontWeight={500}
        desktopLineHeight={"34px"}
      >
        {title}
      </NewText>
      <StyledImageWrapper>
        <Desktop>
          <Image
            src={httpsImage(image)}
            alt=""
            height={imageHeight}
            width={imageWidth}

          />
        </Desktop>
        <Mobile>
          {" "}
          <Image quality={85} src={httpsImage(image)} alt="" fill />
        </Mobile>
      </StyledImageWrapper>
      <ButtonWithArrow
        title={buttonText}
        onClick={buttonAction}
        desktopHeight={48}
        desktopWidth={189}
        reverse={true}
        justifyContent="center"
      />
    </StyledActionDiv>
  )
}
export default ActionStateDesktop
