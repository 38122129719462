"use client"
import { useEffect, useState } from "react";

export function useKeyboardNavigation() {
    const [isKeyboardNav, setIsKeyboardNav] = useState(false);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            // if (["Tab", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight", "Enter"].includes(event.key)) {
            if (["Tab"].includes(event.key)) {
                setIsKeyboardNav(true);
            }
        };

        const handleMouseDown = () => setIsKeyboardNav(false);

        window.addEventListener("keydown", handleKeyDown);
        window.addEventListener("mousedown", handleMouseDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
            window.removeEventListener("mousedown", handleMouseDown);
        };
    }, []);

    return isKeyboardNav;
}
