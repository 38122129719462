import { SVGProps } from "react"
const PhoneCallIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <rect width={32} height={32} fill="#F6F7F9" rx={16} />
    <rect
      width={31}
      height={31}
      x={0.5}
      y={0.5}
      stroke="#D2D9E2"
      strokeOpacity={0.7}
      rx={15.5}
    />
    <path
      stroke="#0050C9"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.503 21.078s.868.854 1.082 1.104c.346.37.755.545 1.29.545.053 0 .107 0 .159-.003 1.02-.066 1.968-.463 2.68-.802a15.254 15.254 0 0 0 5.069-3.963c1.171-1.409 1.954-2.711 2.473-4.11.32-.853.436-1.518.384-2.146a1.572 1.572 0 0 0-.474-1.018l-1.17-1.169c-.169-.157-.348-.243-.523-.243-.216 0-.391.13-.501.24l-.01.01c-.21.196-.41.398-.619.614-.106.11-.216.22-.326.332l-.938.936c-.364.364-.364.7 0 1.063.1.1.196.198.296.294.288.295.061.07.36.336.007.007.014.01.017.018.296.294.24.582.179.778l-.01.03c-.244.59-.588 1.145-1.11 1.807l-.003.003c-.948 1.166-1.948 2.074-3.05 2.77-.141.09-.285.161-.423.23-.123.061-.24.12-.34.181-.013.007-.027.018-.041.024a.745.745 0 0 1-.34.086c-.285 0-.464-.178-.522-.236l-.673-.672c-.117-.117-.303-.257-.519-.257-.213 0-.388.133-.495.25l-.006.007-1.893 1.888c-.354.35-.003 1.073-.003 1.073Z"
    />
  </svg>
)
export default PhoneCallIcon
