import { SVGProps } from "react"

type ReadMoreIconProps = SVGProps<SVGSVGElement> & {
  rotate?: number // Rotation in degrees
}

const MoreIcon = ({ rotate = 0, ...props }: ReadMoreIconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 14L8 10L12 6"
      stroke="#0050C9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default MoreIcon
