import { SVGProps } from "react"
const PlusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 8L16 24M24 16L8 16"
      stroke="#0050C9"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <rect
      x="0.7"
      y="0.7"
      width="30.6"
      height="30.6"
      rx="15.3"
      stroke="#0050C9"
      strokeWidth="1.4"
    />
  </svg>
)
export default PlusIcon
