import styled from "@emotion/styled"
import { NewText } from "../NewText/NewText"
import { useIsDesktop } from "../common/bit/MediaQueries"
import { FinancialArrow } from "../icons"
import { sharedColors } from "../theme/colors"

const StyledSearchContainer = styled.div<{ en?: boolean }>`
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 2;
  width: 312.33333333px;
  height: 143px;
  background-image: ${(props) =>
    props.en
      ? 'url("/images/stock-status-mobileen.png")'
      : 'url("/images/stock-status-mobile.png")'};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 752.66666667px;
    height: 304.66666667px;
    background-image: ${(props) =>
      props.en
        ? 'url("/images/stock-status-desktopen.png")'
        : 'url("/images/stock-status-desktop.png")'};
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
`

const StyledInputContainer = styled.div`
  position: absolute;
  bottom: 8px;
  left: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 250px;
  height: 100px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 24px;
    gap: 16px;
    left: 64px;
    bottom: 38px;
    width: 567px;
    height: 188px;
  }
`

const StyledGrid = styled.div`
  display: flex;
  gap: 14px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 80px;
  }
`

const StyledData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
const StyledDate = styled.div`
  margin-top: 4px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 12px;
  }
`

const StyledArrowBox = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  ${(props) => props.theme.breakpoints.up("lg")} {
    gap: 12px;
  }
`

type Props = {
  title: string
  gate: string
  percentageChange: string
  valueChange: string
  date: string
  en: boolean
  increase: boolean
}

export const PositionedStockStatus: React.FC<Props> = ({
  title,
  gate,
  percentageChange,
  valueChange,
  date,
  en,
  increase,
}) => {
  const isLargeDesktop = useIsDesktop()
  return (
    <StyledSearchContainer en={en}>
      <StyledInputContainer>
        <NewText
          component="h1"
          desktopFontSize={36}
          desktopLineHeight="140%"
          desktopFontWeight="500"
          mobileFontWeight="400"
          mobileFontSize={20}
          mobileLineHeight={"100%"}
          color={sharedColors.white}
        >
          {title}
        </NewText>
        <StyledGrid>
          <StyledData>
            <NewText
              component="h3"
              desktopFontSize={18}
              desktopLineHeight="100%"
              desktopFontWeight="400"
              mobileFontSize={14}
              color={sharedColors.white}
            >
              {en ? "Price" : "שער"}
            </NewText>
            <NewText
              component="p"
              desktopFontSize={32}
              desktopLineHeight="100%"
              desktopFontWeight="400"
              mobileFontSize={20}
              color={sharedColors.white}
            >
              {gate}
            </NewText>
          </StyledData>
          <StyledData>
            <NewText
              component="h3"
              desktopFontSize={18}
              desktopLineHeight="100%"
              desktopFontWeight="400"
              mobileFontSize={14}
              color={sharedColors.white}
            >
              {en ? "Pct. Change" : "שינוי באחוזים"}
            </NewText>
            <StyledArrowBox>
              <FinancialArrow increase={increase} small={!isLargeDesktop} />
              <NewText
                component="p"
                desktopFontSize={32}
                desktopLineHeight="100%"
                desktopFontWeight="400"
                mobileFontSize={20}
                color={
                  increase ? sharedColors.lightGreen : sharedColors.darkRed
                }
              >
                {percentageChange}
              </NewText>
            </StyledArrowBox>
          </StyledData>
          <StyledData>
            <NewText
              component="h3"
              desktopFontSize={18}
              desktopLineHeight="100%"
              desktopFontWeight="400"
              mobileFontSize={14}
              color={sharedColors.white}
            >
              {en ? "Change" : "שינוי"}
            </NewText>

            <NewText
              component="p"
              desktopFontSize={32}
              desktopLineHeight="100%"
              desktopFontWeight="400"
              mobileFontSize={20}
              color={increase ? sharedColors.lightGreen : sharedColors.darkRed}
            >
              {valueChange}
            </NewText>
          </StyledData>
        </StyledGrid>
        <StyledDate>
          <NewText
            component="p"
            mobileFontSize={12}
            desktopFontSize={16}
            desktopFontWeight="400"
            color={sharedColors.white}
            opacity={0.8}
          >
            {date}
          </NewText>
        </StyledDate>
      </StyledInputContainer>
    </StyledSearchContainer>
  )
}

export default PositionedStockStatus
