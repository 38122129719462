import * as React from "react"
const FilterArrowIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={17}
    fill="none"
    {...props}
  >
    <path
      stroke="#153E69"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.333}
      d="m11.533 7.067-3.2 3.2-3.2-3.2"
    />
  </svg>
)
export default FilterArrowIcon
