export const getUtmParams = () => {
  const searchParams = new URLSearchParams(window.location.search)

  const Source = searchParams.get("utm_source") || ""

  const Station = searchParams.get("stn") || ""

  const cmpParams = searchParams.get("cmp")
  const PageUrl =
    cmpParams && cmpParams.length < 50 ? cmpParams : window.location.href

  const Campaign = searchParams.get("utm_campaign") || ""
  const Medium = searchParams.get("utm_medium") || ""
  const Content = searchParams.get("utm_content") || ""
  const Gclid = searchParams.get("gclid") || ""
  const Fbclid = searchParams.get("fbclid") || ""
  const Term = searchParams.get("utm_term") || ""
  const PlatformuserId = searchParams.get("PlatformuserId") || ""
  const urlData = {
    Source,
    Station,
    PageUrl,
    Campaign,
    Medium,
    Content,
    Gclid,
    Fbclid,
    Term,
    PlatformuserId,
  }
  return urlData
}
