import { SVGProps } from "react"
const PersonStickyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="282"
    height="98"
    viewBox="0 0 270 98"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_b_1156_15274)">
      <path
        d="M5.4407 34.5123L106.782 143.489C114.516 151.804 126.506 154.626 137.136 150.631L275.907 98.4835L278.7 0.892306L20.0865 0.892558C2.61627 0.892573 -6.45639 21.719 5.4407 34.5123Z"
        fill={props.color}
        fillOpacity="0.74"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_1156_15274"
        x="-39.9531"
        y="-39.1077"
        width="382.676"
        height="231.53"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="20" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_1156_15274"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1156_15274"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
export default PersonStickyIcon
