import { SVGProps } from "react"
const PhoneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    viewBox="0 0 28 28"
    {...props}
  >
    <path
      stroke="#0050C9"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3.893 21.9s1.351 1.327 1.683 1.716c.54.576 1.175.848 2.009.848.08 0 .165 0 .245-.005 1.587-.101 3.062-.72 4.168-1.248 3.024-1.46 5.68-3.535 7.886-6.164 1.822-2.191 3.04-4.217 3.847-6.393.497-1.327.679-2.362.598-3.338a2.445 2.445 0 0 0-.737-1.583L21.77 3.914c-.262-.245-.54-.378-.812-.378-.337 0-.61.203-.78.373l-.016.016a26.95 26.95 0 0 0-.962.955c-.165.17-.336.34-.507.517l-1.459 1.455c-.566.566-.566 1.088 0 1.653.155.155.304.31.46.459.448.458.096.107.56.523.011.01.022.016.027.026.46.459.374.907.278 1.21l-.016.049c-.38.917-.914 1.78-1.726 2.81l-.005.005c-1.475 1.813-3.03 3.226-4.745 4.308-.219.139-.443.25-.657.358a6.762 6.762 0 0 0-.529.282c-.021.01-.043.027-.064.037a1.16 1.16 0 0 1-.529.134c-.443 0-.721-.278-.812-.368l-1.047-1.045c-.182-.182-.47-.4-.807-.4-.331 0-.604.208-.77.389l-.01.01-2.944 2.939c-.55.543-.005 1.668-.005 1.668Z"
    />
  </svg>
)
export default PhoneIcon
