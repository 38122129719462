import styled from "@emotion/styled"
import React from "react"

const StyledContainer = styled("div") <{
  withoutPadding: boolean
  isSmall: boolean
  padding: number
  mobilePadding?: string
  largeDesktopWidth: number
  mediumDesktopWidth: number
}>`
  padding-left: ${({ withoutPadding, mobilePadding }) => (withoutPadding ? "0px " : mobilePadding)};
  padding-right: ${({ withoutPadding, mobilePadding }) => (withoutPadding ? "0px " : mobilePadding)};
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: ${(props) => (`${props.mediumDesktopWidth}px`)};
    margin: auto;
    padding: 0px;
    padding-left: ${(props) => (props.padding ? `${props.padding}px` : "0px")};
    padding-right: ${(props) => (props.padding ? `${props.padding}px` : "0px")};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    width: 1280px;
    margin: auto;
    padding: 0px;
    padding-left: ${(props) => (props.padding ? `${props.padding}px` : "0px")};
    padding-right: ${(props) => (props.padding ? `${props.padding}px` : "0px")};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.values.xl}px) {
    width: ${(props) => (`${props.largeDesktopWidth}px`)};
    margin: auto;
    padding: 0px;
    padding-left: ${(props) => (props.padding ? `${props.padding}px` : "0px")};
    padding-right: ${(props) => (props.padding ? `${props.padding}px` : "0px")};
  }
`
type Props = {
  children?: React.ReactNode
  withoutPadding?: boolean
  isSmall?: boolean
  padding?: number
  mobilePadding?: string
  largeDesktopWidth?: number
  mediumDesktopWidth?: number
}

export const Container: React.FC<Props> = ({
  isSmall = true,
  children,
  withoutPadding = false,
  padding = 0,
  mobilePadding = '20px',
  largeDesktopWidth = 1480,
  mediumDesktopWidth = 988
}) => {
  return (
    <StyledContainer
      isSmall={isSmall}
      withoutPadding={withoutPadding}
      padding={padding}
      mobilePadding={mobilePadding}
      largeDesktopWidth={largeDesktopWidth}
      mediumDesktopWidth={mediumDesktopWidth}
    
    >
      {children}
    </StyledContainer>
  )
}

export default Container
