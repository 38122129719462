import * as React from "react"
const ShareIconNew = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="none"
    {...props}
  >
    <circle cx={14.998} cy={15} r={15} fill="#fff" />
    <path
      stroke="#111827"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9.229 15.29v5.768a1.442 1.442 0 0 0 1.442 1.443h8.654a1.443 1.443 0 0 0 1.442-1.443V15.29M17.884 10.963 15 8.078l-2.885 2.885M14.998 8.078v9.375"
    />
  </svg>
)
export default ShareIconNew
