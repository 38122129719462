"use client"
import styled from "@emotion/styled"
import { Button } from "@mui/material"
import Image from "next/image"
import { useState } from "react"

import React from "react"
import SwiperCore from "swiper"
import { EffectCreative, Keyboard } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import { extractProperty } from "utils"
import { httpsImage } from "utils/utils"
import AlertMessageSROnly from "./AlertMessageSROnly"
import PositionedCorpText from "./Hero/PositionedCorpText"
import PositionedText from "./Hero/PositionedText"
import { NewText } from "./NewText/NewText"
import { Desktop, Mobile, SmallDesktop, useIsDesktop, XSmall } from "./common/bit/MediaQueries"
import {
  LeftArrow,
  LeftMobileArrow,
  RightArrow,
  RightMobileArrow,
} from "./icons"
import { sharedColors } from "./theme/colors"

const StyledContainer = styled.div`
  display: flex;
`
const StyledPreDiv = styled.div`
  width: 28px;
  flex-shrink: 0;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 150px;
  }
`

const StyledActiveImage = styled.div`
  opacity: 1;
  position: relative;
  width: 100%;
  height: 420px;
  ${(props) => props.theme.breakpoints.up("md")} {
    height: auto;
    aspect-ratio: 1180 / 547;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    aspect-ratio: 1770 / 820;
  }
`;

const StyledArrowsContainer = styled.div`
  background: ${(props) => sharedColors.yellow};
  width: 88px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  ${(props) => props.theme.breakpoints.up("md")} {
    width: 277px;
    height: 69px;
    gap: 12px;
  }
`

const StyledImageWithArrows = styled.div<{
  hasArrows: boolean
  hasAttachments: boolean
}>`
  width: calc(100% - 28px);
  height: ${(props) =>
    props.hasArrows ? "452px" : props.hasAttachments ? "auto" : "420px"};
  ${(props) => props.theme.breakpoints.up("md")} {
      height: auto;
    width: calc(100% - 150px);
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
  height: auto;
  }
`

const StyledImagesContainer = styled.div`
  position: relative;
  width: 100%;
`

const StyledArrowTextContainer = styled.div`
  width: 22px;
  text-align: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 48px;
  }
`

const StyledArrowContainer = styled(Button)`
  padding: 0px;
  min-width: auto;
  border-radius:0;
  :focus-visible{
    outline:2px solid blue;
  }
`

type Props = {
  data: any
  children?: JSX.Element
  attachments?: JSX.Element
  corp?: boolean
  color?: string
}

export const Hero: React.FC<Props> = ({
  data,
  children,
  attachments,
  corp,
  color,
}) => {
  const slideBlocks = extractProperty(data, "slides")

  const images = slideBlocks.map((slideBlock: any) => {
    const titleProp = extractProperty(slideBlock, "title")
    return {
      desktopImage: extractProperty(slideBlock, "deskopImage"),
      mobileImage: extractProperty(slideBlock, "mobileImage"),
      title: titleProp,
      paragraph: extractProperty(slideBlock, "body"),
      alt: titleProp,
    }
  })

  // The rest of your existing Hero logic
  const [current, setCurrent] = useState(0)
  const [swiperController, setSwiperController] = useState<SwiperCore | null>(
    null,
  )
  const isDesktop = useIsDesktop()

  const nextSlide = () => {
    swiperController?.slideNext()
  }

  const prevSlide = () => {
    swiperController?.slidePrev()
  }
  const handleSlideChange = (swiper: SwiperCore) => {
    setCurrent(swiper.realIndex)
  }

  if (!Array.isArray(images) || images.length <= 0) {
    return null
  }
  return (
    <StyledContainer>
      <StyledPreDiv />
      <StyledImageWithArrows
        hasArrows={images.length > 1}
        hasAttachments={!!attachments}
      >
        <StyledImagesContainer>
          {children}
          <div aria-label-="גלריית תמונות"
            role="region">
            <Swiper
              // tabIndex={0}

              onSwiper={setSwiperController}
              style={{ position: "relative", width: "100%", cursor: "grab" }}
              effect="creative"
              modules={[EffectCreative, Keyboard]}
              keyboard={{ // Add keyboard configuration
                enabled: true,
                // onlyInViewport: true,

              }}
              speed={isDesktop ? 800 : 500}
              creativeEffect={{
                prev: {
                  shadow: true,
                  translate: ["20%", 0, -1],
                },
                next: {
                  translate: ["-100%", 0, 0],
                },
              }}
              onSlideChange={handleSlideChange}
              loop={true}
            >
              {images?.map((image, index) => (
                <SwiperSlide key={index}>

                  <StyledActiveImage>
                    {!corp && !!image.title && !!image.paragraph && (
                      <PositionedText
                        corp={corp}
                        title={image.title}
                        paragraph={image.paragraph}
                        width={"100%"}
                        color={color || sharedColors.black}
                        outsideLine
                      />
                    )}
                    {corp && !!image.title && !!image.paragraph && (
                      <PositionedCorpText
                        corp={corp}
                        title={image.title}
                        paragraph={image.paragraph}
                        width={"100%"}
                        color={color || sharedColors.black}
                      />
                    )}
                    {/* ABSOLUTELY POSITIONED ITEMS OVER IMAGE */}


                    <SmallDesktop>
                      <Image
                        style={{ objectFit: "cover" }}
                        src={httpsImage(image.desktopImage)}
                        alt={image.alt}
                        fill
                        quality={85}
                        priority
                        loading="eager"
                      />
                    </SmallDesktop>
                    <XSmall>
                      <Image
                        style={{ objectFit: "cover" }}
                        src={httpsImage(image.mobileImage)}
                        alt={image.alt}
                        fill
                        quality={85}
                        priority
                        loading="eager"
                      />
                    </XSmall>
                  </StyledActiveImage>

                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </StyledImagesContainer>
        {attachments}
        {images.length > 1 && (
          <StyledArrowsContainer>
            <StyledArrowContainer
              disableRipple={true}
              onClick={() => prevSlide()}
              aria-label='הבאנר הקודם'
            >
              <Desktop>
                <RightArrow />
              </Desktop>
              <Mobile>
                <RightMobileArrow />
              </Mobile>
            </StyledArrowContainer>
            <StyledArrowTextContainer>
              <NewText
                desktopFontSize={18}
                desktopLineHeight="24px"
                mobileFontSize={12}
                mobileLineHeight="150%"
                component="p"
                desktopFontWeight="400"
                color={sharedColors.black}
              >
                {current + 1}/{images.length}
              </NewText>
            </StyledArrowTextContainer>
            <StyledArrowContainer
              disableRipple={true}
              onClick={() => nextSlide()}
              aria-label='הבאנר הבא'
            >
              <Desktop>
                <LeftArrow />
              </Desktop>
              <Mobile>
                <LeftMobileArrow />
              </Mobile>
            </StyledArrowContainer>

            <AlertMessageSROnly component={"h2"}>
              {images[current].title} {images[current].paragraph}
            </AlertMessageSROnly>

          </StyledArrowsContainer>
        )}
      </StyledImageWithArrows>
    </StyledContainer>
  )
}

export default Hero
