import styled from "@emotion/styled"
import { useMemo, useState } from "react"
import "swiper/css"
import "swiper/css/effect-coverflow"
import "swiper/css/effect-fade"
import { extractProperty } from "utils"

import HistorySwiperText from "./HistorySwiperText"
import { NewText } from "../../NewText/NewText"
import { Desktop, Mobile } from "../../common/bit/MediaQueries"
import CircularGallery from "./CircularGallery"

const StyledHistoryWrapper = styled.div`
  margin-top: 40px;
  ${(props) => props.theme.breakpoints.up("md")} {
    display: grid;
    grid-template-columns: 50% 1fr;
    margin-top: 70px;
    gap: 60px;
    min-height: 600px;
    align-items: center;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    min-height: 900px;
    gap: 90px;
    margin-top: 100px;
  }
`
const StyledTextSection = styled.div`
  padding-bottom: 40px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-bottom: 0px;
  }
`

const StyledHistoryDiv = styled.div`
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-right: 0;
    min-height: 600px;
    padding-left: 146px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    min-height: 900px;
    padding-left: 220px;
  }
`

const StyledTextWrapper = styled.div`
  margin-top: 12px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 23px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    width: 85%;
    margin-top: 35px;
  }
`

function HistoryBlock({ data, locale }: { data: any; locale: string }) {
  const contentTitle = extractProperty(data, "conetntTitle")
  const innerText = extractProperty(data, "innerText")
  const innerSlider = extractProperty(data, "innerSlider")

  const [activeIndex, setActiveIndex] = useState(0)

  const dataToMap = useMemo(
    () =>
      innerSlider.map((inner: any) => ({
        year: extractProperty(inner, "sliderTitle"),
        description: extractProperty(inner, "slidContent"),
        logo: extractProperty(inner, "slidLogo"),
      })),
    [innerSlider],
  )

  const moveNext = () => {
    // Update the angle to move the element around the circle
    setActiveIndex((prev) => (prev + 1 === dataToMap.length ? 0 : prev + 1))
  }

  const moveBack = () => {
    // Update the angle to move the element around the circle
    setActiveIndex((prev) => (prev - 1 === 0 ? dataToMap.length - 1 : prev - 1))
  }

  return (
    <StyledHistoryDiv>
      <StyledHistoryWrapper>
        <StyledTextSection>
          <NewText
            component={"h2"}
            desktopFontWeight={500}
            largeDesktopFontSize={64}
            largeDesktopLineHeight="76.8px"
            desktopFontSize={42}
            desktopLineHeight={"50.4px"}
            mobileFontSize={36}
            mobileLineHeight={"50.4px"}
          >
            {contentTitle}
          </NewText>
          <StyledTextWrapper>
            <NewText
              component={"p"}
              largeDesktopFontSize={36}
              largeDesktopLineHeight="50.4px"
              desktopFontSize={24}
              desktopLineHeight={"33.6px"}
              mobileFontSize={20}
              mobileLineHeight={"28x"}
              dangerouslySetInnerHTML={{ __html: innerText }}
            />
          </StyledTextWrapper>
          <Desktop>
            <HistorySwiperText
              year={dataToMap[activeIndex].year}
              description={dataToMap[activeIndex].description}
              setActiveIndex={setActiveIndex}
              activeIndex={activeIndex}
              dataLength={dataToMap.length}
              logo={dataToMap[activeIndex]?.logo}
              items={dataToMap}
            />
          </Desktop>
        </StyledTextSection>
        <CircularGallery
          locale={locale}
          imagesData={innerSlider}
          active={activeIndex}
          moveNext={moveNext}
          moveBack={moveBack}
        />
        <Mobile>
          <HistorySwiperText
            year={dataToMap[activeIndex].year}
            description={dataToMap[activeIndex].description}
            setActiveIndex={setActiveIndex}
            activeIndex={activeIndex}
            dataLength={dataToMap.length}
            logo={dataToMap[activeIndex]?.logo}
            items={dataToMap}
          />
        </Mobile>
      </StyledHistoryWrapper>
    </StyledHistoryDiv>
  )
}

export default HistoryBlock
