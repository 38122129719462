import { SVGProps } from "react"
const PrinterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#0050C9"
      fillRule="evenodd"
      d="M7.976 2v4.812h8.049V2h1.4v4.812h1.024a3.7 3.7 0 0 1 3.7 3.7v5.661a.7.7 0 0 1-.7.7h-4.724a.7.7 0 0 1-.7-.7v-2.056H8.276a.3.3 0 0 0-.3.3v5.086a.3.3 0 0 0 .3.3h7.449a.3.3 0 0 0 .3-.3v-.968a.7.7 0 1 1 1.4 0v.969a1.7 1.7 0 0 1-1.7 1.7H8.276a1.7 1.7 0 0 1-1.7-1.7v-2.63H2.552a.7.7 0 0 1-.7-.7v-5.662a3.7 3.7 0 0 1 3.7-3.7h1.024V2h1.4Zm9.449 13.473h3.324v-4.961a2.3 2.3 0 0 0-2.3-2.3H5.552a2.3 2.3 0 0 0-2.3 2.3v4.961h3.324v-1.056a1.7 1.7 0 0 1 1.7-1.7h8.449a.7.7 0 0 1 .7.7v2.056ZM4.914 10.661a.787.787 0 1 0 0-1.574.787.787 0 0 0 0 1.574Z"
      clipRule="evenodd"
    />
  </svg>
)
export default PrinterIcon
