import styled from "@emotion/styled"
import { css, IconButton } from "@mui/material"
import { useLocale } from "next-intl"
import Image from "next/image"
import { Dispatch, SetStateAction, useRef } from "react"
import { Swiper as SwiperType, } from 'swiper'
import "swiper/css"
import "swiper/css/effect-coverflow"
import "swiper/css/effect-fade"
import { A11y, EffectFade, Keyboard } from "swiper/modules"; // Import the fade effect module
import { Swiper, SwiperSlide, } from "swiper/react"
import { useKeyboardNavigation } from "utils"
import { httpsImage } from "utils/utils"
import { NewText } from "../../NewText/NewText"
import { TitleWithLine } from "../../TitleWithLine/TitleWithLine"
import { Desktop, Mobile, useIsDesktop } from "../../common/bit/MediaQueries"
import { CircleLeftIcon, CircleRightIcon } from "../../icons"


const StyledSwiper = styled(Swiper) <{ isKeyNav: boolean }>`
  ${p => p.isKeyNav && css`
    overflow: visible !important;
    height: auto !important;

    .swiper-wrapper{
      height: auto !important;
      flex-direction: column !important;
      .swiper-slide{
        transform: none !important;
        opacity: 1 !important;
        visibility: visible !important;
      }
    }

  `}
`


const StyledTextWrapper = styled.div<{ $locale: string }>`
  display: flex;
  gap: 12px;

  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 28px;
    margin-top: 34px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    margin-top: 44px;
  }
`


const StyledButtonWrapper = styled.div<{ $locale: string }>`
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: flex-start;
  padding-inline-start: 28px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 18px;
    svg {
      width: 26px;
      height: 26px;
    }
    padding-inline-start: 40px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    gap: 28px;
    svg {
      width: 40px;
      height: 41px;
    }
  }
  svg {
    ${(p) =>
    p.$locale === "en" &&
    css`
        transform: rotate(180deg);
      `}
  }
`

const StyledButtonBase = styled(IconButton)`
padding:0;
:focus-visible{
  border: 1px solid gray;
}`


const StyledLogoTextWrapper = styled.div<{ $locale?: string }>`
  display: flex;
  flex-direction: column;
  padding-left: 28px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-left: 40px;
    /* justify-content: center; */
  }
  img {
    object-fit: contain;
  }
`
const StyledSwiperSlide = styled(SwiperSlide)`
  visibility: hidden;
  transition-property: opacity, visibility !important;
  &.swiper-slide-active {
    visibility: visible;
  }
`

type HistorySwiperTextProps = {
  year: string
  description: string
  setActiveIndex: Dispatch<SetStateAction<number>>
  activeIndex: number
  dataLength: number
  logo: string
  items: any[]
}

function HistorySwiperText({
  activeIndex,
  setActiveIndex,
  dataLength,
  items,
}: HistorySwiperTextProps) {
  const isKeyNav = useKeyboardNavigation()
  const isDesktop = useIsDesktop()
  const locale = useLocale()
  const swiperRef = useRef<SwiperType | null>(null)

  const goToPrevious = () => {
    setActiveIndex(activeIndex === 0 ? dataLength - 1 : activeIndex - 1)
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  }

  const goToNext = () => {
    setActiveIndex(activeIndex === dataLength - 1 ? 0 : activeIndex + 1)
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  }
  return (
    <>
      <StyledSwiper
        navigation={{ prevEl: '.right-arrow', nextEl: '.left-arrow' }}
        initialSlide={activeIndex}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        effect="fade"
        fadeEffect={{ crossFade: true }}
        loop={true}
        modules={[EffectFade, A11y, Keyboard]}
        a11y={{ enabled: true, containerMessage: "לניווט ניתן להשתמש בחיצי המקלדת" }}
        autoHeight
        keyboard={{ enabled: true }}
        enabled={!isKeyNav}
        isKeyNav={isKeyNav}
      >
        {items.map((item, index) => (
          <StyledSwiperSlide key={index} aria-label={undefined}>
            <StyledTextWrapper $locale={locale} aria-label={item.year}>
              <TitleWithLine
                marginLeft={isDesktop ? 40 : 0}
                title={item.year}
                largeDesktopFontSize={88}
                largeDesktopLineHeight="123.3px"
                titleFontSize={58}
                desktopLineHeight="81.2px"
                mobileTitleFontSize={64}
                mobileLineHeight="96px"
                titleColor="black"
                component={"h3"}
              />
            </StyledTextWrapper>
            <StyledLogoTextWrapper $locale={locale}>
              {item.logo && (
                <>
                  <Desktop>
                    <Image
                      quality={85}
                      src={httpsImage(item.logo)}
                      alt="logo"
                      width={68}
                      height={68}
                    />
                  </Desktop>
                  <Mobile>
                    <Image
                      quality={85}
                      src={httpsImage(item.logo)}
                      alt="logo"
                      width={48}
                      height={48}
                    />
                  </Mobile>
                </>
              )}
              <NewText
                component={"div"}
                largeDesktopFontSize={28}
                largeDesktopLineHeight="39.2px"
                desktopFontSize={18}
                desktopLineHeight={"25.2px"}
                mobileFontSize={18}
                mobileLineHeight={"25.2px"}
                dangerouslySetInnerHTML={{ __html: item.description }}
              />

            </StyledLogoTextWrapper>
          </StyledSwiperSlide>
        ))}
      </StyledSwiper>
      {!isKeyNav && (
        <StyledButtonWrapper $locale={locale}>
          <StyledButtonBase disableRipple className="right-arrow" aria-label={locale === 'en' ? 'show previous' : 'הצג קודם'} onClick={goToPrevious}>
            <CircleRightIcon color="black" opacity={1} />
          </StyledButtonBase>
          <StyledButtonBase disableRipple className="left-arrow" aria-label={locale === 'en' ? 'show next' : 'הצג הבא'} onClick={goToNext}>
            <CircleLeftIcon color="black" opacity={1} />
          </StyledButtonBase>
        </StyledButtonWrapper>
      )}
    </>
  )
}
export default HistorySwiperText
