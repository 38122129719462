import { SVGProps } from "react"
const FilterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#5C6474"
      strokeLinejoin="round"
      strokeWidth={1.4}
      d="M2.5 5.833V3.5a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v2.333m-15 0 5.248 5.248a2 2 0 0 1 .585 1.414V17.5l2.78-1.39a1 1 0 0 0 .554-.895v-2.72a2 2 0 0 1 .585-1.414L17.5 5.833m-15 0h15"
    />
  </svg>
)
export default FilterIcon
