
export const sharedColors = {
  black: "#000000",
  white: "#FFFFFF",
  red: "#FF0000",
  darkRed: "#D10040",
  green: "#00FF00",
  darkBlue: "#153E69",
  darkBlueTransparent: "rgba(21, 62, 105, 0.1)",
  primaryDarkBlue: "#1A2B68",
  bluishGray: "#BAD2D4",
  yellow: "#FCBB00",
  gray: "#111827",
  lightGray: "#6D7280",
  disableGray: "#696F7A",
  lightRed: "#F2F0F0",
  mint: "#040B14",
  blue: "#0050C9",
  lighterBlack: "#666666",
  darkGray: "#5C6474",
  lightGreen: "#66CC00",
  sliderBlue: "#2642A6",
  darkestBlue: "#1B1F2F",
  borderBlue: "#153E694D",
  deepBlue: "#153E690F",
  veryLightGray: "#EEF0F3",
  greyTitle: "#444C68",
  orange: "rgba(252, 187, 0, 1)",
  purple: "#7A3FBF"
}
