import { SVGProps } from "react"
const EmailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    viewBox="0 0 28 28"
    {...props}
  >
    <path
      stroke="#0050C9"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.4}
      d="m4.2 7.122 9.8 7.7 10.5-7.7m-15.167 8.75L4.2 21.122m20.371.124-8.03-8.288M5.6 22.278a2.8 2.8 0 0 1-2.8-2.8V8.522a2.8 2.8 0 0 1 2.8-2.8h16.8a2.8 2.8 0 0 1 2.8 2.8v10.956a2.8 2.8 0 0 1-2.8 2.8H5.6Z"
    />
  </svg>
)
export default EmailIcon
