'use client'
import styled from '@emotion/styled'
import { Modal } from '@mui/material'
import { useState } from 'react'
import ActionStateDesktop from './ActionStateDesktop'

const StyledActionMobile = styled.div``

function ActionStateMobile() {
  const [open, setOpen] = useState(false)

  const handleClose = () => setOpen(false)
  return (
    <StyledActionMobile>
      <Modal open={open} onClose={handleClose}>
        <ActionStateDesktop
          title="פנייתך נשלחה בהצלחה"
          image="/images/success.png"
          buttonText="חזרה"
          height={554}
        />
      </Modal>
    </StyledActionMobile>
  )
}

export default ActionStateMobile
