import { SVGProps } from "react"
const ShareIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="16" fill="rgba(21, 62, 105, 0.12)" />
    <path
      d="M9.99988 15.6392L16.3999 11.3999L16.3999 13.7999C22 14.9999 22 20.5999 22 20.5999C22 20.5999 19.6 17.3999 16.3999 17.7999L16.3999 20.2799L9.99988 15.6392Z"
      stroke="#0050C9"
      strokeWidth="1.4"
      strokeLinejoin="round"
    />
  </svg>
)
export default ShareIcon
