"use client"

import styled from "@emotion/styled"
import { ElementType, useState } from "react"
import LinkComponent from "../LinkComponent"
import { NewText } from "../NewText/NewText"
import { SearchInput } from "../SearchInput/SearchInput"
import { Desktop, useIsDesktop } from "../common/bit/MediaQueries"
import { ReadMoreIcon } from "../icons"
import { sharedColors } from "../theme/colors"

const StyledSearchContainer = styled.div<{
  $desktopUrl: string
  $mobileUrl: string
  $bottomDesktop?: number
  $bottomMobile?: number
}>`
  position: absolute;
  right: 0px;
  bottom: 10px;
  z-index: 2;
  width: 355px;
  height: 114px;
  display: flex;
  align-items: center;
  background-image: url(${(p) => p.$desktopUrl});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 36px;

  ${(props) => props.theme.breakpoints.up("md")} {
    bottom: ${(props) => props?.$bottomDesktop || "66"}px;
    width: 664.33px;
    height: 199px;
    padding: 77px;
    background-image: url(${(p) => p.$desktopUrl});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    ${(props) => props?.$bottomMobile && `bottom: ${props?.$bottomMobile}px`}
  }
`

const StyledInputContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 4px;
  transform: translateX(-5px);
  ${(props) => props.theme.breakpoints.up("md")} {
    transform: translateX(-20px);
    gap: 16px;
  }
`
const StyledInput = styled.div`
  ${(props) => props.theme.breakpoints.down("md")} {
    transform: translateX(-5px);
  }
`
const StyledGap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  a {
    flex-direction: row-reverse;
  }
  svg {
    margin-left: 10px;
  }
`

type Props = {
  title: string
  subtitle: string
  placeholder: string
  desktopUrl: string
  mobileUrl: string
  color?: string
  searchIcon?: boolean
  linkText?: string
  bottomDesktop?: number
  bottomMobile?: number
  component?: ElementType<any>
}

export const PositionedSearch: React.FC<Props> = ({
  title,
  subtitle,
  placeholder,
  desktopUrl,
  mobileUrl,
  color = sharedColors.white,
  linkText,
  bottomDesktop,
  bottomMobile,
  component = "p"
}) => {
  const [value, setValue] = useState<string>("")
  const isDesktop = useIsDesktop()
  // console.log()
  return (
    <StyledSearchContainer
      $desktopUrl={desktopUrl}
      $mobileUrl={mobileUrl}
      $bottomDesktop={bottomDesktop}
      $bottomMobile={bottomMobile}
    >
      <StyledInputContainer>
        <StyledGap>
          <StyledTextWrapper>
            <NewText
              component={component}
              largeDesktopFontSize={28}
              largeDesktopLineHeight="35.84px"
              desktopFontSize={28}
              desktopLineHeight="35.84px"
              mobileFontSize={20}
              mobileLineHeight={"28px"}
              desktopFontWeight={500}
              color={color}
            >
              {title}
            </NewText>
            {linkText && (
              <LinkComponent
                text={"לכל המוצרים"}
                link={"/products"}
                mobileFontSize={14}
                fontSize={16}
                fontWeight={500}
                icon={<ReadMoreIcon />}
              />
            )}
          </StyledTextWrapper>
          <Desktop>
            <NewText
              component={"span"}
              largeDesktopFontSize={18}
              largeDesktopLineHeight="24px"
              desktopFontSize={18}
              desktopLineHeight="24px"
              fontWeight="400"
              color={color}
            >
              {subtitle}
            </NewText>
          </Desktop>
        </StyledGap>
        <StyledInput>
          <SearchInput
            height={!isDesktop ? "40px" : undefined}
            placeholder={placeholder}
            searchIcon={isDesktop ? false : true}
            radius="200px"
            value={value}
            setValue={setValue}
          />
        </StyledInput>
      </StyledInputContainer>
    </StyledSearchContainer>
  )
}

export default PositionedSearch
