import { SVGProps } from "react"
const LinkedinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM5.76082 9.18892H8.48028V17.3598H5.76082V9.18892ZM8.65939 6.66135C8.64174 5.8602 8.06883 5.25 7.13852 5.25C6.20821 5.25 5.60001 5.8602 5.60001 6.66135C5.60001 7.4459 6.19023 8.07367 7.10322 8.07367H7.1206C8.06883 8.07367 8.65939 7.4459 8.65939 6.66135ZM15.1566 8.99706C16.9461 8.99706 18.2877 10.1651 18.2877 12.6749L18.2876 17.3598H15.5682V12.9884C15.5682 11.8904 15.1747 11.1411 14.1902 11.1411C13.4389 11.1411 12.9914 11.6462 12.7949 12.1341C12.723 12.3089 12.7053 12.5525 12.7053 12.7967V17.36H9.98554C9.98554 17.36 10.0214 9.95585 9.98554 9.18914H12.7053V10.3465C13.0663 9.79013 13.7127 8.99706 15.1566 8.99706Z"
      fill="#0050C9"
    />
  </svg>
)
export default LinkedinIcon
