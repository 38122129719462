const ShowListIcon = ({ fill = "#FFF" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none">
    <path
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.4}
      d="M12.9 5H2.165m10.733 5.4H2.166m10.733 5.4H2.166m15-10.8v.01m0 5.39v.01m0 5.39v.01"
    />
  </svg>
)
export default ShowListIcon
