import { SVGProps } from "react"
const PrintIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.97548 2V6.81181H16.0243V2H17.4243V6.81181H18.4487C20.4922 6.81181 22.1487 8.46836 22.1487 10.5118V16.1732C22.1487 16.5598 21.8353 16.8732 21.4487 16.8732H16.7243C16.3377 16.8732 16.0243 16.5598 16.0243 16.1732V14.1173H8.27548C8.1098 14.1173 7.97548 14.2516 7.97548 14.4173V16.1732V19.5039C7.97548 19.6696 8.1098 19.8039 8.27548 19.8039H15.7243C15.89 19.8039 16.0243 19.6696 16.0243 19.5039V18.5354C16.0243 18.1488 16.3377 17.8354 16.7243 17.8354C17.1109 17.8354 17.4243 18.1488 17.4243 18.5354V19.5039C17.4243 20.4428 16.6632 21.2039 15.7243 21.2039H8.27548C7.3366 21.2039 6.57548 20.4428 6.57548 19.5039V16.8732H2.55107C2.16447 16.8732 1.85107 16.5598 1.85107 16.1732V10.5118C1.85107 8.46836 3.50762 6.81181 5.55107 6.81181H6.57548V2H7.97548ZM17.4243 15.4732H20.7487V10.5118C20.7487 9.24156 19.719 8.21181 18.4487 8.21181H5.55107C4.28082 8.21181 3.25107 9.24156 3.25107 10.5118V15.4732H6.57548V14.4173C6.57548 13.4784 7.3366 12.7173 8.27548 12.7173H16.7243C17.1109 12.7173 17.4243 13.0307 17.4243 13.4173V15.4732ZM4.91328 10.6614C5.34815 10.6614 5.70068 10.3089 5.70068 9.87401C5.70068 9.43914 5.34815 9.08661 4.91328 9.08661C4.47841 9.08661 4.12588 9.43914 4.12588 9.87401C4.12588 10.3089 4.47841 10.6614 4.91328 10.6614Z"
      fill="#0050C9"
    />
  </svg>
)
export default PrintIcon
