import { SVGProps } from "react"

const RightArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={45}
    height={18}
    fill="none"
    {...props}
  >
    <path
      stroke="#111827"
      strokeLinejoin="bevel"
      d="M.5 9H44m0 0-8-8m8 8-8 8"
    />
  </svg>
)
export default RightArrow
