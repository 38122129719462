"use client"
import styled from "@emotion/styled"
import {
  Button,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material"
import Image from "next/image"
import Link from "next/link"
import { usePathname, useRouter } from "next/navigation"
import React, { useState } from "react"
import { extractMenuProperties } from "utils"
import { getNonUmbracoURL } from "utils/utils"
import { Text } from "../Text/Text"
import { Desktop, Mobile } from "../common/bit/MediaQueries"
import { sharedColors } from "../theme/colors"

const StyledLink = styled("li", {
  shouldForwardProp: (prop) =>
    prop !== "selected" && prop !== "color" && prop !== "isLast",
}) <{
  selected?: boolean
  color: string
  isLast?: boolean
  paddingLeft?: number
}>`
  position: relative;
  font-size: 18px;
  line-height: 24px;
  color: ${(props) => props.color};

  text-decoration: none;
  padding: 10px 0px;
  padding-left: 20px;

  p {
    font-weight: ${(props) => (props.selected ? "500" : "400")};
  }
  border-bottom: ${(props) => (props.isLast ? "none" : " 1px dashed #a8acb4")};
  ${(props) => props.theme.breakpoints.up("md")} {
    transition: 300ms all ease;
    padding: 34px 24px 40px 24px;
    border-bottom: none;
    box-shadow: ${(props) =>
    props.selected ? "0px 8px 0px #fcbb00 inset" : "none"};
    :hover {
      box-shadow: 0px 8px 0px #fcbb00 inset;
    }
  }
`

const StyledMenuItem = styled.div`
  a {
    text-decoration: none;
    color: #000;
  }
`

const StyledMenu = styled(Menu)``

const StyledDiv = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "selected" && prop !== "color" && prop !== "isLast",
}) <{
  selected?: boolean
  color: string
  isLast?: boolean
}>`
  position: relative;
  font-size: 18px;
  line-height: 24px;
  color: ${(props) => props.color};

  text-decoration: none;
  button {
    height: 98px;
    padding: 34px 24px 40px 24px;
    :hover {
      background: transparent;
    }
  }

  p {
    font-weight: ${(props) => (props.selected ? "500" : "400")};
  }
  border-bottom: ${(props) => (props.isLast ? "none" : " 1px dashed #a8acb4")};
  ${(props) => props.theme.breakpoints.up("md")} {
    transition: 300ms all ease;
    border-bottom: none;
    box-shadow: ${(props) =>
    props.selected ? "0px 8px 0px #fcbb00 inset" : "none"};
    :hover {
      box-shadow: 0px 8px 0px #fcbb00 inset;
    }
  }
`

const StyledHash = styled("a", {
  shouldForwardProp: (prop) =>
    prop !== "selected" && prop !== "color" && prop !== "isLast",
}) <{
  selected?: boolean
  color: string
  isLast?: boolean
}>`
  font-size: 18px;
  line-height: 24px;
  color: ${(props) => props.color};

  text-decoration: none;
  padding: 10px 0px;
  p {
    font-weight: ${(props) => (props.selected ? "500" : "400")};
  }
  border-bottom: ${(props) => (props.isLast ? "none" : " 1px dashed #a8acb4")};
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 0px 24px;
    border-bottom: none;
  }
`

const MobileLi = styled.li`
  ${(props) => props.theme.breakpoints.up("md")} {
    display: none;
  }
`

const DesktopLi = styled.li`
  ${(props) => props.theme.breakpoints.down("md")} {
    display: none;
  }
`

const StyledMobileButton = styled(ListItemButton, {
  shouldForwardProp: (prop) =>
    prop !== "selected" && prop !== "color" && prop !== "isLast",
}) <{
  selected?: boolean
  color: string
  isLast?: boolean
}>`
  font-size: 18px;
  line-height: 24px;
  color: ${(props) => props.color};

  text-decoration: none;
  padding: 10px 20px;
  p {
    font-weight: ${(props) => (props.selected ? "500" : "400")};
  }
  border-bottom: ${(props) => (props.isLast ? "none" : " 1px dashed #a8acb4")};
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 0px 24px;
    border-bottom: none;
  }
`

const StyledList = styled(List)`
  overflow-y: scroll;
  height: 750px;
`
type Categories = {
  name: string
  details: {
    title: string
    url: string
  }
  subCategories: {
    name: string
    details: {
      title: string
      url: string
    }
    subSubCategories: {
      name: string
      details: {
        title: string
        url: string
      }
    }[]
  }[]
}[]

type Props = {
  href: string
  selected: boolean
  title: string
  color?: string
  onClick?: () => void
  isLast?: boolean
  subCategories?: any[]
  matchingCategory?: Categories[number]
  blank?: boolean
  lube?: boolean
}

interface ActiveSubMenu {
  index: number | null
  anchorEl: HTMLElement | null
}

type SubSub = {
  subSubCategories: {
    name: string
    details: {
      title: string
      url: string
    }
  }[]
  color: string
  title: string
  details: {
    title: string
    url: string
  }
  onClick: (() => void) | undefined
}

function subSubMobile({
  subSubCategories,
  color,
  title,
  details,
  onClick,
}: SubSub) {
  const [isSubOpen, setIsSubOpen] = useState(false)
  const handleMobileSubClick = () => {
    setIsSubOpen((prev) => !prev)
  }
  return (
    <List
      sx={{
        width: "80%",
        bgcolor: "background.paper",
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <StyledMobileButton
        color={color}
        id="basic-button"
        aria-controls={isSubOpen ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isSubOpen ? "true" : undefined}
        onClick={handleMobileSubClick}
      >
        <ListItemText primary={`${details.title}`} />
        {isSubOpen ? (
          <Image
            src="/icons/arrow-down.svg"
            width={24}
            height={24}
            alt=""
          />
        ) : (
          <Image src="/icons/arrow-left.svg" width={24} height={24} alt="" />
        )}
      </StyledMobileButton>
      <Collapse in={isSubOpen} timeout="auto" unmountOnExit>
        <List component="ul" disablePadding>
          {subSubCategories.map((item, index) => {
            const { details, name } = item
            const contact = title === "צור קשר"
            return (
              <StyledMenuItem key={index.toString()} onClick={onClick}>
                <Link
                  href={getNonUmbracoURL(details.url)}
                // target={isBlank ? "_blank" : "_self"}
                >
                  <ListItemButton sx={{ padding: 0, pl: 1.5 }}>
                    <ListItemText primary={details.title} />
                  </ListItemButton>
                </Link>
              </StyledMenuItem>
            )
          })}
        </List>
      </Collapse>
    </List>
  )
}
function NavLink({
  href,
  selected,
  title,
  color = sharedColors.black,
  onClick,
  isLast,
  subCategories,
  matchingCategory,
  blank,
  lube,
}: Props) {
  const pathname = usePathname()
  const router = useRouter()
  const [isOpen, setOpen] = useState(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [activeSub, setActiveSub] = useState<ActiveSubMenu>({
    index: null,
    anchorEl: null,
  })
  const handleSubClick = (
    event: React.MouseEvent<HTMLElement>,
    identifier: number,
  ) => {
    const parent =
      event.currentTarget.parentElement?.parentElement?.parentElement
    const isSameSubClicked = activeSub.index === identifier
    setActiveSub({
      index: isSameSubClicked ? null : identifier,
      anchorEl: isSameSubClicked ? null : parent!,
    })
  }
  const handleSubClose = () => {
    setActiveSub({ index: null, anchorEl: null })
  }
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMobileClick = () => {
    setOpen(!isOpen)
  }

  if (matchingCategory) {
    return (
      <>
        <Desktop>
          <StyledDiv color={color} isLast={isLast}>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <Text variant="MediumText">{title}</Text>
            </Button>
            <StyledMenu
              disableScrollLock={true}
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <StyledMenuItem>
                <Link
                  href={getNonUmbracoURL(matchingCategory.details.url)}
                  target={blank ? "_blank" : "_self"}
                >
                  <MenuItem disableRipple onClick={handleClose}>
                    {matchingCategory.details.title}
                  </MenuItem>
                </Link>
              </StyledMenuItem>
              {matchingCategory.subCategories.map((mid, index) => {
                const hasSub = mid.subSubCategories.length > 0
                return (
                  <div key={index}>
                    <StyledMenuItem key={index.toString()}>
                      {hasSub ? (
                        <MenuItem
                          disableRipple
                          onClick={(e) => handleSubClick(e, index)}
                        >
                          {mid.details.title}
                        </MenuItem>
                      ) : (
                        <Link
                          href={getNonUmbracoURL(mid.details.url)}
                          target={blank ? "_blank" : "_self"}
                        >
                          <MenuItem disableRipple onClick={handleClose}>
                            {mid.details.title}
                          </MenuItem>
                        </Link>
                      )}
                    </StyledMenuItem>
                    {hasSub && (
                      <StyledMenu
                        disableScrollLock={true}
                        id="basic-menu"
                        anchorEl={activeSub.anchorEl}
                        open={activeSub.index === index}
                        onClose={(e) => {
                          handleSubClose()
                        }}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        {mid.subSubCategories.map((bot, index) => {
                          return (
                            <StyledMenuItem key={index.toString()}>
                              <Link
                                href={getNonUmbracoURL(bot.details.url)}
                                target={blank ? "_blank" : "_self"}
                              >
                                <MenuItem
                                  disableRipple
                                  onClick={handleSubClose}
                                >
                                  {bot.details.title}
                                </MenuItem>
                              </Link>
                            </StyledMenuItem>
                          )
                        })}
                      </StyledMenu>
                    )}
                  </div>
                )
              })}
            </StyledMenu>
          </StyledDiv>
        </Desktop>
        <Mobile>
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <StyledMobileButton
              color={color}
              id="basic-button"
              aria-controls={isOpen ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={isOpen ? "true" : undefined}
              onClick={handleMobileClick}
            >
              <ListItemText primary={title} />
              {isOpen ? (
                <Image
                  src="/icons/arrow-down.svg"
                  width={24}
                  height={24}
                  alt="down"
                />
              ) : (
                <Image
                  src="/icons/arrow-left.svg"
                  width={24}
                  height={24}
                  alt=""
                />
              )}
            </StyledMobileButton>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {matchingCategory.subCategories.map((item, index) => {
                  const { details, subSubCategories } = item
                  const subSub = subSubCategories.length > 0
                  const contact = title === "צור קשר"
                  if (subSub) {
                    // return "SubSub"
                    return subSubMobile({
                      color,
                      details,
                      subSubCategories,
                      title,
                      onClick,
                    })
                  } else {
                    return (
                      <StyledMenuItem key={index.toString()} onClick={onClick}>
                        <Link
                          href={
                            contact
                              ? "/#leadform"
                              : getNonUmbracoURL(details.url)
                          }
                        // target={isBlank ? "_blank" : "_self"}
                        >
                          <ListItemButton sx={{ padding: 0, pl: 1.5 }}>
                            <ListItemText primary={details.title} />
                          </ListItemButton>
                        </Link>
                      </StyledMenuItem>
                    )
                  }
                })}
              </List>
            </Collapse>
          </List>
        </Mobile>
      </>
    )
  }


  if (href && href.includes("#") && (pathname === "/" || pathname === "/en"))
    return (
      <StyledHash
        onClick={onClick}
        target={blank ? "_blank" : "_self"}
        href={href}
        color={color}
        isLast={isLast}
      >
        <Mobile>
          <Text variant="LargeText">{title}</Text>
        </Mobile>
        <Desktop>
          <Text variant="MediumText">{title}</Text>
        </Desktop>
      </StyledHash>
    )
  if (subCategories?.length) {
    return (
      <>
        <MobileLi>
          <List
            sx={{ width: "100%", bgcolor: "background.paper", padding: 0 }}
            component="div"
            aria-labelledby="nested-list-subheader"
          >
            <StyledMobileButton
              color={color}
              id="basic-button"
              aria-controls={isOpen ? "basic-menu" : undefined}
              aria-expanded={isOpen ? "true" : false}
              onClick={handleMobileClick}
            >
              <ListItemText primary={title} style={{ margin: 0 }} />
              {isOpen ? (
                <Image
                  src="/icons/arrow-down.svg"
                  width={24}
                  height={24}
                  alt=""
                />
              ) : (
                <Image
                  src="/icons/arrow-left.svg"
                  width={24}
                  height={24}
                  alt=""
                />
              )}
            </StyledMobileButton>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <List component="ul" disablePadding>
                {subCategories.map((item, index) => {
                  const {
                    title,
                    url,
                    blank: blankChild,
                  } = extractMenuProperties(item)
                  const contact = title === "צור קשר"
                  const isBlank = !contact && blankChild
                  return (
                    <li key={index}>
                      <StyledMenuItem>
                        <Link href={getNonUmbracoURL(url)} passHref legacyBehavior>
                          <ListItemButton onClick={() => {
                            onClick && onClick()
                          }} component="a" role="link" sx={{ padding: 0, pl: 1.5 }} >
                            <ListItemText primary={title} />
                          </ListItemButton>
                        </Link>
                      </StyledMenuItem>

                    </li>
                  )
                })}
              </List>
            </Collapse>
          </List>
        </MobileLi>
        <DesktopLi>
          <StyledDiv color={color} isLast={isLast}>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <Mobile>
                <Text variant="LargeText">{title}</Text>
              </Mobile>
              <Desktop>
                <Text variant="MediumText">{title}</Text>
              </Desktop>
            </Button>
            <StyledMenu
              disableScrollLock={true}
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {subCategories.map((item, index) => {
                const {
                  title,
                  url,
                  blank: blankChild,
                } = extractMenuProperties(item)
                const contact = title === "צור קשר"
                const isBlank = !contact && blankChild
                return (
                  <StyledMenuItem key={index.toString()}>
                    <Link
                      href={getNonUmbracoURL(url)}
                      target={isBlank ? "_blank" : "_self"}
                    >
                      <MenuItem disableRipple onClick={handleClose}>
                        {title}
                      </MenuItem>
                    </Link>
                  </StyledMenuItem>
                )
              })}
            </StyledMenu>
          </StyledDiv>
        </DesktopLi>
      </>
    )
  }
  return (
    <StyledLink
      onClick={onClick}
      selected={selected}
      color={color}
      isLast={isLast}
      paddingLeft={20}
    >
      <Link
        target={blank ? "_blank" : "_self"}
        href={href}
        style={{ textDecoration: "none" }}
      >
        <Mobile>
          <Text variant="LargeText">{title}</Text>
        </Mobile>
        <Desktop>
          <Text variant="MediumText">{title}</Text>
        </Desktop>
      </Link>
    </StyledLink>
  )
}

export default NavLink
