import { SVGProps } from "react"
const HomeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#0050C9"
      strokeLinecap="round"
      d="M7.778 16.667v-5.072c0-.467.398-.846.889-.846h2.666c.491 0 .89.379.89.846v5.072M9.484 3.49 3.707 7.397a.833.833 0 0 0-.374.689v7.313c0 .7.597 1.268 1.334 1.268h10.666c.737 0 1.334-.568 1.334-1.268V8.086c0-.274-.14-.53-.374-.689L10.515 3.49a.926.926 0 0 0-1.03 0Z"
    />
  </svg>
)
export default HomeIcon
