import { SVGProps } from "react"
const CompanyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 28}
    height={props.height || 28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26.6875 14.6045V23.0628C26.6875 24.0253 25.9 24.8128 24.9375 24.8128H3.0625C2.1 24.8128 1.3125 24.0253 1.3125 23.0628V14.6045"
      stroke="#5C6474"
      stroke-width="1.4"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.1875 3.8125C9.1875 2.36263 10.3635 1.1875 11.8125 1.1875H16.1875C17.6365 1.1875 18.8125 2.36263 18.8125 3.8125"
      stroke="#5C6474"
      stroke-width="1.4"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.6667 14.6042H7.72917C2.89742 14.6042 1.3125 13.0201 1.3125 8.1875V5.5625"
      stroke="#5C6474"
      stroke-width="1.4"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.6875 5.5625H26.6875V8.1875C26.6875 13.0201 25.1026 14.6042 20.2708 14.6042H15.3125"
      stroke="#5C6474"
      stroke-width="1.4"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.166 12.5625H11.666V17.2292H15.166V12.5625Z"
      stroke="#5C6474"
      stroke-width="1.4"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.84999 5.5625H3.5"
      stroke="#5C6474"
      stroke-width="1.4"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
export default CompanyIcon
