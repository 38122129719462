import * as React from "react"
const IconFile = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <path
      fill="#0050C9"
      d="M1.976 20.603c-.38 0-.664-.095-.949-.19C-.016 19.84-.11 18.984.08 18.318c.38-1.143 2.466-2.572 5.217-3.81 1.233-2.286 2.182-4.667 2.751-6.667C7.1 6.031 6.624 4.318 6.624 3.08c0-.666.19-1.238.475-1.714.379-.476.948-.762 1.707-.762.854 0 1.518.476 1.803 1.334.474 1.142.19 3.238-.475 5.619.949 1.619 2.087 3.142 3.32 4.285 1.803-.38 3.415-.571 4.459-.38 1.802.285 2.087 1.523 2.087 2 0 2-2.087 2-2.846 2-1.423 0-2.846-.572-4.08-1.62-2.276.572-4.553 1.334-6.355 2.19-.949 1.62-1.897 2.953-2.751 3.715-.854.667-1.518.857-1.992.857Zm1.138-2.762c-.474.286-.854.572-1.043.857.19-.095.569-.285 1.043-.857Zm12.902-4.476c.38.095.759.19 1.138.19.57 0 .854-.095.949-.095-.095-.095-.76-.285-2.087-.095Zm-6.64-3.428c-.38 1.143-.95 2.38-1.424 3.523 1.139-.38 2.277-.762 3.415-1.047a16.263 16.263 0 0 1-1.992-2.476Zm-.57-7.429h-.095c-.095.095-.19.762.19 2.19.095-1.142.095-2-.095-2.19Z"
    />
  </svg>
)
export default IconFile
