declare global {
  interface Global {
    redirectsCache: {
      lastFetch: number
      data: Redirect[] | null
    }
  }
}

;(global as Global & typeof globalThis).redirectsCache = (
  global as Global & typeof globalThis
).redirectsCache || {
  lastFetch: 0,
  data: null,
}
export type Redirect = {
  source: string
  destination: string
}

export async function getCachedRedirects(
  graphQlPath: string
): Promise<Redirect[] | null> {
  const oneDayInMillis = 24 * 60 * 60 * 1000 // 24 hours in milliseconds
  const now = Date.now()
  const globalObject = global as Global & typeof globalThis
  // Check if the cache is older than a day or empty
  if (
    !globalObject.redirectsCache.data ||
    now - globalObject.redirectsCache.lastFetch > oneDayInMillis
  ) {
    console.log("Fetching new redirects...")
    // Placeholder for your future GraphQL request
    // Simulate fetch with static data for now
    const newData: Redirect[] = [
      { source: "/old-path", destination: "/new-path" },
      // Add more redirects as needed
    ]
    globalObject.redirectsCache = {
      lastFetch: now,
      data: newData,
    }
  } else {
    console.log("Using cached redirects...")
  }

  return globalObject.redirectsCache.data
}

export {}
