import styled from "@emotion/styled"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { sharedColors } from "../theme/colors"

const StyledTableCell = styled(TableCell)`
  min-width: 100px;
  width: 200px;

  font-weight: 400;
  color: ${sharedColors.darkGray};
  border-bottom: 1px solid ${sharedColors.bluishGray};
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 12px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    font-size: 16px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    padding-left: 0px;
    padding-right: 0px;
    font-size: 14px;
    text-align: center;
  }
`

const StyledTableRow = styled(TableRow)`
  td,
  th {
    font-weight: 400;
    ${(props) => props.theme.breakpoints.down("sm")} {
      font-size: 14px;
      padding-left: 0px;
    }
    ${(props) => props.theme.breakpoints.up("md")} {
      font-size: 12px;
    }
    ${(props) => props.theme.breakpoints.up("lg")} {
      font-size: 16px;
    }
  }
  th {
    border-bottom: 1px solid ${sharedColors.bluishGray};
    ${(props) => props.theme.breakpoints.down("sm")} {
      text-align: center;
    }
  }
`

const tableHeader = ["כתובת התחנה", "שם התחנה", "מספר התחנה"]

const tableRow = [
  { key1: "אילת 35, תל אביב-יפו", key2: "נווה צדק", key3: "107" },
  { key1: "אילת 35, תל אביב-יפו", key2: "נווה צדק", key3: "107" },
  { key1: "אילת 35, תל אביב-יפו", key2: "נווה צדק", key3: "107" },
  { key1: "אילת 35, תל אביב-יפו", key2: "נווה צדק", key3: "107" },
  { key1: "אילת 35, תל אביב-יפו", key2: "נווה צדק", key3: "107" },
  { key1: "אילת 35, תל אביב-יפו", key2: "נווה צדק", key3: "107" },
]

export const CommonTable = () => {
  return (
    <TableContainer>
      <Table aria-label="simple table" sx={{ width: "100%" }}>
        <TableHead>
          <TableRow>
            {tableHeader.map((item, index) => (
              <StyledTableCell key={index}>{item}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRow.map((row, index) => (
            <StyledTableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {Object.values(row).map((item, key) => (
                <TableCell key={key.toString()} component="th" scope="row">
                  {item}
                </TableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CommonTable
