import { Box, Theme, useMediaQuery } from "@mui/material";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { sharedTheme } from "../../theme/theme";

export const useZoomDetection = (thresholdZoom: number = 2) => {
  const [zoomLevel, setZoomLevel] = useState<number>(1);
  const [isZoomedBeyondThreshold, setIsZoomedBeyondThreshold] = useState<boolean>(false);

  useEffect(() => {
    const detectZoom = (): void => {
      const currentZoomLevel = window.devicePixelRatio || 1;
      setZoomLevel(currentZoomLevel);

      if (currentZoomLevel >= thresholdZoom) {
        setIsZoomedBeyondThreshold(true);
      } else {
        setIsZoomedBeyondThreshold(false);
      }
    };

    // Add event listener for resize to detect zoom changes
    window.addEventListener("resize", detectZoom);

    // Initial check
    detectZoom();

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", detectZoom);
    };
  }, [thresholdZoom]);

  return { zoomLevel, isZoomedBeyondThreshold };
};
export const useIsXSmall = () =>
  useMediaQuery<Theme>(sharedTheme.breakpoints.up("xs"));

export const useIsMobile = () =>
  useMediaQuery<Theme>(sharedTheme.breakpoints.up("sm"))

export const useIsDesktop = () =>
  useMediaQuery<Theme>(sharedTheme.breakpoints.up("md"))

export const useIsLargeDesktop = () =>
  useMediaQuery<Theme>(sharedTheme.breakpoints.up("lg"))

export const useIsXLargeDesktop = () =>
  useMediaQuery<Theme>(sharedTheme.breakpoints.up("xl"))

export const useIsXXLargeDesktop = () =>
  useMediaQuery<Theme>((theme) => `(min-width:1900px)`)

// Define the props for the component
interface MediaQueryComponentProps {
  aboveTheFold?: boolean
  children: React.ReactNode
}
export const XSmall: FC<MediaQueryComponentProps> = ({ children }) => (
  <Box sx={{ display: { xs: "inherit", sm: "none" } }}>{children}</Box>
)
export const SmallDesktop: FC<MediaQueryComponentProps> = ({ children }) => (
  <Box sx={{ display: { xs: "none", sm: "inherit" } }}>{children}</Box>
)

export const Mobile: FC<MediaQueryComponentProps> = ({ children }) => (
  <Box sx={{ display: { xs: "inherit", md: "none" } }}>{children}</Box>
)

export const Desktop: FC<MediaQueryComponentProps> = ({ children }) => (
  <Box sx={{ display: { xs: "none", md: "inherit" } }}>{children}</Box>
)
export const MediumDesktop: FC<MediaQueryComponentProps> = ({ children }) => (
  <Box sx={{ display: { xs: "none", md: "inherit", lg: "none" } }}>{children}</Box>
)
export const LargeDesktop: FC<MediaQueryComponentProps> = ({ children }) => (
  <Box sx={{ display: { xs: "none", md: "none", lg: "inherit" } }}>{children}</Box>
)

export const IEMediaQuery =
  "@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)"

export const EdgeMediaQuery = "@supports (-ms-ime-align:auto)"
