
function ScrollUpIcon() {
    return (
        <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="44" height="44" rx="22" fill="white" />
            <rect x="1" y="1" width="44" height="44" rx="22" stroke="#0050C9" strokeWidth="2" />
            <path d="M30 26L23 19L16 26" stroke="#0050C9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
}

export default ScrollUpIcon;
