"use client"
import styled from "@emotion/styled"
import { Button } from "@mui/material"
import Image from "next/image"
import Link from "next/link"
import { useState } from "react"

import "swiper/css"
import { Navigation } from "swiper/modules"

import { Swiper, SwiperSlide } from "swiper/react"

import React from "react"
import { extractProperty } from "utils"
import { getNonUmbracoURL, httpsImage } from "utils/utils"
import ImageContainer from "./ImageContainer"
import { NewText } from "./NewText/NewText"
import { Desktop, Mobile } from "./common/bit/MediaQueries"
import {
  LeftArrow,
  LeftMobileArrow,
  ReadMoreIcon,
  RightArrow,
  RightMobileArrow,
} from "./icons"
import { sharedColors } from "./theme/colors"
const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${(props) => props.theme.breakpoints.up("md")} {
    grid-template-columns: 1fr 1fr;
    /* gap: 80px; */
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    /* gap: 120px; */
  }
`

const StyledImageContainer = styled.div<{
  withRightImage: boolean
}>`
  width: 100%;
  height: 375px;
  position: relative;

  ${(props) => props.theme.breakpoints.up("md")} {
    height: 640px;
    order: ${(props) => (props.withRightImage ? "0" : "1")};
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    height: 900px;
  }
`

const StyledTextContainer = styled.div<{
  withRightImage: boolean
  $justifyContent: string
}>`
  padding: 28px;
  display: flex;
  flex-direction: column;
  position: relative;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 0px;
    height: 640px;
    justify-content: center;
    padding-left: ${(props) => (props.withRightImage ? "80px" : "146px")};
    padding-right: ${(props) => (props.withRightImage ? "146px" : "80px")};
  }
  ${(props) => props.theme.breakpoints.up("xl")} {
    padding-top: 160px;
    padding-left: ${(props) => (props.withRightImage ? "120px" : "220px")};
    padding-right: ${(props) => (props.withRightImage ? "220px" : "120px")};
  }
`

const StyledCTA = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${(props) => sharedColors.blue};
  text-decoration: none;
  margin-top: 20px;
  font-size: 16px;
  line-height: 20px;
  direction: ltr; /* Left-to-Right */
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 18px;
    line-height: 25.2px;
    margin-top: 16px;
  }
`
const StyledSlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 32px;
  padding-right: 32px;
  p {
    margin: 0px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 0px;
  }
`
const StyledArrowsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 0px;
  z-index: 9;
  right: 0px;
  top: 14px;
  ${(props) => props.theme.breakpoints.up("md")} {
    position: relative;
    top: 0px;
  }
`

const StyledArrowTextContainer = styled.div`
  width: 22px;
  text-align: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 48px;
  }
`

const StyledArrowContainer = styled(Button)`
  padding: 0px;
  min-width: auto;
`

const StyledSlideIconContainer = styled.div`
  margin-bottom: 18px;
  width: 100%;
`

const StyledTagsContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`

const StyledTag = styled(Link)`
  height: 30px;
  border: 1px solid ${sharedColors.blue};
  border-radius: 20px;
  padding: 3px 10px;
  text-decoration: none;

  ${(props) => props.theme.breakpoints.up("md")} {
    border: 2px solid ${sharedColors.blue};

    padding: 8px 20px;
    height: 40px;
  }
`
const Bidi = styled.div`
  unicode-bidi: embed;
  direction: rtl;
`
type Props = {
  data: any
  withCover?: boolean
  keyForComponent: number
  justifyContent?: string
  subWeight?: number
}

export const BrandsValue: React.FC<Props> = ({
  data,
  withCover,
  keyForComponent,
  justifyContent = "space-between",
  subWeight,
}) => {
  const innerSliderBlocks = extractProperty(data, "innerSliderImageNextToText")
  const withRightImage = extractProperty(data, "flipSide")

  const items = innerSliderBlocks.map((block: any) => {
    const image = extractProperty(block, "imgText")
    const logo = extractProperty(block, "logo")
    const slidTitle = extractProperty(block, "slidTitle")
    const slidContent = extractProperty(block, "slidContent")
    const slidfUrl = extractProperty(block, "slidfUrl")
    const slidLink = extractProperty(block, "slidLink")
    const imageAlt = extractProperty(block, "altTextForImage")
    return {
      bigImage: {
        url: image,
        alt: imageAlt,
      },
      icon: {
        url: logo,
        alt: slidTitle,
      },
      title: slidTitle,
      subtitle: slidContent,
      cta: {
        url: slidfUrl?.[0]?.url,
        text: slidfUrl?.[0]?.name,
        target: slidfUrl?.[0]?.target,
      },
      tags: slidLink,
    }
  })
  const [activeIndex, setActiveIndex] = useState(0)
  return (
    <Swiper
      navigation={{
        nextEl: `.arrow-right-${keyForComponent}-1`,
        prevEl: `.arrow-left-${keyForComponent}-1`,
      }}
      style={{ padding: 0 }}
      key={keyForComponent + "slider"}
      modules={[Navigation]}
      slidesPerView={1}
      onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
      onSwiper={() => { }}
    >
      {items.map((item: any, index: number) => {
        console.log(item, 'wefwefwef')
        return (
          <SwiperSlide key={index.toString()}>
            <StyledGrid>
              <StyledImageContainer withRightImage={!withRightImage}>
                <Image
                  style={{ objectFit: withCover ? "cover" : "contain" }}
                  src={httpsImage(item?.bigImage?.url)}
                  alt=""
                  fill
                />
              </StyledImageContainer>
              <StyledTextContainer
                withRightImage={!withRightImage}
                $justifyContent={justifyContent}
              >
                <StyledSlideContainer>
                  {item.icon.url && (
                    <StyledSlideIconContainer>
                      <ImageContainer
                        desktopUrl={item.icon.url}
                        height={48}
                        mediumHeight={32}
                        mobileHeight={28}
                        objectPosition="right"
                        contain
                        sm
                        alt={item?.cta?.text?.replace('ל ', '').replace('-', '')}
                      />
                    </StyledSlideIconContainer>
                  )}
                  <NewText
                    largeDesktopFontSize={48}
                    largeDesktopLineHeight="67px"
                    desktopFontSize={32}
                    desktopLineHeight={"44.8px"}
                    mobileFontSize={28}
                    mobileLineHeight={"36px"}
                    desktopFontWeight={500}
                    component="h2"
                    color={sharedColors.black}
                    dangerouslySetInnerHTML={{ __html: item.title }} // For rich text rendering
                  />
                  <NewText
                    largeDesktopFontSize={24}
                    largeDesktopLineHeight="33.6px"
                    desktopFontSize={16}
                    desktopFontWeight={subWeight || 400}
                    desktopLineHeight={"22.4px"}
                    mobileFontSize={18}
                    mobileLineHeight={"25px"}
                    component="p"
                    color={sharedColors.lighterBlack}
                    dangerouslySetInnerHTML={{ __html: item.subtitle }} // For rich text rendering
                  />
                  <StyledTagsContainer>
                    {item.tags.map((tag: any, index: number) => {
                      const link = extractProperty(tag, "link")
                      const linkTitle = extractProperty(tag, "linkTitle")

                      return (
                        <StyledTag
                          href={getNonUmbracoURL(link?.[0]?.url) || "#"}
                          key={index.toString()}
                        >
                          <NewText
                            component="p"
                            desktopFontSize={16}
                            desktopLineHeight="24px"
                            mobileLineHeight="24px"
                            mobileFontSize={14}
                            desktopFontWeight="400"
                            color={sharedColors.blue}
                          >
                            {linkTitle}
                          </NewText>
                        </StyledTag>
                      )
                    })}
                  </StyledTagsContainer>
                  {!!item.cta.url && (
                    <StyledCTA
                      target={item.cta.target}
                      href={getNonUmbracoURL(item.cta.url) || "/"}
                    >
                      {item.cta.text}
                      <ReadMoreIcon />
                    </StyledCTA>
                  )}
                </StyledSlideContainer>
                {items.length > 1 && (
                  <StyledArrowsContainer>
                    <StyledArrowContainer
                      className={`arrow-left-${keyForComponent}-1 arrow`}
                    >
                      <Desktop>
                        <RightArrow />
                      </Desktop>
                      <Mobile>
                        <RightMobileArrow />
                      </Mobile>
                    </StyledArrowContainer>
                    <StyledArrowTextContainer>
                      <Desktop>
                        <NewText
                          desktopFontSize={16}
                          component="p"
                          desktopLineHeight="150%"
                          desktopFontWeight="400"
                          color={sharedColors.darkGray}
                        >
                          {activeIndex + 1}/{items.length}
                        </NewText>
                      </Desktop>
                      <Mobile>
                        <NewText
                          desktopFontSize={14}
                          component="p"
                          desktopLineHeight="20px"
                          desktopFontWeight="400"
                          color={sharedColors.darkGray}
                        >
                          {activeIndex + 1}/{items.length}
                        </NewText>
                      </Mobile>
                    </StyledArrowTextContainer>
                    <StyledArrowContainer
                      className={`arrow-right-${keyForComponent}-1 arrow`}
                    >
                      <Desktop>
                        <LeftArrow />
                      </Desktop>
                      <Mobile>
                        <LeftMobileArrow />
                      </Mobile>
                    </StyledArrowContainer>
                  </StyledArrowsContainer>
                )}
              </StyledTextContainer>
            </StyledGrid>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

export default BrandsValue
