import { SVGProps } from "react"

interface LineVectorProps extends SVGProps<SVGSVGElement> {
  width?: number // Optional width prop
  color?: string
}

const LineVector = ({
  width = 129,
  color = "#000",
  ...props
}: LineVectorProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width} // Use the width prop here
    height={props.height || 4}
    fill="none"
    {...props}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={4}
      d="M2 2h125"
    />
  </svg>
)

export default LineVector
