import * as React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  checked: boolean
}
const FilterCheckbox = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <rect width={23} height={23} x={0.5} y={0.5} stroke="#000" rx={1.5} />
    {props.checked && (
      <path
        stroke="#0050C9"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m18 7.5-8.95 9L6 13.432"
      />
    )}
  </svg>
)
export default FilterCheckbox
