"use client"
import styled from "@emotion/styled"
import { Button, Tab, Tabs } from "@mui/material"
import Image from "next/image"
import Link from "next/link"
import { useState } from "react"

import "swiper/css"
import "swiper/css/effect-coverflow"
import "swiper/css/effect-fade"
import "swiper/css/navigation"
import "swiper/css/scrollbar"
import {
  EffectCoverflow,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import { getNonUmbracoURL, httpsImage } from "utils/utils"
import Container from "./Container"
import { NewText } from "./NewText/NewText"
import { Desktop, Mobile } from "./common/bit/MediaQueries"
import { NextArrow, ReadMoreIcon } from "./icons"
import { sharedColors } from "./theme/colors"

interface PropertyValue {
  value?: string
  richText?: string
  blocks?: any[] // Adjust the type as per your data structure if needed
  mediaItems?: { url: string }[]
  links?: []
  dateTime?: string
}

interface DataProperty {
  propertyName: string | null
  propertyValue: PropertyValue | null
}

interface Data {
  properties?: DataProperty[]
}

export function extractProperty(data: Data | any, propertyName: string): any {
  if (!data || !propertyName) {
    return ""
  }

  const property = data.properties?.find(
    (prop: DataProperty) => prop.propertyName === propertyName,
  )

  if (!property?.propertyValue) {
    return ""
  }
  // Attempt extraction in the given order
  return (
    property.propertyValue?.value ||
    property.propertyValue?.richText ||
    property.propertyValue?.mediaItems?.[0]?.url ||
    property.propertyValue.blocks || // Assuming blocks is an array you might want to convert to string
    property.propertyValue.links ||
    property.propertyValue.dateTime ||
    ""
  )
}
const StyledSwiper = styled(Swiper)``

const StyledMobileSwiper = styled(Swiper)`
  display: flex;
  flex-direction: column-reverse;
  padding-left: 28px !important;
  .swiper-scrollbar {
    position: static;
    margin-bottom: 42px;
    background-color: white;
    height: 1px;
  }
  .swiper-scrollbar-drag {
    height: 4px;
    background-color: white;
    width: 50px;
  }
`

const StyledBackground = styled.div<{ $background: string }>`
  background: ${(props) => props.$background};
  padding: 0px;
  padding-top: 36px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 60px;
  }
`

const StyledItemContainer = styled(Link, {
  shouldForwardProp: (prop) =>
    !["transformLeft", "transformRight", "paddingTop"].includes(prop),
})<{
  transformLeft?: boolean
  transformRight?: boolean
  paddingTop?: number
}>`
  height: 320px;
  width: 260px;
  background: ${(props) => props.theme.colors.white};
  transition: 200ms all ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  ${(props) => props.paddingTop && `padding-top: ${props.paddingTop}px;`}
  box-sizing: border-box;
  text-align: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 300px;
    width: 100%;
    box-shadow: none;
    transform-origin: ${(props) =>
      props.transformLeft
        ? "left center"
        : props.transformRight
          ? "right center"
          : "center"};
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    width: 100%;
  }
  :hover {
    transform: scale(1.065);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
  }
`

const StyledIcon = styled.div<{
  $height: string
  $margin: string
  $width?: string
}>`
  height: ${(p) => p.$height};
  ${(p) =>
    p.$width
      ? `
  width:${p.$width}`
      : null};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: ${(p) => p.$margin};
`

const StyledTabs = styled(Tabs)`
  margin-left: 20px;
  border-top: 1px solid #e5edfa;
  .MuiTab-root {
    border-top: 4px solid transparent;
    padding-top: 0;
    color: ${(props) => props.theme.colors.white};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.2px;
  }
  .Mui-selected {
    border-top: 4px solid white;
    color: ${(props) => props.theme.colors.white} !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.2px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-left: 0px;
  }
`

const StyledDescription = styled("p")`
  text-align: center;
  min-height: 50px;
`
const MobileLink = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 28px;
  padding-bottom: 37px;
  padding-right: 20px;
`
const StyledCTA = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${(props) => props.theme.colors.blue};
  text-decoration: none;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  min-height: 74px;
  padding: 0 6px;

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
  }
`

const StyledTitleContainer = styled.div`
  margin-left: 20px;
  margin-bottom: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-bottom: 40px;
    margin-left: 0px;
  }
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const StyledHorizontalLine = styled.div<{
  $width?: number | undefined
  $height?: number | undefined
}>`
  border-top: ${(props) => props?.$height || "1"}px solid white;
  width: ${(props) => `${props?.$width}px` || "100%"};
`

const StyledTab = styled(Tab)`
  padding-right: 20vw;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-right: 10vw;
  }
  &.MuiTab-root {
    ${(props) => props.theme.breakpoints.up("md")} {
      line-height: 60px;
    }
  }
`

const StyledRelative = styled.div`
  position: relative;
`
const StyledNext = styled(Button)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: -50px;
  ${(props) => props.theme.breakpoints.up("lg")} {
    right: -50px;
  }
`

const StyledPrev = styled(Button)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%) rotate(180deg);
  left: -50px;
  ${(props) => props.theme.breakpoints.up("lg")} {
    left: -50px;
  }
`

const StyledPazBrandsTitle = styled.div`
  padding-bottom: 28px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-bottom: 40px;
  }
`

const StyledLink = styled(Link, {
  shouldForwardProp: (prop) => !["$ctaFontWeight"].includes(prop),
})<{ $ctaFontWeight?: number | undefined }>`
  ${(props) => props?.$ctaFontWeight && `font-weight: ${props.$ctaFontWeight};`}
  background: white;
  text-decoration: none;
  display: flex;
  border-radius: 28px;
  height: 52px;
  align-items: center;
  padding: 9.5px 15px 9.5px 15px;
  svg {
    margin-left: 10px;
  }
`

interface ItemType {
  icon: string
  text: string
  url: string
  description?: string
}

interface TabType {
  tabLabel: string
  items: ItemType[]
}

function convertToTabsData(data: any) {
  // Validate the input structure
  if (
    data.__typename !== "BasicBlockListItem" ||
    data.blockType !== "linksLists"
  ) {
    throw new Error("Invalid input data structure.")
  }

  // Extract lists

  const lists = extractProperty(data, "lists")

  // Convert lists into tabsData format
  const tabsData: TabType[] = lists.map((list: any) => {
    const tabLabel = extractProperty(list, "title")
    const items = extractProperty(list, "urls")
    const convertedItems: ItemType[] = items.map((item: any) => {
      const url = extractProperty(item, "link")?.[0]?.url
      const text = extractProperty(item, "linkTitle")
      const iconUrl = extractProperty(item, "linkImage")
      const description = extractProperty(item, "description")
      return {
        icon: iconUrl,
        text: text,
        url,
        description,
      }
    })

    return {
      tabLabel: tabLabel,
      items: convertedItems,
    }
  })

  return tabsData
}

const SLIDES_DESKTOP = 6

export type BrandsProps = {
  data: any
  background?: string
  mainTitle?: string
  objectFit: any
  height?: string
  width?: string
  margin?: string
  keyForComponent?: any
  enabledScrollbar?: boolean
  paddingTop?: number
  mWidth?: string
  mHeight?: string
  ctaFontWeight?: number
  trackHandler?: (cat: string, action: string) => void
}

export const Brands: React.FC<BrandsProps> = ({
  data,
  background = sharedColors.blue,
  mainTitle,
  objectFit,
  height = "100%",
  margin = "0px",
  keyForComponent,
  enabledScrollbar,
  width,
  paddingTop,
  mHeight,
  mWidth,
  ctaFontWeight,
  trackHandler,
}) => {
  const tabsData = convertToTabsData(data)
  const [value, setValue] = useState(0)
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  const [active, setActive] = useState<number | null>(0)
  const linkToAll = extractProperty(data, "linkToAll")[0]
  const lastSlide = tabsData[value].items.length - 1
  const allClickHandler = () => {
    trackHandler && trackHandler(mainTitle || "", linkToAll.name || "")
  }
  const oneClickHandler = (label?: string) => {
    trackHandler && trackHandler(mainTitle || "", label || "")
  }
  return (
    <StyledBackground $background={background}>
      {linkToAll && (
        <Container>
          <StyledTitleContainer>
            <NewText
              desktopFontSize={32}
              component="h1"
              desktopLineHeight="150%"
              desktopFontWeight="500"
              color={sharedColors.white}
            >
              {mainTitle}
            </NewText>
            <Desktop>
              {linkToAll && (
                <StyledLink
                  href={getNonUmbracoURL(linkToAll.url)}
                  onClick={allClickHandler}
                >
                  <NewText
                    component={"p"}
                    desktopFontSize={18}
                    desktopLineHeight={"24px"}
                    color={sharedColors.blue}
                    desktopFontWeight={ctaFontWeight}
                    mobileFontWeight={500}
                  >
                    {linkToAll.name}
                  </NewText>
                  <ReadMoreIcon />
                </StyledLink>
              )}
            </Desktop>
          </StyledTitleContainer>
          <Desktop>
            <StyledHorizontalLine>
              <StyledHorizontalLine $width={300} $height={4} />
            </StyledHorizontalLine>
          </Desktop>
        </Container>
      )}
      {tabsData.length > 1 ? (
        <>
          <Desktop>
            <Container>
              <StyledTabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                {tabsData.map((tab: TabType, index: number) => (
                  <StyledTab label={tab.tabLabel} key={index} />
                ))}
              </StyledTabs>
            </Container>
          </Desktop>
          <Mobile>
            <StyledTitleContainer>
              <NewText
                desktopFontSize={32}
                component="h1"
                desktopLineHeight="150%"
                desktopFontWeight="500"
                color={sharedColors.white}
              >
                {mainTitle}
              </NewText>
            </StyledTitleContainer>
            <StyledTabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {tabsData.map((tab: TabType, index: number) => (
                <StyledTab
                  label={
                    <NewText
                      desktopFontSize={18}
                      component="h1"
                      desktopLineHeight="60px"
                      desktopFontWeight="500"
                      color={sharedColors.white}
                    >
                      {tab.tabLabel}
                    </NewText>
                  }
                  key={index}
                />
              ))}
            </StyledTabs>
          </Mobile>
        </>
      ) : tabsData.length === 1 ? (
        <Container>
          <StyledPazBrandsTitle>
            <NewText
              desktopFontSize={48}
              component="h1"
              desktopLineHeight="48px"
              desktopFontWeight="500"
              color={sharedColors.black}
            >
              {tabsData?.[0]?.tabLabel}
            </NewText>
          </StyledPazBrandsTitle>
        </Container>
      ) : null}
      <Desktop>
        <StyledRelative>
          <StyledNext className={`arrow-left-swiper-${keyForComponent} arrow`}>
            <NextArrow />
          </StyledNext>
          <StyledPrev className={`arrow-right-swiper-${keyForComponent} arrow`}>
            <NextArrow />
          </StyledPrev>
          <Container>
            <StyledSwiper
              navigation={{
                nextEl: `.arrow-left-swiper-${keyForComponent}`,
                prevEl: `.arrow-right-swiper-${keyForComponent}`,
              }}
              pagination={{ type: "progressbar" }}
              key={value}
              style={{ padding: 0 }}
              spaceBetween={1}
              modules={[Navigation, Scrollbar, Pagination]}
              slidesPerView={SLIDES_DESKTOP}
              breakpoints={{
                // when window width is >= 320px
                320: {
                  slidesPerView: 4.5,
                },
                768: {
                  slidesPerView: 4,
                },

                // when window width is >= 640px
                1366: {
                  slidesPerView: 6,
                },
              }}
              onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
            >
              {tabsData[value].items.map((item: ItemType, index: number) => {
                const descriptionHTML = item.description || ""
                return (
                  <SwiperSlide
                    key={index.toString()}
                    onMouseOver={() => setActive(index)}
                    onMouseLeave={() => setActive(null)}
                    style={{ zIndex: active === index ? 9 : 1 }}
                  >
                    <StyledItemContainer
                      paddingTop={paddingTop}
                      href={getNonUmbracoURL(item.url)}
                      onClick={() => oneClickHandler(item.text)}
                      transformLeft={activeIndex === index}
                      transformRight={
                        index === activeIndex + SLIDES_DESKTOP - 1
                      }
                    >
                      <StyledIcon
                        $height={height}
                        $margin={margin}
                        $width={width}
                      >
                        <Image
                          style={{
                            objectFit: objectFit,
                            // transform: "translateY(40px)",
                          }}
                          loading="eager"
                          src={httpsImage(item.icon)}
                          fill
                          alt="s"
                        />
                      </StyledIcon>
                      <NewText
                        component={"p"}
                        largeDesktopFontSize={16}
                        largeDesktopLineHeight={"24px"}
                        desktopFontSize={12}
                        desktopLineHeight={"18px"}
                        color="black"
                        style={{ opacity: 0.6 }}
                        dangerouslySetInnerHTML={{ __html: descriptionHTML }}
                      />
                      <StyledCTA>
                        {item.text}

                        <ReadMoreIcon />
                      </StyledCTA>
                    </StyledItemContainer>
                  </SwiperSlide>
                )
              })}
            </StyledSwiper>
          </Container>
        </StyledRelative>
      </Desktop>
      <Mobile>
        <StyledMobileSwiper
          style={{ padding: 0 }}
          key={2}
          // effect={"coverflow"}
          grabCursor={true}
          slidesPerView={"auto"}
          initialSlide={0}
          // coverflowEffect={{
          //   rotate: 0,
          //   stretch: 0,
          //   depth: 100,
          //   modifier: 1.065,
          //   slideShadows: false,
          // }}
          scrollbar={{ draggable: true, dragSize: 300 }}
          pagination={true}
          width={260}
          spaceBetween={1}
          modules={[EffectCoverflow, Scrollbar]}
          className="mySwiper"
        >
          {tabsData[value].items.map((item: ItemType, index: number) => {
            const descriptionHTML = item.description || ""
            return (
              <SwiperSlide
                key={index.toString()}
                onMouseOver={() => {
                  setActive(index)
                }}
                onMouseLeave={() => {
                  setActive(null)
                }}
                style={{ zIndex: active === index ? 9 : 1, width: "260px" }}
              >
                <StyledItemContainer
                  href={getNonUmbracoURL(item.url)}
                  paddingTop={paddingTop}
                >
                  <StyledIcon
                    $height={mHeight || height}
                    $margin={margin}
                    $width={mWidth || width}
                  >
                    <Image
                      style={{ objectFit: "fill" }}
                      src={httpsImage(item.icon)}
                      fill
                      alt="s"
                    />
                  </StyledIcon>
                  <NewText
                    component={"p"}
                    desktopFontSize={24}
                    mobileFontSize={16}
                    mobileLineHeight={"24px"}
                    color="black"
                    style={{ opacity: 0.6 }}
                    dangerouslySetInnerHTML={{ __html: descriptionHTML }}
                  />
                  <StyledCTA>
                    {item.text}
                    <ReadMoreIcon />
                  </StyledCTA>
                </StyledItemContainer>
              </SwiperSlide>
            )
          })}
        </StyledMobileSwiper>
        <MobileLink>
          {linkToAll && (
            <StyledLink href={getNonUmbracoURL(linkToAll.url)}>
              <NewText
                component={"p"}
                desktopFontSize={18}
                desktopLineHeight={"24px"}
                mobileFontSize={19}
                mobileLineHeight={"27.14px"}
                color={sharedColors.blue}
              >
                {linkToAll.name}
              </NewText>
              <ReadMoreIcon />
            </StyledLink>
          )}
        </MobileLink>
      </Mobile>
    </StyledBackground>
  )
}

export default Brands
