import styled from "@emotion/styled"
import { NewText } from "../NewText/NewText"
import { ErrorIcon } from "../icons"
import { sharedColors } from "../theme/colors"

const StyledError = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
`

type GeneralErrorMsgProps = {
  error: string
}

function GeneralErrorMsg({ error }: GeneralErrorMsgProps) {
  return (
    <StyledError>
      <ErrorIcon />
      <NewText
        component={"p"}
        largeDesktopFontSize={16}
        largeDesktopLineHeight={"24px"}
        desktopFontSize={12}
        desktopLineHeight={"16px"}
        desktopFontWeight={400}
        color={sharedColors.darkRed}
        marginLeft={"4px"}
      >
        {error}
      </NewText>
    </StyledError>
  )
}
export default GeneralErrorMsg
