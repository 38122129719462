import { SVGProps } from "react"
const CircleRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17 14.6084L23 20.6084L17 26.6084"
      stroke={props.color}
      strokeWidth="1.53846"
      strokeLinecap="round"
      strokeLinejoin="round"
      opacity={props.opacity}
    />
    <rect
      x="0.5"
      y="1.1084"
      width="39"
      height="39"
      rx="19.5"
      stroke={props.color}
      opacity={props.opacity}
    />
  </svg>
)
export default CircleRightIcon
