import styled from "@emotion/styled"

import React, { ElementType } from "react"
import { NewText } from "../NewText/NewText"
import { sharedColors } from "../theme/colors"

const StyledTextWrapper = styled.div<{
  $marginTop?: number
  $marginLeft?: number
  $marginBottom?: number
  noMobileMargin?: boolean
  spaceBetweenTextToLine?: number
  lineAboveTextInDesktop?: boolean
  smallDesktopLineHeight: number
  largeDesktopLineHeight: number
}>`
  display: flex;
  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
    gap:${(p) => p.spaceBetweenTextToLine ? `${p.spaceBetweenTextToLine}` : '0'}px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: ${(p) => `${p.smallDesktopLineHeight / 2}px`};
    margin-bottom: ${(p) => `${p.$marginBottom}px`};
    align-items: flex-start;
  }
 ${(props) => props.theme.breakpoints.up("lg")} {
      margin-top: ${(p) => `${p.largeDesktopLineHeight / 2}px`};
  }

    ${(props) => props.theme.breakpoints.up("md")} {
        ${(p) => p.lineAboveTextInDesktop && "flex-direction: column;"}
        ${(p) => p.lineAboveTextInDesktop && "margin: 0px;"}
  }
`
const StyledTitleLine = styled.div<{
  $component?: ElementType<any>
  noPadding?: boolean,
  lineMarginTop?: number
  largeDesktopLineHeight: number
  desktopLineHeight: number
  lineAboveTextInDesktop?: boolean
}>`
  width: 28px;
  height: 2px;
  background: ${sharedColors.black};
  ${(props) => props.theme.breakpoints.up("md")} {
     margin-top: ${(p) => `${p.largeDesktopLineHeight / 2}px`};
    width: 26px;
    height: 4px;
    ${(p) => p.lineAboveTextInDesktop && "margin: 0px;"}
    };
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    width: 40px;
    margin-top: ${(p) => `${p.desktopLineHeight / 2}px`};
  }
`

type TitleWithLineChargeProps = {
  titleColor?: string
  title: string

  mobileFontSize: number
  mobileLineHeight?: number | undefined
  smallDesktopFontSize: number
  smallDesktopLineHeight?: number | undefined
  desktopFontSize: number,
  desktopLineHeight?: number | undefined
  largeDesktopFontSize: number
  largeDesktopLineHeight?: number | undefined

  lineHeightRatio?: number | undefined
  marginTop?: number
  marginLeft?: number
  marginBottom?: number
  desktopFontWeight?: number
  component?: ElementType<any>
  noPadding?: boolean
  noMobileMargin?: boolean,
  lineMarginTop?: number
  spaceBetweenTextToLine?: number
  lineAboveTextInDesktop?: boolean
}

export const TitleWithLineCharge: React.FC<TitleWithLineChargeProps> = ({
  titleColor,
  title,
  marginTop = 0,
  marginLeft = 0,
  marginBottom = 0,
  lineHeightRatio = 1.12,
  desktopFontWeight = 500,
  component = "h2",
  mobileFontSize,
  mobileLineHeight,
  smallDesktopFontSize,
  smallDesktopLineHeight = Math.round(lineHeightRatio * smallDesktopFontSize),
  desktopFontSize,
  desktopLineHeight = Math.round(lineHeightRatio * desktopFontSize),
  largeDesktopFontSize,
  largeDesktopLineHeight = Math.round(lineHeightRatio * largeDesktopFontSize),
  noPadding,
  noMobileMargin,
  lineMarginTop,
  spaceBetweenTextToLine,
  lineAboveTextInDesktop = false
}) => {
  return (
    <StyledTextWrapper
      $marginTop={marginTop}
      $marginLeft={marginLeft}
      $marginBottom={marginBottom}
      noMobileMargin={noMobileMargin}
      spaceBetweenTextToLine={spaceBetweenTextToLine}
      lineAboveTextInDesktop={lineAboveTextInDesktop}
      smallDesktopLineHeight={smallDesktopLineHeight}
      largeDesktopLineHeight={largeDesktopLineHeight}
    >
      <StyledTitleLine
        $component={component}
        noPadding={noPadding}
        lineMarginTop={lineMarginTop}
        desktopLineHeight={desktopLineHeight}
        largeDesktopLineHeight={largeDesktopLineHeight}
        lineAboveTextInDesktop={lineAboveTextInDesktop}
      />
      <NewText
        mobileFontSize={mobileFontSize ? mobileFontSize : 36}
        desktopFontSize={desktopFontSize ? desktopFontSize : 64}
        component={component}
        desktopLineHeight={desktopLineHeight}
        desktopFontWeight={desktopFontWeight}
        mobileFontWeight={desktopFontWeight}
        largeDesktopFontSize={largeDesktopFontSize}
        largeDesktopLineHeight={`${largeDesktopLineHeight}px`}
        smallDesktopFontSize={smallDesktopFontSize}
        smallDesktopLineHeight={`${smallDesktopLineHeight}px`}
        mobileLineHeight={mobileLineHeight || "100%"}
        color={titleColor ? titleColor : sharedColors.blue}
      >
        {title}
      </NewText>
    </StyledTextWrapper>
  )
}
