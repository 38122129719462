"use Client"

import styled from "@emotion/styled"
import IconButton from "@mui/material/IconButton"
import InputBase from "@mui/material/InputBase"
import Paper from "@mui/material/Paper"
import { useRouter } from "next/navigation"
import React, { Dispatch, SetStateAction } from "react"
import { Desktop, Mobile } from "../common/bit/MediaQueries"
import { LocationIcon, SubmitSearchIcon } from "../icons"
import { sharedColors } from "../theme/colors"

type Props = {
  placeholder: string
  searchIcon: boolean
  value: string
  setValue: Dispatch<SetStateAction<string>>
  width?: string
  height?: string
  radius?: string
  isCorporate?: boolean
  stopNavigation?: boolean
  onSubmitCallback?: () => void
  setSelectedMarker?: (value: string) => void
}

const StyledInputBase = styled(InputBase)`
  & .MuiInputBase-input::placeholder {
    color: #153e69;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    ${(props) => props.theme.breakpoints.up("md")} {
      font-size: 16px;
      line-height: 24px;
    }
  }
`

const StyledIconButton = styled(IconButton)`
  border-radius:0;
  :focus-visible{
    outline:2px solid blue;
  }
`

export const SearchInput: React.FC<Props> = ({
  placeholder,
  searchIcon,
  value,
  setValue,
  width,
  height,
  radius,
  isCorporate,
  stopNavigation = false,
  onSubmitCallback,
  setSelectedMarker = () => { }
}) => {
  const router = useRouter()
  const searchResultPath = isCorporate ? "/service-locator" : "/search"
  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (onSubmitCallback) {
      onSubmitCallback();
    }
    if (stopNavigation) {
      return
    }
    if (value === "") {
      return
    }
    router.push(`${searchResultPath}?searchString=${value}&origin=searchProducts`);
  }
  return (
    <Paper
      component="form"
      sx={{
        p: "4px",
        borderRadius: radius || "24px",
        display: "flex",
        width,
        height,
        alignItems: "center",
        border: "1px solid rgba(185, 197, 210, 0.70)",
      }}
      onSubmit={submitHandler}
    >
      {searchIcon && (
        <StyledIconButton sx={{ p: "0px", pl: "8px" }} aria-label="menu" disableRipple={true} tabIndex={-1}>
          <LocationIcon color={sharedColors.darkBlue} />
        </StyledIconButton>
      )}
      <StyledInputBase
        sx={{
          ml: "8px",
          flex: 1,
          color: sharedColors.darkBlue,
        }}
        placeholder={placeholder}
        inputProps={{ "aria-label": placeholder }}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onFocus={() => setSelectedMarker("")}
      />

      <StyledIconButton
        disableRipple={true}
        type="submit"
        style={{
          padding: 0,
          margin: 0,
          minHeight: 0,
          minWidth: 0,
        }}
        color="primary"
        sx={{ p: "0px" }}
        aria-label="חפש מוצר"
      >
        <Mobile>
          <SubmitSearchIcon width={32} height={32} />
        </Mobile>
        <Desktop>
          <SubmitSearchIcon width={40} height={40} />
        </Desktop>
      </StyledIconButton>
    </Paper>
  )
}
