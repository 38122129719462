import { SVGProps } from "react"
const ListIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.2993 3.33203H6.66278C6.11261 3.33203 5.66602 3.77764 5.66602 4.33203V23.6654C5.66602 24.2176 6.11373 24.6654 6.66601 24.6654H21.3327C21.885 24.6654 22.3327 24.2176 22.3327 23.6654V13.9987V9.15148H18.7993C17.4186 9.15148 16.2993 8.03219 16.2993 6.65147V3.33203ZM23.3327 8.65148V9.15148V13.9987V23.6654C23.3327 24.7699 22.4373 25.6654 21.3327 25.6654H6.66601C5.56144 25.6654 4.66602 24.7699 4.66602 23.6654V4.33203C4.66602 3.22746 5.55822 2.33203 6.66278 2.33203H16.2993H16.7993L17.2993 2.81566L22.8158 8.15148L23.3327 8.65148ZM21.3774 8.15148L17.2993 4.20692V6.65147C17.2993 7.4799 17.9709 8.15148 18.7993 8.15148H21.3774ZM8.63268 13.9987C8.63268 13.6121 8.94608 13.2987 9.33268 13.2987H18.666C19.0526 13.2987 19.366 13.6121 19.366 13.9987C19.366 14.3853 19.0526 14.6987 18.666 14.6987H9.33268C8.94608 14.6987 8.63268 14.3853 8.63268 13.9987ZM9.33268 9.7987C8.94608 9.7987 8.63268 10.1121 8.63268 10.4987C8.63268 10.8853 8.94608 11.1987 9.33268 11.1987H13.9993C14.3859 11.1987 14.6993 10.8853 14.6993 10.4987C14.6993 10.1121 14.3859 9.7987 13.9993 9.7987H9.33268ZM8.63268 17.4987C8.63268 17.1121 8.94608 16.7987 9.33268 16.7987H18.666C19.0526 16.7987 19.366 17.1121 19.366 17.4987C19.366 17.8853 19.0526 18.1987 18.666 18.1987H9.33268C8.94608 18.1987 8.63268 17.8853 8.63268 17.4987ZM9.33268 20.2987C8.94608 20.2987 8.63268 20.6121 8.63268 20.9987C8.63268 21.3853 8.94608 21.6987 9.33268 21.6987H18.666C19.0526 21.6987 19.366 21.3853 19.366 20.9987C19.366 20.6121 19.0526 20.2987 18.666 20.2987H9.33268Z"
      stroke="#5C6474"
    />
  </svg>
)
export default ListIcon
