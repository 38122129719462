import styled from "@emotion/styled"
import Typography, { TypographyProps } from "@mui/material/Typography"
import { AriaAttributes } from "react"

const ScreenReaderText = styled(Typography)`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`

type Props = {
  children: string
  variant: TypographyProps["variant"]
} & AriaAttributes

export const ScreenReaderOnly = ({ children, variant,  ...ariaProps }: Props) => (
  <ScreenReaderText variant={variant} {...ariaProps}>{children}</ScreenReaderText>
)
