"use client"
import styled from "@emotion/styled"
import {
  Button,
  CircularProgress,
  IconButton,
  InputBase,
  Paper,
} from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import Link from "next/link"
import { usePathname, useSearchParams } from "next/navigation"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useIsDesktop } from "../common/bit/MediaQueries"
import { SearchIcon, SubmitSearchIcon } from "../icons"
import { sharedColors } from "../theme/colors"

const StyledInputBase = styled(InputBase)`
  & .MuiInputBase-input {
    color: #153e69;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    ${(props) => props.theme.breakpoints.up("md")} {
      font-size: 18px;
      line-height: 25.2px;
    }
  }
`
const SearchBox = styled.div`
  position: absolute;
  top: 50px;
  width: 100%;
  z-index: 1000000000;
  height: 100%;
  background-color: white;
`

const ResultsContainer = styled.div`
  padding: 16px;
  overflow: hidden;
  display: flex;
  /* margin-top: 24px; */
  flex-direction: column;
  z-index: 1000000000;
  background-color: white;
  border-radius: 24px;
`
const Result = styled.div`
  padding: 12px 16px 12px 16px;
  width: 100%;
  height: 48px;
  color: #153e69;
  font-size: 16px;
  line-height: 24px;
  z-index: 1000000000;
  border-bottom: 1px solid #153e694d;
`
type Item = {
  id: number
  name: string
  urlSegment: string
  sortOrder: number
  level: number
  path: string
  createDate: string
  updateDate: string
  key: string
  url: string
}

export type QueryResults = {
  items: Item[]
  pageSize: number
  pageIndex: number
  totalRecords: number
  term: string
}
function getNonUmbracoURL(url?: string) {
  if (!url) return ""
  return url.replace(/.*\/pages/, "")
}

async function fetchResults(
  baseUrl: string,
  searchText: string,
  searchPath?: string,
) {
  const apiString = `${baseUrl}/mint/search?path=${searchPath}&term=${searchText}&pageIndex=0&pageSize=10`
  const apiResult = await fetch(apiString)
  const apiData = await apiResult.json()
  return apiData
}

type Props = {
  setSearchIsActive: Dispatch<SetStateAction<boolean>>
  searchPath?: string
  GRAPHQL_URL?: string
  onSearch?: (searchText: string, results: string[]) => void
}

export const SearchAutocomplete = ({
  searchPath,
  setSearchIsActive,
  GRAPHQL_URL = "",
  onSearch,
}: Props) => {
  const searchParams = useSearchParams().get("searchString")
  const pathname = usePathname()
  const defaultText =
    pathname === "/search" && searchParams ? searchParams || "" : ""
  const [inputText, setInputText] = useState(defaultText)
  const [searchText, setSearchText] = useState(defaultText)
  const { data, isLoading, isError, isSuccess } = useQuery<QueryResults>({
    queryKey: [searchText],
    queryFn: () =>
      fetchResults(GRAPHQL_URL.replace("/graphql", ""), searchText, searchPath),

    enabled: !!searchText,
    refetchOnWindowFocus: false,
  })
  const isOpen = isError || isSuccess
  const isDesktop = useIsDesktop()
  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchText(inputText)
    }, 1000)

    return () => {
      clearTimeout(handler)
    }
  }, [inputText])
  useEffect(() => {
    if (onSearch && isSuccess) {
      onSearch(
        searchText,
        data.items.map((el) => el.name),
      )
    }
  }, [data])
  console.log(data, "CHECKTHIS")
  return (
    <>
      <div
        style={{
          position: "relative",
          width: "90%",
          margin: "auto",
          // paddingLeft: 20,
          // paddingRight: 40,
        }}
      >
        <Paper
          component="form"
          sx={{
            p: 0,
            pt: "4px",
            pb: "4px",
            borderRadius: "24px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            border: "1px solid rgba(185, 197, 210, 0.70)",
            boxShadow: "none",
          }}
          onSubmit={(e) => {
            e.preventDefault()
            setSearchText(inputText)
          }}
        >
          <IconButton sx={{ p: "0px", pl: "8px" }} aria-label="menu">
            <SearchIcon color={sharedColors.darkBlue} />
          </IconButton>

          <StyledInputBase
            sx={{
              ml: "8px",
              flex: 1,
              color: sharedColors.darkBlue,
            }}
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            inputProps={{ "aria-label": "שמני ממסרה דביקים" }}
          />
          <Button
            type="submit"
            style={{
              padding: 0,
              margin: 0,
              minHeight: 0,
              minWidth: 0,
            }}
          >
            <IconButton
              color="primary"
              sx={{ p: "0px", pr: "8px" }}
              aria-label="directions"
            >
              {isLoading ? (
                <CircularProgress color="inherit" size={isDesktop ? 40 : 32} />
              ) : (
                <SubmitSearchIcon
                  width={!isDesktop ? 32 : 40}
                  height={!isDesktop ? 32 : 40}
                  blue
                />
              )}
            </IconButton>
          </Button>
        </Paper>
        {isOpen && (
          <SearchBox>
            <ResultsContainer>
              {data && (
                <>
                  {data.items.map((el) => (
                    <Link
                      style={{ textDecoration: "none", cursor: "pointer" }}
                      href={getNonUmbracoURL(
                        el.url.includes("home") ? "/" : el.url,
                      )}
                      onClick={() => setSearchIsActive(false)}
                      key={el.id}
                    >
                      <Result>{el.name}</Result>
                    </Link>
                  ))}
                </>
              )}
              {!data ||
                (data.items.length === 0 && <Result>אין תוצאות</Result>)}
            </ResultsContainer>
          </SearchBox>
        )}
      </div>
    </>
  )
}

export default SearchAutocomplete
