import styled from "@emotion/styled"
import { Button, ButtonProps } from "@mui/material"
import { NewText } from "./NewText/NewText"
import { ReadMoreIcon } from "./icons"
import { sharedColors } from "./theme/colors"

const StyledButton = styled(Button, {
  shouldForwardProp: (props) =>
    props !== "$reverse" &&
    props !== "$justifyContent" &&
    props !== "$svgRelative" &&
    props !== "$backgroundColor" &&
    props !== "$textColor",
}) <{
  $reverse?: boolean
  $justifyContent?: string
  $svgRelative?: boolean
  $backgroundColor?: string
  $textColor?: string
}>`
  background: ${(p) =>
    p.disabled
      ? sharedColors.darkBlueTransparent
      : p.$backgroundColor || sharedColors.blue};
  color: ${(p) => p.$textColor || sharedColors.white};
  border-radius: 24px;
  display: flex;
  justify-content: ${(p) =>
    p.$justifyContent ? p.$justifyContent : "space-between"};
  padding-left: 12px;
  padding-right: 12px;
  text-transform: none;

  position: relative;
  gap: ${(p) => (p.$svgRelative ? "5px" : "")};
  flex-direction: ${(p) => (p.$reverse ? "row-reverse" : "row")};
  svg {
    position: ${(p) => (p.$svgRelative ? "" : "absolute")};
    ${(p) => (p.$reverse ? "left: 15px" : "right: 15px")};
    ${(p) => p.$reverse && "transform: rotate(180deg) !important"};
    width: 24px;
    path {
      stroke: ${(p) => p.$textColor || sharedColors.white};
    }
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-left: 13px;
    padding-right: 13px;
    svg {
      ${(p) => (p.$reverse ? "left: 13px" : "right: 13px")};
      width: 16px;
    }
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    padding-left: 20px;
    padding-right: 20px;
    svg {
      width: 24px;
      ${(p) => (p.$reverse ? "left: 20px" : "right: 20px")};
    }
  }

  &:hover {
    background: ${(p) => p.$backgroundColor || sharedColors.blue};
    color: ${(p) => p.$textColor || sharedColors.white};
  }
  :focus-visible{
    border: 1px solid black;
  }
`
type ButtonWithArrowProps = {
  title: string
  desktopWidth: number
  desktopHeight: number
  mobileWidth?: number
  mobileHeight?: number
  type?: "button" | "reset" | "submit" | undefined
  onClick?: () => void
  reverse?: boolean
  justifyContent?: string
  smallDesktopWidth?: number
  smallDesktopHeight?: number
  desktopFontSize?: number
  mobileFontSize?: number
  smallDesktopFontSize?: number
  svgRelative?: boolean
  backgroundColor?: string
  textColor?: string
  rotateArrow?: number
  largeDesktopFontSize?: string | number
  largeDesktopWidth?: number
  largeDesktopHeight?: number
  disabled?: boolean
  desktopFontWeight?: number
  asChild?: boolean
} & ButtonProps

export const ButtonWithArrow: React.FC<ButtonWithArrowProps> = ({
  title,
  desktopWidth,
  desktopHeight,
  mobileWidth = desktopWidth,
  mobileHeight = desktopHeight,
  type = "button",
  onClick = () => { },
  reverse = false,
  justifyContent = "space-between",
  smallDesktopHeight = desktopHeight,
  smallDesktopWidth = desktopWidth,
  desktopFontSize = 20,
  mobileFontSize = 16,
  smallDesktopFontSize = 14,
  svgRelative,
  backgroundColor,
  textColor,
  rotateArrow,
  largeDesktopFontSize = desktopFontSize,
  largeDesktopHeight = desktopHeight,
  largeDesktopWidth = desktopWidth,
  disabled,
  desktopFontWeight,
  asChild,
  ...props
}) => {
  return (
    <StyledButton
      disabled={disabled}
      tabIndex={asChild ? -1 : undefined}
      sx={{
        width: {
          xs: mobileWidth,
          sm: smallDesktopWidth,
          lg: desktopWidth,
          xl: largeDesktopWidth,
        },
        height: {
          xs: mobileHeight,
          sm: smallDesktopHeight,
          lg: desktopHeight,
          xl: largeDesktopHeight,
        },
      }}
      disableRipple
      type={type}
      onClick={onClick}
      $reverse={reverse}
      $justifyContent={justifyContent}
      $svgRelative={svgRelative}
      $backgroundColor={backgroundColor}
      $textColor={textColor}
      {...props}
    >
      <NewText
        mobileFontSize={mobileFontSize}
        desktopFontSize={desktopFontSize}
        largeDesktopFontSize={largeDesktopFontSize}
        component="p"
        desktopFontWeight={desktopFontWeight || "500"}
        color={textColor || sharedColors.white}
        smallDesktopFontSize={smallDesktopFontSize}
        smallDesktopLineHeight="20px"
      >
        {title}
      </NewText>
      <ReadMoreIcon rotate={rotateArrow} />
    </StyledButton>
  )
}

export default ButtonWithArrow
