import { SVGProps } from "react"
const MapIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 28}
    height={props.height || 28}
    fill="none"
    viewBox="0 0 28 28"
    {...props}
  >
    <path
      d="M14.0001 25.2008C14.0001 25.2008 22.7653 17.4095 22.7653 11.566C22.7653 6.7251 18.841 2.80078 14.0001 2.80078C9.15918 2.80078 5.23486 6.7251 5.23486 11.566C5.23486 17.4095 14.0001 25.2008 14.0001 25.2008Z"
      stroke="#5C6474"
      stroke-width="1.4"
    />
    <path
      d="M16.8004 11.201C16.8004 12.7474 15.5468 14.001 14.0004 14.001C12.454 14.001 11.2004 12.7474 11.2004 11.201C11.2004 9.65456 12.454 8.40096 14.0004 8.40096C15.5468 8.40096 16.8004 9.65456 16.8004 11.201Z"
      stroke="#5C6474"
      stroke-width="1.4"
    />
  </svg>
)
export default MapIcon
