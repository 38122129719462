import { SVGProps } from "react"
const CalendarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 28}
    height={props.height || 28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.5 12.25V22.5C3.5 23.6046 4.39543 24.5 5.5 24.5H22.5C23.6046 24.5 24.5 23.6046 24.5 22.5V12.25M3.5 12.25V7.83333C3.5 6.72876 4.39543 5.83333 5.5 5.83333H8.16667M3.5 12.25H24.5M24.5 12.25V7.83333C24.5 6.72876 23.6046 5.83333 22.5 5.83333H20.4167M20.4167 5.83333V3.5V8.75M20.4167 5.83333H8.16667M8.16667 5.83333V3.5V8.75"
      stroke={props.color || "#5C6474"}
      strokeWidth="1.4"
      strokeLinecap="round"
    />
  </svg>
)
export default CalendarIcon
