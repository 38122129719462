"use client"
import { Box, Skeleton, Typography } from "@mui/material"
import { useEffect } from "react"
import { Desktop, Mobile } from "../common/bit/MediaQueries"

const LoadingSkeletonPage = () => {
  useEffect(() => {
    setTimeout(()=>{
      window?.location.reload();
    },5000)
  }
  , [])
  return (
    <Box>
      <Desktop>
        <Skeleton
          variant="rectangular"
          width="90%"
          height="500px"
          style={{ marginInlineStart: "auto" }}
        />

        <Box margin="50px 10%" display="flex" justifyContent="space-between">
          <Box
            width="500px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Typography variant="h1">
              <Skeleton variant="text" />
            </Typography>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </Box>
          <Skeleton variant="rectangular" width="500px" height="300px" />
        </Box>
        <Box margin="50px 10%" display="flex" justifyContent="space-between">
          <Skeleton variant="rectangular" width="500px" height="300px" />
          <Box
            width="500px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Typography variant="h2">
              <Skeleton variant="text" />
            </Typography>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </Box>
        </Box>
      </Desktop>
      <Mobile>
        <Skeleton
          variant="rectangular"
          width="90%"
          height="300px"
          style={{ marginInlineStart: "auto" }}
        />
        <Box margin="30px 15px">
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Typography variant="h1">
              <Skeleton variant="text" />
            </Typography>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </Box>
          <Skeleton
            variant="rectangular"
            width="100%"
            height="300px"
            style={{ marginTop: 30, marginBottom: 30 }}
          />
        </Box>
      </Mobile>
    </Box>
  )
}

export default LoadingSkeletonPage
