import { css } from "@emotion/react";

export const GlobalStyle = css`
* {
  box-sizing: border-box;
  list-style-type:none;

}
  :root {
    direction: rtl;
  }

  body{
    width:100%;
  }


  .swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: calc(100% - 40px);
  }

  .swiper-slide img {
    display: block;
    width: 100%;
  }
  .swiper-button-disabled {
    opacity:0.3;
  }
`
