import { SVGProps } from "react"
const EmailIconMobile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#0050C9"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.4}
      d="m3 5.087 7 5.5 7.5-5.5m-10.833 6.25L3 15.087m14.551.089-5.736-5.92M4 15.913a2 2 0 0 1-2-2V6.087a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v7.826a2 2 0 0 1-2 2H4Z"
    />
  </svg>
)
export default EmailIconMobile
