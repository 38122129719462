import { SVGProps } from "react"

interface RightArrowShortProps extends SVGProps<SVGSVGElement> {
  size?: number
}

const RightArrowShort = ({ size = 24, ...props }: RightArrowShortProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.5882 16.8897L21 12.2574M21 12.2574L16.5882 7.625M21 12.2574H3"
      stroke={props.color || "#000000"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default RightArrowShort
