import { SVGProps } from "react"
const PdfIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.85 4.5H8.99615C8.17023 4.5 7.5 5.16906 7.5 6V30C7.5 30.8284 8.17157 31.5 9 31.5H27C27.8284 31.5 28.5 30.8284 28.5 30V18V11.875H24.6C22.5289 11.875 20.85 10.1961 20.85 8.125V4.5ZM30 11.125V11.875V18V30C30 31.6569 28.6569 33 27 33H9C7.34315 33 6 31.6569 6 30V6C6 4.34315 7.3393 3 8.99615 3H20.85H21.6L22.35 3.72545L29.2246 10.375L30 11.125ZM27.0671 10.375L22.35 5.81233V8.125C22.35 9.36764 23.3574 10.375 24.6 10.375H27.0671ZM10.95 18C10.95 17.4201 11.4201 16.95 12 16.95H24C24.5799 16.95 25.05 17.4201 25.05 18C25.05 18.5799 24.5799 19.05 24 19.05H12C11.4201 19.05 10.95 18.5799 10.95 18ZM12 12.45C11.4201 12.45 10.95 12.9201 10.95 13.5C10.95 14.0799 11.4201 14.55 12 14.55H18C18.5799 14.55 19.05 14.0799 19.05 13.5C19.05 12.9201 18.5799 12.45 18 12.45H12ZM10.95 22.5C10.95 21.9201 11.4201 21.45 12 21.45H24C24.5799 21.45 25.05 21.9201 25.05 22.5C25.05 23.0799 24.5799 23.55 24 23.55H12C11.4201 23.55 10.95 23.0799 10.95 22.5ZM12 25.95C11.4201 25.95 10.95 26.4201 10.95 27C10.95 27.5799 11.4201 28.05 12 28.05H24C24.5799 28.05 25.05 27.5799 25.05 27C25.05 26.4201 24.5799 25.95 24 25.95H12Z"
      fill="#153E69"
    />
  </svg>
)
export default PdfIcon
