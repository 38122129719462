import { SVGProps } from "react"

interface Props extends SVGProps<SVGSVGElement> {
  active?: boolean
}
const LocatorArrowRight = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="rgba(38, 66, 166, 1)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2 12h20M16 18l6-6-6-6"
    />
  </svg>
)
export default LocatorArrowRight
