import Typography from "@mui/material/Typography";
import { visuallyHidden } from '@mui/utils';
import React from "react";

type AlertMessageSROnlyProps = {
  children: string | string[];
  component?: React.ElementType; // Allow the user to specify the component type
};

const AlertMessageSROnly: React.FC<AlertMessageSROnlyProps> = ({ children, component = "span" }) => (
  <Typography sx={visuallyHidden} component={component} role="alert">
    {children}
  </Typography>
);

export default AlertMessageSROnly;
