type SubjectName =
  | "מוצג באתר"
  | "תהליך הקנייה"
  | "משהו שעשה לי את היום"
  | "הזמנה שעשיתי"
  | "עדכון פרטים"
  | "חנות / עובד"
  | "תקלה טכנית"
  | "החזרת מוצר"
  | "משהו אחר"
  | "ספרו לי עוד איך אפשר לעבוד יחד ולשלוח דרככם חבילות של העסק שלי"
  | "אחר"
  | "מה סטטוס החבילה שלי"
  | "פז - שיווקי"
  | "הצטרפות"
  | "רחיצה"
  | "בירור בנושא חשבוניות"
  | "תמיכה באתר"
  | "גבייה"
  | "צירוף חברה קשורה/שינוי שם חברה"
  | "הרשמה פזומט"
  | "הצטרפות פזומט"
  | "אחר - פזומט"

type SubjectIdPair = {
  subjectCode: string
  subject: SubjectName
}

const array: SubjectIdPair[] = [
  { subjectCode: "010", subject: "מוצג באתר" },
  { subjectCode: "020", subject: "תהליך הקנייה" },
  { subjectCode: "030", subject: "משהו שעשה לי את היום" },
  { subjectCode: "040", subject: "הזמנה שעשיתי" },
  { subjectCode: "050", subject: "עדכון פרטים" },
  { subjectCode: "060", subject: "חנות / עובד" },
  { subjectCode: "070", subject: "תקלה טכנית" },
  { subjectCode: "080", subject: "החזרת מוצר" },
  { subjectCode: "090", subject: "משהו אחר" },
  {
    subjectCode: "110",
    subject: "ספרו לי עוד איך אפשר לעבוד יחד ולשלוח דרככם חבילות של העסק שלי",
  },
  { subjectCode: "140", subject: "אחר" },
  { subjectCode: "130", subject: "מה סטטוס החבילה שלי" },
  { subjectCode: "120", subject: "אחר" },
  { subjectCode: "150", subject: "פז - שיווקי" },
  { subjectCode: "999010", subject: "הצטרפות" },
  { subjectCode: "999020", subject: "אחר - פזומט" },
  { subjectCode: "999030", subject: "רחיצה" },
  { subjectCode: "999040", subject: "בירור בנושא חשבוניות" },
  { subjectCode: "999050", subject: "תמיכה באתר" },
  { subjectCode: "999060", subject: "גבייה" },
  { subjectCode: "999070", subject: "צירוף חברה קשורה/שינוי שם חברה" },
  { subjectCode: "Z080", subject: "הרשמה פזומט" },
  { subjectCode: "Z010", subject: "הצטרפות פזומט" },
]
// ... continue for the rest of the rows

export function getSubjectId(subject: SubjectName): string {
  const pair = array.find((item) => item.subject === subject)
  return pair ? pair.subjectCode : "140"
}
