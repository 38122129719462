import * as React from "react"

const CircleArrow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 48}
    height={props.height || 48}
    fill="none"
    {...props}
  >
    <rect
      width={props.width || 48}
      height={props.height || 48}
      fill="#0050C9"
      rx={props.width === 32 ? 16 : 24}
    />
    {props.width === 32 ? (
      <path
        d="M15.1105 21.3324L10.666 16.6657M10.666 16.6657L15.1105 11.999M10.666 16.6657H21.3327"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    ) : (
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M22.667 31 16 24m0 0 6.667-7M16 24h16"
      />
    )}
  </svg>
)
export default CircleArrow
