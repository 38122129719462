"use client"
import styled from "@emotion/styled"
import {
  Button,
  CircularProgress,
  IconButton,
  InputBase,
  Modal,
  Paper,
} from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import Link from "next/link"
import { usePathname, useSearchParams } from "next/navigation"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { NewText } from "../NewText/NewText"
import { SearchIcon, SubmitSearchIcon, XIcon } from "../icons"
import { sharedColors } from "../theme/colors"

const StyledInputBase = styled(InputBase)`
  & .MuiInputBase-input {
    color: #153e69;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    ${(props) => props.theme.breakpoints.up("md")} {
      font-size: 18px;
      line-height: 25.2px;
    }
  }
`
const SearchBox = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
`

const ResultsContainer = styled.div`
  overflow-y: scroll;
  display: flex;
  /* margin-top: 24px; */
  flex-direction: column;
  z-index: 1000000000;
  background-color: white;
  border-radius: 24px;
`
const Result = styled.div`
  padding: 12px 16px 12px 16px;
  width: 100%;
  height: 48px;
  color: #153e69;
  font-size: 16px;
  line-height: 24px;
  z-index: 1000000000;
  border-bottom: 1px dashed #153e694d;
  :hover {
    background-color: #153e690f;
  }
`

const StyledContainer = styled.div`
  height: 100%;
  background-color: white;
  margin-top: 63px;
  padding-top: 32px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`
const StyledIconButton = styled(IconButton)`
  padding: 0px;
`

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
`

const FormContainer = styled.div`
  padding: 20px;
`
const StyledIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`
const StyledX = styled(IconButton)`
  /* display: flex; */
  /* align-items: stretch; */
  padding: 0 4px;
  margin: 0;
  border-radius: 0;
  height: 100%;
  border-right: 1px dashed #153e694d;
`

type Item = {
  id: number
  name: string
  urlSegment: string
  sortOrder: number
  level: number
  path: string
  createDate: string
  updateDate: string
  key: string
  url: string
}

type QueryResults = {
  items: Item[]
  pageSize: number
  pageIndex: number
  totalRecords: number
  term: string
}
function getNonUmbracoURL(url?: string) {
  if (!url) return ""
  return url.replace(/.*\/pages/, "")
}

type Props = {
  setMobileSearch: Dispatch<SetStateAction<boolean>>
  mobileSearch: boolean
  searchPath?: string
  GRAPHQL_URL?: string
  onSearch?: (searchText: string, results: string[]) => void
}
export const SearchMobile = ({
  searchPath,
  setMobileSearch,
  mobileSearch,
  GRAPHQL_URL = "",
  onSearch,
}: Props) => {
  const searchParams = useSearchParams().get("searchString")
  const pathname = usePathname()
  const defaultText = pathname === "/search" && searchParams ? searchParams : ""
  const [inputText, setInputText] = useState(defaultText)
  const [searchText, setSearchText] = useState(defaultText)
  const { data, isLoading, isError, isSuccess } = useQuery<QueryResults>({
    queryKey: [searchText],
    queryFn: () =>
      fetch(`
      ${GRAPHQL_URL?.replace("/graphql", "")}/mint/search?path=${searchPath}&term=${searchText}&pageIndex=0&pageSize=10
      `)
        .then((res) => res.json())
        .catch((err) => console.log(err, "CHECKTHIS")),

    enabled: !!searchText,
    refetchOnWindowFocus: false,
  })
  const clearHandle = () => {
    setInputText("")
    setSearchText("")
  }
  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchText(inputText)
    }, 1000)

    return () => {
      clearTimeout(handler)
    }
  }, [inputText])

  useEffect(() => {
    if (onSearch && isSuccess) {
      onSearch(
        searchText,
        data.items.map((el) => el.name),
      )
    }
  }, [data])

  return (
    <Modal open={mobileSearch} onClose={() => setMobileSearch(false)}>
      <StyledContainer>
        <StyledHeader>
          <NewText
            desktopFontSize={18}
            component={"h2"}
            mobileFontSize={18}
            mobileLineHeight={"25.2px"}
            color="black"
            desktopFontWeight={500}
          >
            חיפוש באתר
          </NewText>
          <StyledIconButton
            onClick={() => {
              clearHandle()
              setMobileSearch(false)
            }}
          >
            <XIcon />
          </StyledIconButton>
        </StyledHeader>
        <FormContainer>
          <Paper
            component="form"
            sx={{
              p: 0,
              pt: "4px",
              pb: "4px",
              borderRadius: "24px",
              display: "flex",
              alignItems: "center",
              width: "100%",
              border: "1px solid rgba(185, 197, 210, 0.70)",
              boxShadow: "none",
            }}
            onSubmit={(e) => {
              e.preventDefault()
              setSearchText(inputText)
            }}
          >
            <IconButton sx={{ p: "0px", pl: "8px" }} aria-label="menu">
              <SearchIcon color={sharedColors.darkBlue} />
            </IconButton>

            <StyledInputBase
              sx={{
                ml: "8px",
                flex: 1,
                color: sharedColors.darkBlue,
              }}
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              inputProps={{ "aria-label": "שמני ממסרה דביקים" }}
            />
            <StyledIcons>
              {inputText.length ? (
                <StyledX onClick={clearHandle}>
                  <XIcon color="#5C6474" width={18} height={18} />
                </StyledX>
              ) : null}

              <Button
                type="submit"
                style={{
                  padding: 0,
                  margin: 0,
                  minHeight: 0,
                  minWidth: 0,
                }}
                disabled={isLoading}
              >
                <IconButton
                  color="primary"
                  sx={{ p: "0px", pr: "8px" }}
                  aria-label="directions"
                >
                  {isLoading ? (
                    <CircularProgress color="inherit" size={32} />
                  ) : (
                    <SubmitSearchIcon width={32} height={32} blue />
                  )}
                </IconButton>
              </Button>
            </StyledIcons>
          </Paper>
        </FormContainer>

        <SearchBox>
          <ResultsContainer>
            {data && (
              <>
                {data.items.map((el) => (
                  <Link
                    style={{ textDecoration: "none", cursor: "pointer" }}
                    href={getNonUmbracoURL(
                      el.url.includes("home") ? "/" : el.url,
                    )}
                    onClick={() => {
                      clearHandle()
                      setMobileSearch(false)
                    }}
                    key={el.id}
                  >
                    <Result>{el.name}</Result>
                  </Link>
                ))}
              </>
            )}
            {!data || (data.items.length === 0 && <Result>אין תוצאות</Result>)}
          </ResultsContainer>
        </SearchBox>
      </StyledContainer>
    </Modal>
  )
}

export default SearchMobile
