import { SVGProps } from "react"
const AdobeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.6277 25.1946C12.4376 25.285 12.2791 25.4304 12.1727 25.612C10.7706 28.005 9.42488 29.8751 8.27843 30.908C7.14256 31.788 6.3766 31.95 5.96384 31.95C5.61148 31.95 5.34382 31.8742 4.9684 31.75C4.43652 31.4404 4.2194 31.1116 4.1257 30.8465C4.023 30.556 4.02803 30.2237 4.12128 29.8823C4.2024 29.6525 4.3968 29.3323 4.7695 28.9316C5.14585 28.5269 5.66126 28.0856 6.30893 27.6211C7.60428 26.6922 9.35218 25.725 11.3751 24.8146C11.5854 24.72 11.7587 24.5587 11.8683 24.3557C13.7528 20.8628 15.2052 17.2207 16.0807 14.1446C16.1544 13.8855 16.1257 13.6081 16.0007 13.3696C14.6249 10.7452 13.9863 8.34437 13.9863 6.71429C13.9863 5.95238 14.193 5.30028 14.5117 4.74537C14.8652 4.33055 15.3958 4.05 16.2091 4.05C17.0239 4.05 17.6276 4.46159 17.9162 5.33086C17.9243 5.35506 17.9332 5.37895 17.9429 5.40249C18.1981 6.01723 18.2895 7.02134 18.1524 8.41506C18.0182 9.77905 17.6787 11.3937 17.1899 13.1465C17.1134 13.4206 17.1515 13.7139 17.2953 13.9594C18.7533 16.4477 20.5214 18.8234 22.4679 20.6273C22.718 20.8591 23.0651 20.955 23.3987 20.8844C26.103 20.3129 28.3394 20.0738 29.6809 20.3186L29.7051 20.3228C30.8126 20.4983 31.3372 20.9382 31.6015 21.299C31.8882 21.6905 31.95 22.103 31.95 22.2857C31.95 22.8432 31.8084 23.1795 31.6482 23.3967C31.4811 23.6231 31.2327 23.8019 30.8979 23.9376C30.1894 24.2249 29.3221 24.2357 28.7311 24.2357C26.8842 24.2357 24.9838 23.4939 23.2922 22.0569C23.0339 21.8375 22.6856 21.7562 22.3568 21.8387C18.897 22.7071 15.4119 23.8706 12.6277 25.1946Z"
      stroke="#153E69"
      strokeWidth="2.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default AdobeIcon
