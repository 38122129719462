import { SVGProps } from "react"
const WarningIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      stroke="#D10040"
      strokeWidth={1.5}
      d="M3.541 7.174C5.487 3.724 6.46 2 8 2c1.54 0 2.513 1.725 4.459 5.174l.242.43c1.617 2.867 2.425 4.3 1.695 5.348C13.666 14 11.857 14 8.242 14h-.485c-3.615 0-5.422 0-6.153-1.048-.73-1.048.078-2.481 1.695-5.348l.242-.43Z"
    />
    <path
      stroke="#D10040"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M8 5.333v3.334"
    />
    <circle cx={8} cy={10.667} r={0.667} fill="#D10040" />
  </svg>
)
export default WarningIcon
