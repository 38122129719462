const ShowMapIcon = ({ fill = "#000" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none">
    <path
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.4}
      d="m1.969 4 13.92 14.059M1.969 4c.517-.612 1.291-1 2.155-1H8.83M1.97 4A2.812 2.812 0 0 0 1.3 5.824v10.353A2.824 2.824 0 0 0 4.124 19h10.353a2.824 2.824 0 0 0 2.824-2.823V11.94m-8.47 0L2.712 18.06M15.5 4.2v-.06m3.2-.01C18.7 6.217 15.5 9 15.5 9s-3.2-2.783-3.2-4.87C12.3 2.402 13.733 1 15.5 1c1.767 0 3.2 1.402 3.2 3.13Z"
    />
  </svg>
)
export default ShowMapIcon
