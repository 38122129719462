import { SVGProps } from "react"

type ReadMoreIconProps = SVGProps<SVGSVGElement> & {
  rotate?: number // Rotation in degrees
}

const ReadMoreIcon = ({ rotate = 0, ...props }: ReadMoreIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    style={{ transform: `rotate(${rotate}deg)` }} // Apply rotation here
    {...props}
  >
    <path
      stroke={props.color ? props.color : "#0050C9"}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7.412 16.89 3 12.257m0 0 4.412-4.632M3 12.257h18"
    />
  </svg>
)

export default ReadMoreIcon
