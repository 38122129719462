import styled from "@emotion/styled"
import { NewText } from "../NewText/NewText"
import { Desktop, Mobile } from "../common/bit/MediaQueries"
import { LineMobileVector, LineVector } from "../icons"
import { sharedColors } from "../theme/colors"

const StyledTextContainer = styled.div`
  width: 100%;
  height: 130px;
  position: absolute;
  top: 52px;
  left: 26px;
  z-index: 2;
  ${(props) => props.theme.breakpoints.up("md")} {
    left: 49px;
    top: 178px;
    width: 80%;
    height: 288px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    width: 55%;
  }
  ${(props) => props.theme.breakpoints.up("xl")} {
    width: 50%;
  }
`
const StyledVectorContainer = styled.div`
  position: absolute;
  left: -30px;
  top: 15px;
  ${(props) => props.theme.breakpoints.up("md")} {
    left: -142px;
    top: -2px;
  }
`

const StyledLast = styled.div<{ $width: string }>`
  width: 70%;
  height: 48px;
  h3 {
    font-size: 68px;
    line-height: 68px;
    margin: 0px;
  }
  p {
    margin: 0px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${(p) => p.$width};
    height: auto;
    h3 {
      font-size: 80px;
      line-height: 96px;
      margin: 0px;
    }
    p {
      font-size: 26px;
      line-height: 40px;
      margin: 0px;
    }
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    width: ${(p) => p.$width};
    height: auto;
    h3 {
      font-size: 120px;
      line-height: 144px;
      margin: 0px;
    }
    p {
      font-size: 40px;
      line-height: 60px;
      margin: 0px;
    }
  }
`

const StyledInsideLine = styled.div`
  height: 4px;
  border-radius: 4px;
  background: ${sharedColors.blue};
  width: 28px;
  margin-right: 7px;
  margin-left: -19px;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 49px;
  }
`

const StyledTextWrapper = styled.div`
  display: flex;
  align-items: center;
`
interface StyledNewTextProps {
  corp?: boolean
}

const StyledNewText = styled(NewText) <StyledNewTextProps>`
  h3 {
    font-weight: 500;
  }
  p {
    width: ${(props) => (props.corp ? "60%" : "50%")};
    ${(props) => props.theme.breakpoints.down("md")} {
      ${(props) => (props.corp ? "width:80%" : "")}
    }
  }
`

type Props = {
  title: string | undefined
  paragraph: string
  width: string
  color: string
  outsideLine?: boolean
  insideLine?: boolean
  corp?: boolean
}

export const PositionedText: React.FC<Props> = ({
  title,
  paragraph,
  width,
  color,
  outsideLine = false,
  insideLine = false,
  corp,
}) => {
  return (
    <StyledTextContainer>
      <StyledVectorContainer>
        {outsideLine && (
          <>
            <Desktop>
              <LineVector />
            </Desktop>
            <Mobile>
              <LineMobileVector />
            </Mobile>
          </>
        )}
      </StyledVectorContainer>
      <StyledTextWrapper>
        {insideLine && <StyledInsideLine />}
        <NewText
          largeDesktopFontSize={24}
          largeDesktopLineHeight="33.6px"
          desktopFontSize={24}
          desktopLineHeight="33.6px"
          mobileFontSize={16}
          mobileLineHeight={"22.4px"}
          mobileFontWeight="400"
          component="h2"
          desktopFontWeight="500"
          color={color}
        >
          {title}
        </NewText>
      </StyledTextWrapper>

      <StyledLast $width={width}>
        <StyledNewText
          corp={corp}
          largeDesktopFontSize={40}
          largeDesktopLineHeight="60px"
          desktopFontSize={40}
          desktopLineHeight="60px"
          mobileFontSize={18}
          mobileLineHeight={"24px"}
          component="div"
          desktopFontWeight="400"
          color={color}
          dangerouslySetInnerHTML={{ __html: paragraph }}
        />
      </StyledLast>
    </StyledTextContainer>
  )
}

export default PositionedText
