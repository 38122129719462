import { SVGProps } from "react"
const AdvancedMarkerIconCharge = ({ }: SVGProps<SVGSVGElement>) => (
    <svg width="46" height="52" viewBox="0 0 46 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_6409_23021)">
            <path d="M37 21C37 26.237 30.5773 34.4726 26.4324 39.2422C24.5967 41.3545 21.4033 41.3545 19.5676 39.2422C15.4227 34.4726 9 26.237 9 21C9 13.268 15.268 7 23 7C30.732 7 37 13.268 37 21Z" fill="#FCBB00" />
            <path d="M26.4324 39.2422L26.055 38.9142L26.4324 39.2422ZM19.5676 39.2422L19.1902 39.5701L19.5676 39.2422ZM36.5 21C36.5 22.2152 36.1252 23.6416 35.4709 25.1949C34.8188 26.7429 33.9022 28.3861 32.8497 30.0263C30.7446 33.307 28.1203 36.5376 26.055 38.9142L26.8098 39.5701C28.8894 37.1771 31.5489 33.9052 33.6913 30.5664C34.7626 28.8969 35.7108 27.2013 36.3925 25.5831C37.0719 23.9702 37.5 22.4033 37.5 21H36.5ZM19.945 38.9142C17.8797 36.5376 15.2554 33.307 13.1503 30.0263C12.0978 28.3861 11.1812 26.7429 10.5291 25.1949C9.87477 23.6416 9.5 22.2152 9.5 21H8.5C8.5 22.4033 8.92807 23.9702 9.60753 25.5831C10.2892 27.2013 11.2374 28.8969 12.3087 30.5664C14.4511 33.9052 17.1106 37.1771 19.1902 39.5701L19.945 38.9142ZM9.5 21C9.5 13.5442 15.5442 7.5 23 7.5V6.5C14.9919 6.5 8.5 12.9919 8.5 21H9.5ZM23 7.5C30.4558 7.5 36.5 13.5442 36.5 21H37.5C37.5 12.9919 31.0081 6.5 23 6.5V7.5ZM26.055 38.9142C24.4186 40.7971 21.5814 40.7971 19.945 38.9142L19.1902 39.5701C21.2253 41.9118 24.7747 41.9118 26.8098 39.5701L26.055 38.9142Z" fill="white" />
        </g>
        <path d="M17.7702 21.3687L15.4775 18.5455" stroke="#005DA0" stroke-width="1.48301" stroke-linecap="round" stroke-linejoin="bevel" />
        <path d="M22.716 17.3582L20.4233 14.535" stroke="#005DA0" stroke-width="1.48301" stroke-linecap="round" stroke-linejoin="bevel" />
        <path d="M28.5192 29.535L26.2266 26.7119" stroke="#005DA0" stroke-width="1.48301" stroke-linecap="round" stroke-linejoin="bevel" />
        <path d="M23.2201 27.8992C21.6537 27.8992 20.1822 27.1918 19.178 25.9602L17.6306 24.0466C17.3653 23.7245 17.2453 23.3202 17.2832 22.9034C17.3211 22.4866 17.5232 22.1076 17.8453 21.8424L23.1822 17.516C23.4538 17.295 23.8011 17.175 24.1548 17.175C24.6285 17.175 25.0769 17.3897 25.3801 17.7624L26.9653 19.7202C27.818 20.775 28.2222 22.0887 28.0959 23.4276C27.9759 24.7413 27.3569 25.9223 26.359 26.7623C25.4874 27.495 24.3695 27.8992 23.2138 27.8992H23.2201Z" fill="#FBBA00" />
        <path d="M24.1548 17.9266C24.3948 17.9266 24.6348 18.0339 24.799 18.236L26.3843 20.1939C27.1422 21.1223 27.4517 22.2655 27.3506 23.3581C27.2496 24.4255 26.7569 25.455 25.879 26.1876C25.1085 26.8381 24.1611 27.1476 23.2138 27.1476C21.9317 27.1476 20.6432 26.5729 19.759 25.4866L18.2117 23.5792C17.9274 23.2255 17.9717 22.7139 18.319 22.436L23.6559 18.1097C23.8011 17.9897 23.978 17.9329 24.1548 17.9329M24.1548 16.4297C23.6306 16.4297 23.119 16.6129 22.7085 16.9413L17.3716 21.2676C16.8916 21.6592 16.5885 22.215 16.5316 22.8402C16.4748 23.4466 16.658 24.0466 17.0432 24.5266L18.5906 26.4339C19.7338 27.8423 21.4201 28.6508 23.2138 28.6508C24.5464 28.6508 25.8348 28.1834 26.8454 27.3371C27.9948 26.3708 28.7085 25.0066 28.8475 23.4971C28.9864 21.9623 28.5254 20.4529 27.5464 19.2466L25.9611 17.2887C25.5127 16.7392 24.8559 16.4234 24.1485 16.4234L24.1548 16.4297Z" fill="#005DA0" />
        <path d="M18.3193 22.4361L23.6562 18.1097C24.0036 17.8318 24.5151 17.8887 24.7993 18.2424L26.3846 20.2003C27.1425 21.1287 27.452 22.2718 27.3509 23.3645C27.2499 24.4318 26.7572 25.4613 25.8793 26.1939C24.0604 27.7224 21.2941 27.3813 19.7593 25.4929L18.212 23.5855C17.9277 23.2318 17.972 22.7203 18.3193 22.4424V22.4361Z" stroke="white" stroke-width="0.49226" stroke-miterlimit="10" />
        <defs>
            <filter id="filter0_d_6409_23021" x="0.5" y="0.5" width="45" height="50.8264" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="4" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.0823529 0 0 0 0 0.243137 0 0 0 0 0.411765 0 0 0 0.4 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6409_23021" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6409_23021" result="shape" />
            </filter>
        </defs>
    </svg>


)
export default AdvancedMarkerIconCharge
