import styled from "@emotion/styled"

import React, { ElementType } from "react"
import { NewText } from "../NewText/NewText"
import { sharedColors } from "../theme/colors"

const StyledTextWrapper = styled.div<{
  $marginTop?: number
  $marginLeft?: number
  $marginBottom?: number
  noMobileMargin?: boolean
  spaceBetweenTextToLine?: number
  lineAboveTextInDesktop?: boolean
}>`
  display: flex;
  position: relative;
  margin-top: ${(p) => `${p.$marginTop && "32"}px`};
  margin-bottom: ${(p) => `${p.$marginBottom && "32"}px`};
  margin-left: ${(p) => `${p.$marginLeft}px`};
  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: ${(p) => p.noMobileMargin ? "" : '40px'};
    flex-direction: column;
    gap:${(p) => p.spaceBetweenTextToLine ? `${p.spaceBetweenTextToLine}` : '0'}px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: ${(p) => `${p.$marginTop}px`};
    margin-bottom: ${(p) => `${p.$marginBottom}px`};
    align-items: center;
  }
    ${(p) => `${p.lineAboveTextInDesktop && "  flex-direction: column;"}`}
`

const StyledTitleLine = styled.div<{
  $component?: ElementType<any>
  noPadding?: boolean,
  lineMarginTop?: number,
  largeLineWidth: number,
  largeDesktopFontSize: number,
  desktopFontSize: number
}>`
  width: 28px;
  height: 2px;
  background: ${sharedColors.black};
  ${(props) => props.theme.breakpoints.up("md")} {
    position: absolute;
    width: 26px;
    height: 4px;
    left: -38px;
    top: ${(props) => {
      return `${(props.desktopFontSize / 2.5)}px`
   }
  };
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    width:  ${(p) => `${p.largeLineWidth}px`};
    left: -52px;
    top: ${(props) => `${(props.largeDesktopFontSize / 2.5)}px`
  }
`

type TitleWithLineProps = {
  titleFontSize?: number
  titleColor?: string
  title: string
  marginTop?: number
  marginLeft?: number
  marginBottom?: number
  mobileTitleFontSize?: number
  desktopFontWeight?: number
  smallDesktopFontSize?: number
  smallDesktopLineHeight?: string
  component?: ElementType<any>
  mobileLineHeight?: string
  desktopLineHeight?: string
  largeDesktopFontSize?: number
  largeDesktopLineHeight?: string
  noPadding?: boolean
  noMobileMargin?: boolean,
  lineMarginTop?: number
  spaceBetweenTextToLine?: number
  lineAboveTextInDesktop?: boolean
  largeLineWidth?: number
}

export const TitleWithLine: React.FC<TitleWithLineProps> = ({
  titleFontSize = 64,
  mobileTitleFontSize,
  titleColor,
  title,
  marginTop = 0,
  marginLeft = 0,
  marginBottom = 0,
  desktopFontWeight = 500,
  component = "h2",
  mobileLineHeight,
  desktopLineHeight,
  smallDesktopFontSize = mobileTitleFontSize,
  smallDesktopLineHeight = mobileLineHeight,
  largeDesktopFontSize = titleFontSize,
  largeDesktopLineHeight = desktopLineHeight,
  noPadding,
  noMobileMargin,
  lineMarginTop,
  spaceBetweenTextToLine,
  lineAboveTextInDesktop = false,
  largeLineWidth = 40
}) => {
  return (
    <StyledTextWrapper
      $marginTop={marginTop}
      $marginLeft={marginLeft}
      $marginBottom={marginBottom}
      noMobileMargin={noMobileMargin}
      spaceBetweenTextToLine={spaceBetweenTextToLine}
      lineAboveTextInDesktop={lineAboveTextInDesktop}
    >
      <StyledTitleLine 
      $component={component} 
      noPadding={noPadding} 
      lineMarginTop={lineMarginTop} 
      largeLineWidth={largeLineWidth}
      largeDesktopFontSize={largeDesktopFontSize}
      desktopFontSize={titleFontSize}
      />
      <NewText
        mobileFontSize={mobileTitleFontSize ? mobileTitleFontSize : 36}
        desktopFontSize={titleFontSize ? titleFontSize : 64}
        component={component}
        desktopLineHeight={desktopLineHeight || "108%"}
        desktopFontWeight={desktopFontWeight}
        mobileFontWeight={desktopFontWeight}
        largeDesktopFontSize={largeDesktopFontSize}
        largeDesktopLineHeight={largeDesktopLineHeight}
        smallDesktopFontSize={smallDesktopFontSize}
        smallDesktopLineHeight={smallDesktopLineHeight}
        mobileLineHeight={mobileLineHeight || "100%"}
        color={titleColor ? titleColor : sharedColors.blue}
      >
        {title}
      </NewText>
    </StyledTextWrapper>
  )
}
