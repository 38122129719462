import { SVGProps } from "react"
const ArchiveIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20 8V13.5714C20 17.0733 20 17.8242 18.8973 18.9121C17.7947 20 16.02 20 12.4706 20H11.5294C7.98001 20 6.20531 20 5.10266 18.9121C4 17.8242 4 17.0733 4 13.5714V8"
      stroke="#0050C9"
      strokeWidth="1.4"
      strokeLinecap="round"
    />
    <path
      d="M3 6C3 5.05719 3 4.58579 3.2636 4.29289C3.52721 4 3.95147 4 4.8 4H19.2C20.0485 4 20.4728 4 20.7364 4.29289C21 4.58579 21 5.05719 21 6C21 6.94281 21 7.41421 20.7364 7.70711C20.4728 8 20.0485 8 19.2 8H4.8C3.95147 8 3.52721 8 3.2636 7.70711C3 7.41421 3 6.94281 3 6Z"
      stroke="#0050C9"
      strokeWidth="1.4"
    />
    <rect
      x="9"
      y="11"
      width="6"
      height="3"
      rx="1"
      stroke="#0050C9"
      strokeWidth="1.4"
    />
  </svg>
)
export default ArchiveIcon
