import Image from "next/image"
import styled from "styled-components"
import { httpsImage } from "utils/utils"

const StyledContainer = styled.div`
  display: flex;
  padding: 30px 60px;
  justify-content: center;
`

const StyledImgContainer = styled.div`
  position: relative;
  height: 800px;
  width: 550px;

  > * {
    object-fit: contain;
  }
`

const StyledTextContainer = styled.div`
  direction: rtl;
  width: 694px;
  padding-top: 100px;
`

const StyledSubTitle = styled.h3`
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 128%;
  color: ${(p) => p?.theme?.colors?.black};
`

const StyledTitle = styled.h1`
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 25px;
  color: ${(p) => p?.theme?.colors?.black};
`

const StyledText = styled.p`
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: ${(p) => p?.theme?.colors?.black};
`

type PageSectionType = {
  imgSrc: string
  title: string
  text: string
  imgAlt?: string
  subTitle?: string
  subText?: string
}

export const PageSection = ({
  imgSrc,
  subTitle,
  title,
  text,
  subText,
  imgAlt,
}: PageSectionType) => {
  return (
    <StyledContainer>
      <StyledImgContainer>
        <Image
          quality={85}
          fill
          src={httpsImage(imgSrc)}
          alt={imgAlt || "An image"}
        />
      </StyledImgContainer>

      <StyledTextContainer>
        <StyledSubTitle>{subTitle}</StyledSubTitle>
        <StyledTitle>{title}</StyledTitle>
        <StyledText>{text}</StyledText>
      </StyledTextContainer>
    </StyledContainer>
  )
}
