import { SxProps, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { ElementType, ReactNode } from 'react'
import { sharedColors } from '../theme/colors'

type TextVariant =
  | 'LargeTitle'
  | 'MediumTitle'
  | 'SmallTitle'
  | 'Subtitle'
  | 'SubtitleLight'
  | 'Underline'
  | 'LargeText'
  | 'MediumText'
  | 'SmallText'
  | 'Bold'
  | 'MediumTitleLight'
  | 'LineThrough'
  | 'SliderTitle'
  | 'SliderSubtitle'

interface TextProps {
  variant: TextVariant
  children: ReactNode
  color?: string
  className?: string
  fontWeight?: '400' | '500' | '600' | '700' | '800' | '900'
}

const variantStyles: Record<TextVariant, SxProps<Theme>> = {
  LargeTitle: {
    fontWeight: 500,
    fontSize: '30px',
    lineHeight: '36px',
    letterSpacing: '0%',
    fontDisplay: 'swap',
    '@media (min-width:960px)': {
      fontSize: '60px',
      lineHeight: '66px'
    }
  },
  SliderTitle: {
    fontWeight: 500,
    fontSize: '68px',
    lineHeight: '68px',
    letterSpacing: '0%',
    fontDisplay: 'swap',
    '@media (min-width:960px)': {
      fontSize: '120px',
      lineHeight: '120px'
    }
  },
  SliderSubtitle: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '18px',
    letterSpacing: '0%',
    fontDisplay: 'swap',
    '@media (min-width:960px)': {
      fontSize: '40px',
      lineHeight: '60px'
    }
  },
  MediumTitle: {
    fontWeight: 500,
    fontSize: '22px',
    lineHeight: '28px',
    letterSpacing: '0%',
    '@media (min-width:960px)': {
      fontSize: '36px',
      lineHeight: '46px'
    }
  },
  MediumTitleLight: {
    fontWeight: 400,
    fontSize: '22px',
    lineHeight: '28px',
    letterSpacing: '0%',
    '@media (min-width:960px)': {
      fontSize: '36px',
      lineHeight: '46px'
    }
  },
  SmallTitle: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '0%',
    '@media (min-width:960px)': {
      fontSize: '20px',
      lineHeight: '28px'
    }
  },
  Subtitle: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0%',
    '@media (min-width:960px)': {
      fontSize: '18px',
      lineHeight: '26px'
    }
  },
  SubtitleLight: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0%',
    '@media (min-width:960px)': {
      fontSize: '18px',
      lineHeight: '26px'
    }
  },
  Underline: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0%',
    textDecoration: 'underline'
  },
  LineThrough: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0px',

    textDecoration: 'line-through'
  },
  LargeText: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0%',
    '@media (min-width:960px)': {
      fontSize: '18px',
      lineHeight: '24px'
    }
  },
  MediumText: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0%',
    '@media (min-width:960px)': {
      fontSize: '16px',
      lineHeight: '22.4px'
    }
  },
  SmallText: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0%',
    '@media (min-width:960px)': {
      fontSize: '14px',
      lineHeight: '22px'
    }
  },
  Bold: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0%',
    '@media (min-width:960px)': {
      fontSize: '16px',
      lineHeight: '22px'
    }
  }
}

const variantMapping: Record<TextVariant, ElementType<any>> = {
  LargeTitle: 'h1',
  SliderTitle: 'h1',
  MediumTitle: 'h2',
  SmallTitle: 'h3',
  Subtitle: 'h4',
  SliderSubtitle: 'h4',
  SubtitleLight: 'h4',
  Underline: 'p',
  LineThrough: 'p',
  LargeText: 'p',
  MediumText: 'p',
  SmallText: 'p',
  Bold: 'strong',
  MediumTitleLight: 'h2'
}

export const Text: React.FC<TextProps> = ({
  variant = 'SmallText',
  children,
  color = sharedColors.gray,
  className,
  fontWeight
}) => {
  return (
    <Typography
      color={color}
      component={variantMapping[variant]}
      sx={
        fontWeight
          ? { ...variantStyles[variant], fontWeight }
          : variantStyles[variant]
      }
      className={className}
    >
      {children}
    </Typography>
  )
}
