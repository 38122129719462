import { SVGProps } from "react"

interface Props extends SVGProps<SVGSVGElement> {
  blue?: boolean
}
const SubmitSearchIcon = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.width || 24}
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <rect
      width={40}
      height={40}
      fill={props.blue ? "#0050C9" : "#FCBB00"}
      rx={20}
    />
    <path
      stroke={props.blue ? "white" : "black"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M18.667 27 12 20m0 0 6.667-7M12 20h16"
    />
  </svg>
)
export default SubmitSearchIcon
