"use client"
import styled from "@emotion/styled"
import Image from "next/image"
import { extractProperty } from "utils"
import { httpsImage } from "utils/utils"
import { useIsDesktop, useIsLargeDesktop, useIsMobile, useIsXXLargeDesktop } from "../../common/bit/MediaQueries"

const StyledContainer = styled.div`
  height: 350px;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) => props.theme.breakpoints.up("md")} {
    height: 350px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    height: 350px;
  }

  ${(props) => props.theme.breakpoints.up("xl")} {
    height: 896px;
  }
  @media (min-width: 1900px) {
    height: 896px;
  }
`

const StyledCircle = styled.div`
  width: 350px;
  height: 350px;
  border-radius: 100%;
  border: 1px solid #000;
  position: absolute;
  right: -140px;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 530px;
    height: 530px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    right: -15%;
    width: 700px;
    height: 800px;
  }
`

const StyledFirstImage = styled.div`
  position: absolute;
  width: 250px;
  height: 250px;
  border-radius: 100%;
  transition: 500ms;
  transition-timing-function: ease-in;
  left: 0px;
  font-size: 10rem;
  img {
    border-radius: 100%;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 375px;
    height: 375px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    width: 563px;
    height: 563px;
  }
`

const getTransform = (
  index: number,
  activeIndex: number,
  isDesktop: boolean,
  isXLargeDesktop: boolean,
  isLargeDesktop: boolean,
  isMobile: boolean,
  en: boolean,
) => {
  const enLargeDesktop = {
    [activeIndex]: `translate(-50%,25%)`,
    [activeIndex + 2]: `translate(150px, 515px) scale(0.05)`,
    [activeIndex + 1]: `translate(-15px, 495px) scale(0.25)`,
    [activeIndex - 1]: `translate(35px,-265px) scale(0.15)`,
    [activeIndex - 2]: `translate(500px,-175px) scale(0.15)`,
  }
  const enDesktop = {
    [activeIndex]: `translate(-50%,20%)`,
    [activeIndex + 2]: `translate(130px, 335px) scale(0.05)`,
    [activeIndex + 1]: `translate(10px, 320px) scale(0.25)`,
    [activeIndex - 1]: `translate(20px,-175px) scale(0.15)`,
    [activeIndex - 2]: `translate(500px,-125px) scale(0.15)`,
  }
  const enSmall = {
    [activeIndex]: `translate(-50%,20%)`,
    [activeIndex + 2]: `translate(50px, 225px) scale(0.05)`,
    [activeIndex + 1]: `translate(-15px, 210px) scale(0.2)`,
    [activeIndex - 1]: `translate(35px,-125px) scale(0.15)`,
    [activeIndex - 2]: `translate(500px,-125px) scale(0.15)`,
  }
  const largeDesktop = {
    [activeIndex]: `translate(50%,25%)`,
    [activeIndex + 2]: `translate(-160px, 515px) scale(0.05)`,
    [activeIndex + 1]: `translate(-20px, 500px) scale(0.25)`,
    [activeIndex - 1]: `translate(-50px,-275px) scale(0.15)`,
    [activeIndex - 2]: `translate(-500px,-175px) scale(0.15)`,
  }
  const desktop = {
    [activeIndex]: `translate(50%,20%)`,
    [activeIndex + 2]: `translate(-130px, 335px) scale(0.05)`,
    [activeIndex + 1]: `translate(-10px, 325px) scale(0.25)`,
    [activeIndex - 1]: `translate(-20px,-175px) scale(0.15)`,
    [activeIndex - 2]: `translate(-500px,-125px) scale(0.15)`,
  }
  const small = {
    [activeIndex]: `translate(50%,20%)`,
    [activeIndex + 2]: `translate(-50px, 225px) scale(0.05)`,
    [activeIndex + 1]: `translate(15px, 210px) scale(0.2)`,
    [activeIndex - 1]: `translate(-15px,-120px) scale(0.15)`,
    [activeIndex - 2]: `translate(-500px,-125px) scale(0.15)`,
  }

  if (isLargeDesktop) {
    return en ? enLargeDesktop[index] : largeDesktop[index]
  } else if (isDesktop) {
    return en ? enDesktop[index] : desktop[index]
  } else {
    return en ? enSmall[index] : small[index]
  }
}

type Props = {
  imagesData: any
  moveNext: () => void
  moveBack: () => void
  active: number
  locale?: string
}

function CircularGallery({ imagesData, active, locale }: Props) {
  const isDesktop = useIsDesktop()
  // const isXLargeDesktop = useIsXLargeDesktop()
  const isXXLargeDesktop = useIsXXLargeDesktop()
  const isLargeDesktop = useIsLargeDesktop()
  const isMobile = useIsMobile()

  const images = imagesData.map((imageData: any) =>
    extractProperty(imageData, "slidImage"),
  )

  const getBackgroundColor = (index: number, activeIndex: number) => {
    if (index === activeIndex) {
      return `transparent`
    }

    if (index === activeIndex + 1) {
      return `#FFBD02`
    }

    if (index < activeIndex) {
      return `black`
    }

    if (index > activeIndex) {
      return `#FFBD02`
    }
  }

  return (
    <StyledContainer>
      <StyledCircle>
        {images.map((image: any, index: number) => (
          <StyledFirstImage
            key={index.toString()}
            style={{
              transform: getTransform(
                index,
                active,
                isDesktop,
                isXXLargeDesktop,
                isLargeDesktop,
                isMobile,
                locale === "en",
              ),
              display:
                index > active + 2 || index < active - 2 ? "none" : "block",
            }}
          >
            <div
              style={{
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                borderRadius: "100%",
                position: "absolute",
                zIndex: 5,
                backgroundColor: getBackgroundColor(index, active),
              }}
            />
            <Image quality={85} fill src={httpsImage(image)} alt={image.id} />
          </StyledFirstImage>
        ))}
      </StyledCircle>
    </StyledContainer>
  )
}

export default CircularGallery
