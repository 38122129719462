"use client"
import styled from "@emotion/styled"
import { Button } from "@mui/material"
import Image from "next/image"
import Link from "next/link"
import { useState } from "react"

import "swiper/css"
import { Navigation } from "swiper/modules"

import { Swiper, SwiperSlide } from "swiper/react"

import React from "react"
import { extractProperty } from "utils"
import { getNonUmbracoURL, getUmbracoMediaUrl, httpsImage, replaceHrefsWithMediaUrl } from "utils/utils"
import ImageContainer from "./ImageContainer"
import { NewText } from "./NewText/NewText"
import { Desktop, Mobile } from "./common/bit/MediaQueries"
import {
  LeftArrow,
  LeftMobileArrow,
  ReadMoreIcon,
  RightArrow,
  RightMobileArrow,
} from "./icons"
import { sharedColors } from "./theme/colors"
const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${(props) => props.theme.breakpoints.up("md")} {
    grid-template-columns: 1fr 1fr;
    /* gap: 80px; */
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    /* gap: 120px; */
  }
`

const StyledImageContainer = styled.div<{
  withRightImage: boolean
}>`
  width: 100%;
  height: 375px;
  position: relative;

  ${(props) => props.theme.breakpoints.up("md")} {
    height: 640px;
    order: ${(props) => (props.withRightImage ? "0" : "1")};
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    height: 900px;
  }
`

const StyledTextContainer = styled.div<{
  withRightImage: boolean
  $justifyContent: string
}>`
  padding: 28px 0;
  display: flex;
  flex-direction: column;
  position: relative;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 0px;
    height: 640px;
    justify-content: center;
    padding-left: ${(props) => (props.withRightImage ? "80px" : "146px")};
    padding-right: ${(props) => (props.withRightImage ? "146px" : "80px")};
  }

  ${(props) => props.theme.breakpoints.up("xl")} {
    padding-top: 160px;
    padding-left: ${(props) => (props.withRightImage ? "120px" : "220px")};
    padding-right: ${(props) => (props.withRightImage ? "220px" : "120px")};
  }
`

const StyledCTA = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${(props) => sharedColors.blue};
  text-decoration: none;
  margin-top: 20px;
  font-size: 16px;
  line-height: 20px;
  direction: ltr;
  font-weight: 500;

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 18px;
    line-height: 25.2px;
    margin-top: 16px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: 8px;
  }
`
const StyledSlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  p {
    margin: 0px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 0px;
  }
`
const StyledArrowsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 0px;
  z-index: 9;
  right: 0px;
  top: 14px;
  ${(props) => props.theme.breakpoints.up("md")} {
    position: relative;
    top: 0px;
  }
`

const StyledArrowTextContainer = styled.div`
  width: 22px;
  text-align: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 48px;
  }
`

const StyledArrowContainer = styled(Button)`
  padding: 0px;
  min-width: auto;
`

const StyledSlideIconContainer = styled.div`
  margin-bottom: 18px;
  width: 100%;
`

const StyledTagsContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`

const StyledTag = styled(Link)`
  height: 30px;
  border: 1px solid ${sharedColors.blue};
  border-radius: 20px;
  padding: 3px 10px;
  text-decoration: none;

  ${(props) => props.theme.breakpoints.up("md")} {
    border: 2px solid ${sharedColors.blue};

    padding: 8px 20px;
    height: 40px;
  }
`
const Bidi = styled.div`
  unicode-bidi: embed;
  direction: rtl;
`

interface StyledTextProps {
  $subWeight: number | undefined
  $mobileFontSize: number | undefined
  $desktopFontSize?: number | undefined
  $lgDesktopFontSize?: number | undefined
  $desktopLineHeight?: number | undefined
  $mobileLineHeight?: number | undefined
  $textColour?: string | undefined
  $fontWeight?: number | undefined
}

const StyledText = styled.div<StyledTextProps>`
  color: ${(props) => props?.$textColour || sharedColors.lighterBlack};

  ${(props) => props.theme.breakpoints.up("lg")} {
    font-size: ${(props) => props?.$lgDesktopFontSize || "24"}px;
    line-height: ${(props) => props?.$desktopLineHeight || "33.6"}px;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: ${(props) => props?.$desktopFontSize || "16"}px;
    font-weight: ${(props) => props.$subWeight || "400"};
    line-height: ${(props) => props?.$desktopLineHeight || "22.4"}px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    font-size: ${(props) => props?.$mobileFontSize || "18"}px;
    ${(props) =>
    props?.$mobileLineHeight && `line-height: ${props?.$mobileLineHeight}px`}
    mobilelineheight: 25px;
  }
`

const StyledHeading = styled(NewText)`
  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-bottom: 8px;
  }
`

export type BrandsTextProps = {
  data: any
  withCover?: boolean
  keyForComponent: number
  justifyContent?: string
  subWeight?: number
  desktopFontSize?: number
  lgDesktopFontSize?: number
  mobileFontSize?: number
  textColour?: string
  desktopLineHeight?: number
  mobileLineHeight?: number
  fontWeight?: number
  titleDesktopFontSize?: number
  titleMobileFontSize?: number
  titleDesktopLineHeight?: number
  titleMobileLineHeight?: number
  titleFontWeight?: number
  titleColour?: string
  trackHandler?: (cat: string, label: string) => void
  GRAPHQL_URL?: string
}

export const BrandsText: React.FC<BrandsTextProps> = ({
  data,
  withCover,
  keyForComponent,
  justifyContent = "space-between",
  subWeight,
  desktopFontSize,
  lgDesktopFontSize,
  mobileFontSize,
  textColour,
  desktopLineHeight,
  mobileLineHeight,
  fontWeight,
  titleDesktopFontSize,
  titleMobileFontSize,
  titleDesktopLineHeight,
  titleMobileLineHeight,
  titleFontWeight,
  titleColour,
  trackHandler,
  GRAPHQL_URL
}) => {
  const innerSliderBlocks = extractProperty(data, "innerSliderImageNextToText")
  const withRightImage = extractProperty(data, "flipSide")

  const items = innerSliderBlocks.map((block: any) => {
    const image = extractProperty(block, "imgText")
    const logo = extractProperty(block, "logo")
    const slidTitle = extractProperty(block, "slidTitle")
    const slidContent = extractProperty(block, "slidContent")
    const slidfUrl = extractProperty(block, "slidfUrl")
    const slidLink = extractProperty(block, "slidLink")
    const imageAlt = extractProperty(block, "altTextForImage")
    return {
      bigImage: {
        url: image,
        alt: imageAlt,
      },
      icon: {
        url: logo,
        alt: "",
      },
      title: slidTitle,
      subtitle: slidContent,
      cta: {
        url: slidfUrl?.[0]?.url,
        text: slidfUrl?.[0]?.name,
        target: slidfUrl?.[0]?.target,
      },
      tags: slidLink,
    }
  })
  const [activeIndex, setActiveIndex] = useState(0)
  const clickHandler = (cat: string, action: string) => {
    trackHandler && trackHandler(cat, action)
  }
  return (
    <Swiper
      navigation={{
        nextEl: `.arrow-right-${keyForComponent}-1`,
        prevEl: `.arrow-left-${keyForComponent}-1`,
      }}
      style={{ padding: 0 }}
      key={keyForComponent + "slider"}
      modules={[Navigation]}
      slidesPerView={1}
      onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
      onSwiper={() => { }}
    >
      {items.map((item: any, index: number) => {
        const mediaLink = item?.cta?.url && item?.cta?.url.includes("media")
        return (
          <SwiperSlide key={index.toString()}>
            <StyledGrid>
              <StyledImageContainer withRightImage={!withRightImage}>
                <Image
                  style={{ objectFit: withCover ? "cover" : "contain" }}
                  src={httpsImage(item?.bigImage?.url) || "/"}
                  alt={item?.bigImage?.alt}
                  fill
                />
              </StyledImageContainer>
              <StyledTextContainer
                withRightImage={!withRightImage}
                $justifyContent={justifyContent}
              >
                <StyledSlideContainer>
                  {item.icon.url && (
                    <StyledSlideIconContainer>
                      <ImageContainer
                        desktopUrl={item.icon.url}
                        height={48}
                        mediumHeight={32}
                        mobileHeight={28}
                        objectPosition="right"
                        contain
                        sm
                      />
                    </StyledSlideIconContainer>
                  )}
                  <StyledHeading
                    largeDesktopFontSize={titleDesktopFontSize || 48}
                    largeDesktopLineHeight={`${titleDesktopLineHeight || "67"}px`}
                    desktopFontSize={titleDesktopFontSize || 32}
                    desktopLineHeight={`${titleDesktopLineHeight || "44.8"}px`}
                    mobileFontSize={titleMobileFontSize || 28}
                    mobileLineHeight={`${titleMobileLineHeight || "36"}px`}
                    desktopFontWeight={titleFontWeight || 500}
                    component="h2"
                    color={titleColour || sharedColors.black}
                    dangerouslySetInnerHTML={{ __html: item.title }} // For rich text rendering
                  />
                  <StyledText
                    dangerouslySetInnerHTML={{ __html: replaceHrefsWithMediaUrl(item.subtitle, GRAPHQL_URL) }}
                    $subWeight={subWeight}
                    $desktopFontSize={desktopFontSize}
                    $mobileFontSize={mobileFontSize}
                    $lgDesktopFontSize={lgDesktopFontSize}
                    $desktopLineHeight={desktopLineHeight}
                    $mobileLineHeight={mobileLineHeight}
                    $fontWeight={fontWeight}
                    $textColour={textColour}
                  />
                  <StyledTagsContainer>
                    {item.tags.map((tag: any, index: number) => {
                      const link = extractProperty(tag, "link")
                      const linkTitle = extractProperty(tag, "linkTitle")

                      return (
                        <StyledTag
                          href={getNonUmbracoURL(link?.[0]?.url) || "#"}
                          key={index.toString()}
                        >
                          <NewText
                            component="p"
                            desktopFontSize={16}
                            desktopLineHeight="24px"
                            mobileLineHeight="24px"
                            mobileFontSize={14}
                            desktopFontWeight="400"
                            color={sharedColors.blue}
                          >
                            {linkTitle}
                          </NewText>
                        </StyledTag>
                      )
                    })}
                  </StyledTagsContainer>
                  {!!item.cta.url && (
                    <StyledCTA
                      target={item.cta.target}
                      href={
                        mediaLink
                          ? getUmbracoMediaUrl(item.cta.url)
                          : getNonUmbracoURL(item.cta.url) || "/"
                      }
                    >
                      {item.cta.text}
                      <ReadMoreIcon />
                    </StyledCTA>
                  )}
                </StyledSlideContainer>
                {items.length > 1 && (
                  <StyledArrowsContainer>
                    <StyledArrowContainer
                      className={`arrow-left-${keyForComponent}-1 arrow`}
                      aria-label="למדיניות הקודמת"
                    >
                      <Desktop>
                        <RightArrow />
                      </Desktop>
                      <Mobile>
                        <RightMobileArrow />
                      </Mobile>
                    </StyledArrowContainer>
                    <StyledArrowTextContainer>
                      <Desktop>
                        <NewText
                          desktopFontSize={16}
                          component="p"
                          desktopLineHeight="150%"
                          desktopFontWeight="400"
                          color={sharedColors.darkGray}
                        >
                          {activeIndex + 1}/{items.length}
                        </NewText>
                      </Desktop>
                      <Mobile>
                        <NewText
                          desktopFontSize={14}
                          component="p"
                          desktopLineHeight="20px"
                          desktopFontWeight="400"
                          color={sharedColors.darkGray}
                        >
                          {activeIndex + 1}/{items.length}
                        </NewText>
                      </Mobile>
                    </StyledArrowTextContainer>
                    <StyledArrowContainer
                      className={`arrow-right-${keyForComponent}-1 arrow`}
                      aria-label="למדיניות הבאה"
                    >
                      <Desktop>
                        <LeftArrow />
                      </Desktop>
                      <Mobile>
                        <LeftMobileArrow style={{ marginLeft: '16px' }} />
                      </Mobile>
                    </StyledArrowContainer>
                  </StyledArrowsContainer>
                )}
              </StyledTextContainer>
            </StyledGrid>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

export default BrandsText
