import styled from "@emotion/styled"

import Link from "next/link"
import { NewText } from "../NewText/NewText"
import { ArrowIcon, LocationIcon } from "../icons"
import { sharedColors } from "../theme/colors"

const StyledContainer = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  svg {
    margin-left: 10px;
    margin-right: 10px;
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    right: 15px;
    top: 30px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    top: 75px;
    left: 50px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    top: 80px;
    left: 70px;
  }
`
const StyledLink = styled(Link)`
  text-decoration: none;
`

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
`

const StyledBorder = styled.div`
  height: 36px;
  width: 2px;
  margin-left: 25px;
  margin-right: 25px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-left: 18px;
    margin-right: 18px;
    height: 24px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    margin-left: 50px;
    margin-right: 50px;
    height: 36px;
  }
  background: ${sharedColors.bluishGray};
`

type StickyBannerTextProps = {
  textOne: string
  textTwo: string
  linkOne: string
  linkTwo: string
}

function StickyBannerText({
  textOne,
  textTwo,
  linkOne,
  linkTwo,
}: StickyBannerTextProps) {
  return (
    <StyledContainer>
      <StyledLink href={linkOne}>
        <TextWrapper>
          <LocationIcon color={sharedColors.white} />
          <NewText
            component="p"
            largeDesktopFontSize={20}
            largeDesktopLineHeight="28px"
            desktopFontWeight="500"
            mobileFontWeight="400"
            mobileFontSize={20}
            mobileLineHeight={"100%"}
            color={sharedColors.white}
            desktopFontSize={14}
            desktopLineHeight="20px"
          >
            {textOne}
          </NewText>
        </TextWrapper>
      </StyledLink>
      <StyledBorder />
      <StyledLink href={linkTwo}>
        <TextWrapper>
          <NewText
            component="p"
            largeDesktopFontSize={20}
            largeDesktopLineHeight="28px"
            desktopFontWeight="500"
            mobileFontWeight="400"
            mobileFontSize={20}
            mobileLineHeight={"100%"}
            color={sharedColors.white}
            desktopFontSize={14}
            desktopLineHeight="20px"
          >
            {textTwo}
          </NewText>
          <ArrowIcon />
        </TextWrapper>
      </StyledLink>
    </StyledContainer>
  )
}

export default StickyBannerText
