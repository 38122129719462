import { SVGProps } from "react"
const PersonStickyIconEng = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="301"
    height="98"
    viewBox="0 0 301 91"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_b_5073_13538)">
      <path
        d="M296.812 31.514C306.141 18.2635 296.664 0 280.458 0H0V98H250L296.812 31.514Z"
        fill={props.color}
        fill-opacity="0.74"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_5073_13538"
        x="-40"
        y="-40"
        width="380.492"
        height="178"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="20" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_5073_13538"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_5073_13538"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
export default PersonStickyIconEng
