import { SVGProps } from "react"
const PazomatAdvancedMarkerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={46}
    height={52}
    fill="none"
    {...props}
  >
    <g filter="url(#a)">
      <path
        fill={props.fill}
        d="M37 21c0 5.237-6.423 13.473-10.568 18.242a4.51 4.51 0 0 1-6.864 0C15.423 34.472 9 26.237 9 21c0-7.732 6.268-14 14-14s14 6.268 14 14Z"
      />
      <path
        fill="#fff"
        d="m26.432 39.242-.377-.328.377.328Zm-6.864 0-.378.328.378-.328ZM36.5 21c0 1.215-.375 2.642-1.03 4.195-.651 1.548-1.568 3.191-2.62 4.831-2.105 3.281-4.73 6.512-6.795 8.888l.755.656c2.08-2.393 4.739-5.665 6.881-9.004 1.072-1.67 2.02-3.365 2.701-4.983.68-1.613 1.108-3.18 1.108-4.583h-1ZM19.945 38.914c-2.065-2.376-4.69-5.607-6.795-8.888-1.052-1.64-1.969-3.283-2.62-4.831C9.874 23.642 9.5 22.215 9.5 21h-1c0 1.403.428 2.97 1.108 4.583.681 1.618 1.63 3.314 2.7 4.983 2.143 3.34 4.803 6.611 6.882 9.004l.755-.656ZM9.5 21c0-7.456 6.044-13.5 13.5-13.5v-1C14.992 6.5 8.5 12.992 8.5 21h1ZM23 7.5c7.456 0 13.5 6.044 13.5 13.5h1c0-8.008-6.492-14.5-14.5-14.5v1Zm3.055 31.414a4.01 4.01 0 0 1-6.11 0l-.755.656a5.01 5.01 0 0 0 7.62 0l-.755-.656Z"
      />
    </g>
    <circle cx={23} cy={20} r={6} fill="#fff" />
    <defs>
      <filter
        id="a"
        width={45}
        height={50.826}
        x={0.5}
        y={0.5}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={2} />
        <feGaussianBlur stdDeviation={4} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.0823529 0 0 0 0 0.243137 0 0 0 0 0.411765 0 0 0 0.4 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4648_58666"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_4648_58666"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
export default PazomatAdvancedMarkerIcon
